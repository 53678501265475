.dt-c-SearchBox {
  position: relative;
  display: flex;
  border: none;

  --field-height: 36px;

  [type='text'] {
    width: 100%;
    height: var(--field-height);
    float: none;
    border: solid color(tertiary);
    border-width: 0 0 2px 0;
    border-radius: 0;
    font-family: font(text);
    font-weight: fw(semiBold);
    color: color(tertiary);
    background-color: transparent;
    text-overflow: ellipsis;
  }

  [type='submit'], [type='reset'] {
    @include center-abs-y;
    width: auto;
    color: color(tertiary);
    font-weight: fw(bold);
    background: transparent;
    border: none;

    svg {
      display: block;
      height: auto;

      g {
        fill: color(tertiary);
      }
    }
  }


  // Page context
  &:not([name='SearchBox_Header']) {
    [type='text'] {
      height: var(--field-height);
      width: 305px;
      max-width: 100%;
      padding: 0 67px 0 28px;
    }

    [type='submit'] {
      left: 0;

      svg {
        width: 21px;
      }
    }

    [type='reset'] {
      right: 0;
      @include fs(14px, 19px);
      text-decoration: underline;
    }

    [type='text']:placeholder-shown ~ [type='reset'] {
      opacity: .6;
      pointer-events: none;
    }
  }



  // Header context
  &[name='SearchBox_Header'] {
    --field-height: 63px;

    [type='text'] {
      height: var(--field-height);
      padding: 7px 45px 7px 0;
      @include fs(32px, 43px);
    }

    [type='submit'] {
      right: 0;
      width: 45px;
      height: 45px;
      border: 2px solid color(tertiary);
      border-radius: 3px;

      svg {
        width: 37px;
      }
    }

    [type='reset'] {
      display: none;
    }


    @include mq-to(medium){
      --field-height: 43px;

      [type='text'] {
        @include fs(18px, 22px);

        &::placeholder {
          @include rem(font-size, 14px);
        }
      }

      [type='submit'] {
        width: 32px;
        height: 32px;

        svg {
          width: 26px;
          margin: auto;
        }
      }
    }
  }


}