//
//Brand Index

ul {
	&.brand-index {
		list-style: none outside none;
		padding-left: 0;
	}

	&.brand-list {
		list-style: none outside none;
		padding-left: 0;
	}
}


//
// TABSET

.brand-list-container {
	padding: $space-default;
}