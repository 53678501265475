/*********** GLOBAL NAVIGATION **********/

.main-navigation {
	position: relative;
	z-index: 10000;
	@media (max-width: $screen-sm-max) {
		padding: 0;
	}



	ul {

		&.main-navigation-list {
			padding: 0;
			margin: 0;
			position: relative;
			display: table;
			width: 100%;

			& > li {
				display:table-cell;
				vertical-align: middle;
				margin: 0;
				list-style: none;
				text-align: center;

				@media (max-width: $screen-sm-max) {
					display:block;
					width: 100%;
					margin: 0;
					padding: 0;
				}
				& > a {
					display: block;
					padding:10px 15px;
					color: $header-nav-level0-color;
					font-size: 15px;
					font-weight:400;
					text-decoration: none;
				}

				& .dropdown-menu{
					left: 0;
					top: 100%;
					padding: 20px;
					width: 100%;
					z-index: 9999;
					margin:0;

					@media (max-width:$screen-sm-max) {
						padding: 0;
						position: relative;
						border-top: none;
						top: 0;
						box-shadow: none;
						& .row{
							margin:0;
						}
						& [class*='col-']{
							padding:0;
						}
						& .dropdown-menu-push{
							padding:15px;
						}
					}

					@media (min-width: $screen-md-min) {
						display: block;
						visibility: hidden;
					}
				}

				&.dropdown {
					position: static;
					//background: url(../../../img/header/active_catalog.png) -2000px bottom no-repeat;

					@media (min-width: $screen-sm-min) {
						margin: 0;
						&:hover {
							background: $header-nav-level0-hover-bkg;
							//transition-delay: 0.5s;
							& .dropdown-menu {
								visibility: visible;
								transition-delay: 0.5s;
							}
						}
					}
				}



				@media (max-width:$screen-sm-max) {

					> a {

						background: $header-nav-xs-level0-bkg;
						border-bottom: 1px solid white;
						width: 85%;
						color:$header-nav-xs-level0-text;
						float: left;
						padding: 0 15px !important;
						height:45px;
						line-height: 45px;
						text-align: left;
						&.dropdown-toggle {
							float: right;
							width: 15%;
							text-align: center;
							font-size: 14px;
						}
					}

					&.open {
						.glyphicon-plus {
							&:before {
								content: "\2212";
							}
						}
					}
				}

			}
		}

		li {

			list-style: none;
			ul {
				padding: 0;
			}
		}

		&.category-level1 {

			> li {
				padding-right: 3%;
				line-height: 28px;
				margin-bottom: $space-default;


				@media (max-width:$screen-sm-max) {
					width: 100%;
					margin-bottom: 0;
					padding: 0;
					@include clearfix();
				}

				a {
					font-size: 16px;
					font-weight:700;
					background: none;
					color:$header-nav-level1-color;
					padding: 0;
					&:hover{
						color: $header-nav-level1-hover-color;
					}
					@media (max-width: $screen-sm-max) {
						text-transform: capitalize;
						font-size: 16px;
						background:white;
						padding: 5px 15px !important;
						width: 100%;
						color: $color-01;
						display: block;
					}
				}

				li {
					float: none;
					margin: 0;
					ul{
						margin: 0;
						padding: 0;
					}

					a {
						font-weight:400;
						font-size: 16px;
						color: $header-nav-level2-color;
						text-transform: none;
						&:hover{
							color: $header-nav-level2-hover-color;
						}
					}
				}

				@media (max-width:$screen-sm-max) {

					ul {
						a{
							padding: 2px 15px !important;
						}
					}
				}
			}
		}
	}


	& .viewall{
		position: relative;
		display: inline-block;
		color: #ED6E4C;
		padding-right:20px;
		font-size:16px;
		& span{
			text-decoration: underline;
		}
		&.icon:before{
			position: absolute;
			top:50%;
			right:0;
			transform: translateY(-35%);
			font-size:14px;
		}
	}

}



.main-navigation-right {
	z-index: 3;

	@media (max-width:$screen-sm-max) {
		width: 100%;
		padding: 0;
	}
}


.dropdown-menu {
	border: 0;
	border-radius: 0;
}
