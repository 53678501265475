.dt-c-OADServices{
  .display-grid{
    padding: 0 15px;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    @include rem(gap,15px);
    @include mq-from(medium) {
      grid-template-columns: repeat(2, minmax(calc(50% - 15px), 1fr));
      @include rem(gap,15px);
    }
    @include mq-from(large) {
      padding: 0;
      grid-template-columns: repeat(3, minmax(253px, 1fr));
      @include rem(gap,29px);
    }
    @include mq-from(xlarge) {
      padding: 0;
      grid-template-columns: repeat(4, minmax(253px, 1fr));
      @include rem(gap,29px);
    }
    .dt-c-TemplatePages-columnLeft &{
      @include mq-from(large) {
        padding: 0;
        grid-template-columns: repeat(2, minmax(calc(50% - 15px), 1fr));
      }
      @include mq-from(xlarge) {
        grid-template-columns: repeat(auto-fit, calc(253px - 29px));
      }
    }
  }
  &-item{
    @include rem(height,253px);
    @include rem(padding,10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    color:color(light);
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    &:hover, &:focus-within {
      .dt-c-OADServices-item--bg{
        transform: translate(-50%) scale(1.1);
      }
    }
    &--bg{
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      transform: translate(-50%) scale(1);
      position: absolute;
      left: 50%;
      transition: all .2s ease-in-out;
    }
    &--title{
        z-index: 1;
        font-family: font(title);
        font-weight: fw(extraBold);
        @include rem(font-size,36px);
        text: {
            align: center;
            transform: uppercase;
        };
    }
    &--desc{
        @include rem(font-size,14px);
        position: absolute;
        @include rem(bottom,15px);
        @include rem(left,10px);
        @include rem(right,10px);
        @include rem(font-size,14px);
        text: {
            align: center;
        };
    }
    &--link{
      display: block;
      text-decoration: none !important;
    }
  }
}
