.dt-c-HeaderContactInfos {
  padding: 20px;
  background-color: color(light);

  &-list {

  }

  &-info {
    display: flex;
    align-items: center;
    color: color(primary);

    &:not(:last-child) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 2px solid color(background--light);
    }

    &-icon {
      flex: 0 0 30px;
      margin-right: 20px;

      img {
        display: block;
        width: 100%;
        height: auto;

        // Placeholder
        ::after {
          content: '';
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 2px solid currentColor;
        }
      }
    }

    &-desc {
      strong {
        display: block;
        margin-bottom: 5px;
        font-family: font(title);
        @include fs(16px, 19px);
      }

      span {
        font-family: font(text);
        @include fs(12px, 15px);
        font-weight: fw(semiBold);
        color: color(dark);
      }
    }
  }

  &-cta {
    margin-top: 20px;

    .btn {
      display: block;
      width: 100%;
    }
  }
}