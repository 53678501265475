// .nq-.. given for retro-compatibility

.nq-c-NavigationPush,
.dt-c-NavigationPush {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 220px;

  &-image {
    z-index: 0;
    @include cover-abs();
    background-color: color(dark);

    img {
      @include cover-abs();
      opacity: 0.76;
    }
  }

  &-content, &-link {
    z-index: 1;
    position: relative;
  }

  &-content {
    width: 100%;
    padding: 18px;
    font-weight: fw(bold);
    font-family: font(title);
    color: color(light);

    strong {
      display: block;
      margin-bottom: 11px;
      @include fs(20px, 24px);
    }

    p {
      margin: 0;
      @include fs(16px, 19px);
    }
  }

  &-link {
    margin-top: 15px;
    padding: 6px 8px 5px 36px;
    font-family: font(text);
    font-weight: fw(semiBold);
    @include fs(14px, 23px);
    color: color(primary);
    background: {
      color: color(light);
      image: url('../img/icons/arrowline-right.svg');
      position: 13px center;
      repeat: no-repeat;
    }
    text-decoration: underline;
  }


  @include mq-to(large) {
    min-height: 278px;
  }
}