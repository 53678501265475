.dt-c-Checkbox{
  position: relative;
  label{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    &:before{
      content:"";
      @include rem(width,18px);
      @include rem(height,18px);
      border: 1px solid color(black);
      display: block;
      @include rem(margin-right,5px);
      flex-shrink: 0;
      align-self: flex-start;
    }
    &:after{
      content:"";
      background: color(dark);
      transform: scale(0);
      transition: all 0.2s ease;
      display: block;
      @include rem(width,12px);
      @include rem(height,12px);
      position: absolute;
      @include rem(left,3px);
      @include rem(top,3px);
    }
  }
  input[type=checkbox]{
    display: none;
    &:checked + label{
      &:after{
        transform: scale(1);
      }
    }
  }
}