.dt-c-HeaderShortcuts {
  height: 100%;
  color: color(primary);

  $item: unquote('.dt-c-HeaderShortcuts-item');

  &-list {
    display: flex;
    align-items: stretch;
    height: 100%;
  }

  &-item {
    position: relative;
    padding: 30px 0 20px;
    transition: all .2s ease-in-out;
    box-sizing: content-box;

    &:not(:last-child) {
      margin-right: 15px;
    }

    &-name {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      transition: all .15s ease;

      .bullet-count-text {
        position: absolute;
        top: calc(50% - 16px);
        right: -6px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        background-color: color(tertiary);
        border-radius: 50%;
        @include fs(10px);
        font-weight: fw(bold);
        color: color(light);
      }
    }

    &-content {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }



    &[data-type='search'] {
      color: color(secondary);

      #{$item}-content {
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        transition: all 0.2s ease;
      }

      // Toggled state
      &[data-search-toggled='true'] {
        #{$item}-content {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    // Contact infos dropdown
    &[data-type='contact'] {
      filter: drop-shadow(0 3px 8px rgba(color(dark), 0.1));

      #{$item}-name {
        &::after {
          content: '';
          position: absolute;
          bottom: -4px;
          display: inline-block;
          width: 0;
          height: 0;
          border-right: 15px solid transparent;
          border-bottom: 15px solid color(light);
          border-left: 15px solid transparent;
          opacity: 0;
          transition: all .2s ease-in-out;
        }
      }

      #{$item}-content {
        @include center-abs-x;
        top: calc(100% - 20px);
        width: 285px;
        padding-top: 4px;
        transition: all .2s ease;
      }

      &:hover {
        #{$item}-name::after {
          opacity: 1;
        }

        #{$item}-content {
          opacity: 1;
          pointer-events: all;
        }
      }
    }

    &[data-type='account'] {
      // Logged & not in account pages : bullet
      body.logged & #{$item}-name::after {
        content: '';
        position: absolute;
        top: calc(50% - 13px);
        right: -4px;
        width: 8.8px;
        height: 8.8px;
        border-radius: 50%;
        background-color: color(tertiary);
      }

      // In account pages : highlighted entry
      body[data-applicationframe*='account'] & {
        margin: 0 17px 0 -8px;
        padding: 0 8px;
        height: auto;
        overflow: hidden;
        &:before {
          content: "";
          position: absolute;
          display: block;
          @include rem(top,-15px);
          @include rem(height,150px);
          left: 0;
          width: 100%;
          background: color(secondary);
        }
      }
    }
    
    
    &[data-type='exploitation'] {
    
    width: auto;
	height: auto;
    margin: 0px;
    padding: 10px 21px;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 0.231;
    font-weight: 800;
    font-family: "Barlow", Arial, sans-serif;
    text-transform: uppercase;
    color: white;
    background: green;
    border: 1px solid green;
    border-radius: 25.5px;
    white-space: normal;
    bottom: 13px;
    position: relative;
    display: inline-block;
    right: 0;
    z-index:-26;

      // In account pages : highlighted entry
      body[data-applicationframe*='account'] & {
      display: none;

      }
    }
    
    
    
    

    &[data-type='mobile-burger'] {
      cursor: pointer;

      img {
        display: block;
        width: 26px;
        height: auto;
      }
    }
  }



  @include mq-from(large) {
    body.homepage:not(.logged) .dt-c-Header:not([data-sticky='true']) & {
      color: color(light);
    }

    &-item {
      height: 60px;

      &[data-type='mobile-burger'] {
        display: none;
      }
    }
  }

  @include mq-from(xxlarge) {
    &-item {
      &[data-type='search'] {
        #{$item}-name::before {
          content: '';
          position: absolute;
          bottom: calc(50% - 12px);
          right: calc(100% - 16px);
          display: block;
          width: 50px;
          height: 2px;
          background-color: currentColor;
          border-radius: 1px;
        }
      }
    }
  }

  @include mq-from(xxxlarge) {
    &-item {
      &:not(:last-child) {
        margin-right: 30px;
      }

      &[data-type='search'] {
        #{$item}-name::before {
          width: 101px;
        }
      }
    }
  }

  @include mq-to(large) {
    &-item {
      padding: 13px 0;

      &:not(:last-child) {
        margin-right: 25px;
      }

      &-name {
        .bullet-count-text {
          top: calc(50% - 14px);
        }
      }

      &[data-type='search'] {
        color: currentColor;
      }

      &[data-type='contact'] {
        display: none;
      }

      &[data-type='account'] {
        // Logged & not in account pages : bullet
        body.logged & #{$item}-name::after {
          top: calc(50% - 11px);
        }
      }

      svg {
        width: 24px;
        height: auto;
      }
    }
  }
}