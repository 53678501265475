.ol-u-fit {
  position: relative;
  // may be used on inline elements, so force block
  display: block;
  height: 0;

  img,
  picture,
  svg,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
