
//
// CHECKOUT

/************* Checkout Step 1 - Address ***************/

.multiple-address-container {
	.list-body .list-item-row .list-item {
		overflow: inherit;
		
		@media (min-width:$screen-lg-min) {
			address {
				margin-bottom: 0;
			}
		}
	}
}

/************* Checkout Step 2 - Shipping ***************/

.shipping-methods {
	margin-bottom: $space-default;
}

.cart-summary-checkout {
	font-size: $font-size-small;
	
	h5 {
		margin-top: 0;
	}
	
	.cart-pli-data {
		color: $text-color-quarternary;
		
		span {
			width: 80px;
			display: inline-block;
		}
	}
}

.packslip-message {
	.checkbox  {
		margin-bottom: ($space-default/2); 
	}
}

//
// GIFT WRAP

.dialog-giftwrap-content {
	padding: $space-default*2;
	width: 100%;
	text-align: center;
}

/************* Checkout Step 5 - Checkout Receipt ***************/

.checkout-signup {
	background: $color-secondary;
	height: 100%;
	padding-bottom: ($space-default * 2);
	color: $text-color-inverse;	
	
	@media (max-width:$screen-xs-max) {
		margin-top: ($space-default * 2);
		padding-top: 1px;
	}
	
	input {
		border: $border-width-default solid $border-color-default;
	}
}

.addressDeleteConfirmation {
	display:none;
}

.infobox {
	padding: $space-default;
	margin-bottom: $space-default;
	background-color:$color-tertiary;
	width: 100%;
	h3 	{
		margin-top:0;
	}
	
	@media (min-width:$screen-sm-min) { 
		.col-sm-6 ~ .col-sm-6 {
			padding-left: $space-default*2;
		}
	}
	}

/* Promotions Carousel */

.promotionsCarousel {
	padding-bottom: $space-default*3;
	
	.carousel-indicators {
		bottom: -25px;
	}
	
	.carousel-inner > .active {
		padding-left: 30px;
		padding-right: 30px;
	}
}

