.dt-c-Push {
    text-align: center;

    $p: &;

    &-top {
        z-index: 0;
        position: relative;
        background-color: color(light);

        // Spacer & fader
        &::before {
            content: '';
            z-index: 1;
            position: relative;
            display: block;
            padding-top: 52%;
            background-color: color(background--dark);
            opacity: 0.15;
            transition: all .2s ease;
        }

        img {
            z-index: 0;
            @include cover-abs;
            transition: all .2s ease;

            #{$p}:focus &,
            #{$p}:hover & {
                transform: scale(1.02);
            }
        }

        &-text {
            z-index: 2;
            @include center-abs-xy;
            width: 100%;
            padding: 20px 15px;
            color: color(light);
        }
    }

    &-bottom {
        z-index: 1;
        position: relative;
        margin-top: -22px;
    }

    
    &-title {
        @include fs(36px, 34px);
        font-family: font(title);
        font-weight: fw(extraBold);
        text-transform: uppercase;
        color: currentColor;
    }
}