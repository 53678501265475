.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: ($grid-gutter-width / 2);
    padding-right: ($grid-gutter-width / 2);
}

a {
	cursor: pointer;
	
	&:hover,
	&:focus {
		text-decoration: none;
	}
	
	&.teaser-link {
		display: block;
	}
}

ul{
	margin:0;
	padding:0;
	& li{
		list-style-type: none;
	}
}

.glyphicon {
	&.glyphicon-footer-icon:before {
		color: $text-color-inverse;
		font-size: 50px;
	}
}


//
// Main working area

.wrapper {
	padding: 0px 0px 50px 0px;
}





// General styles
.show-inline {
	display: inline;
}

//
// Global Panels
.grey-panel {
	background: $color-tertiary;
	padding: ($space-default + ($space-default / 2)) 0;
}

// Help Text
.help-block {
	color: $text-color-quarternary;
}

h4.help-block {
	color: $text-color-quarternary;
}

.help-block-inline {
	display: inline-block;
	margin:0;
}


// Detail links and tooltips

[data-toggle="popover"] {
	.glyphicon {
		padding-left: $space-default/4;
		font-size: 120%;
	}
}

.details-link,
.details-tooltip {
	padding-left: $space-default/2;
	font-size: 90%;
	font-family: $font-family-regular;
	text-transform: none;
	white-space: nowrap;
	display: inline-block;
}


//
// SETS BOXES TO THE SAME HEIGHT
@media (min-width: $screen-sm-min) {
	.equal, .equal > div[class*='col-'] {
	    display: -webkit-box;
	    display: -moz-box;
	    display: -ms-flexbox;
	    display: -webkit-flex;
	    display: flex;
	    flex:1 0 auto;
	}
	// do not use flexbox if there is only one box
	.equal > div[class*='col-']:only-child {
		display: inherit;
		flex: 0 0 auto;
	}
}


.custom-pull-right {
	float: right !important;
	margin-right: 0 !important;
	padding-right: 15px !important;
	text-align: right !important;
}

// general layout adaption styles
.top-padding {
	padding-top: $space-default;
}
.bottom-padding {
	padding-bottom: $space-default;
}

#gtx-trans{
	display:none;
}

h1.title-no-breadcrumb {
	padding-top: 15px;
}
