.dt-c-BtnList{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @include mq-from(large){
        flex-direction: row;
        justify-content: flex-start;
    }
    li{
        margin: {
            bottom:20px;
        };
        @include mq-from(medium){
            flex-direction: row;
            justify-content: flex-start;
            margin: {
                right:20px;
            }
            &:last-of-type{
                margin: {
                    right:0;
                }
            }
        }
        .btn{
            @include rem(padding-left,15px);
            @include rem(padding-right,15px);
            min-width: 0;
        }
    }
}
