.font-barlow{font-family: "Barlow", Arial, sans-serif;}
.whitespace-normal {white-space: normal;}
.whitespace-nowrap {white-space: nowrap;}
.uppercase{
  text-transform: uppercase;
}
.defaultcase {
  text-transform: none;
}
.underline{
  text-decoration: underline;
}
.font-semibold{
  font-weight: bold;
}
.lh-0 {
  line-height: 0;
}

@for $i from 10 through 50 {
  .text-#{(1 *$i)} {
    @include rem(font-size, (1px *$i));
  }
}
@for $i from 10 through 40 {
  @each $name, $values in $breakpoints {
    @include mq-from($name) {
      .#{$name}-text-#{(1 *$i)} {
        @include rem(font-size, (1px *$i));
      }
    }
  }
}

@for $i from 1 through 9 {
  .font-#{(100 *$i)} {
    font-weight: (100 *$i) !important;
  }
}
@each $fwname, $fwvalues in $font-weights {
  .fw-#{$fwname} {
    font-weight: $fwvalues;
  }
}

.barlow{
  font-family: font(title);
}
.open-sans{
  font-family: font(text);
}
.raleway{
  font-family: font(product);
}


.color-tertiary {
  color: color(tertiary);
}
.color-green {
  color: color(green);
}
.color-green--light {
  color: color(green--light);
}