@each $name, $values in $breakpoints {
    @include mq-to($name) {
        .up-to-#{$name}-hidden {
            display: none;
        }
    }
    @include mq-from($name) {
        .#{$name}-hidden {
            display: none;
        }
        .#{$name}-block {
            display: block;
        }
    }
}