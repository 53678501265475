.promotion-list {
	list-style: none;
	font-family: $font-family-regular;
	padding-left: 0px;
	display: table;
	width: 100%;

	.promotion-list-item {
		display: table-row;
	}
	.promotion-icon {
		float: left;
		margin-right: 10px;
	}
	.promotion-short-title {
		display: inline;
	}
	.promotion-long-title {
		display: inline;
	}
	.promotion-details-link {
		display: inline;
	}
}

.modal-body {
	
	.product-info {
		.promotion-list {
			margin-bottom: 0;
		}
	}
}