.dt-c-SocialShare{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include rem(margin-bottom, 30px);
  @include rem(font-size, 12px);
  font-weight: fw(bold);
  &:before{
    content:"";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    @include rem(height, 1px);
    width: 100%;
    background: color(border);
  }
  &--content{
    display: flex;
    justify-content: center;
    align-items: center;
    background: color(background--light);
    z-index: 10;
    @include rem(padding, 0 15px);
  }
  .icon{
    @include rem(font-size, 22px);
  }
  a{
    display: inline-block;
    @include rem(margin-right, 8px);
    &:last-child{
      margin-right: 0;
    }
  }
}