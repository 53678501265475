.dt-c-ActuEvents {
    &-item {
        @include rem(margin-bottom, 20px);
        position: relative;
        z-index: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include mq-from(medium) {
            flex-direction: row;
        }
        &.event{
            .dt-c-ActuEvents-item-category-tag{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                @include rem(border-radius, 18px);
                background: color(secondary);
                color:color(dark);
                @include rem(padding, 4px 16px);
                .icon{
                    display: inline-block;
                    @include rem(margin-right, 5px);
                    @include rem(font-size, 15px);
                }
            }
            .dt-c-ActuEvents-item-date{
                position: absolute;
                top:0;
                @include rem(right, 10px);
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-start;
                align-items: center;
                @include rem(width, 59px);
                @include rem(height, 74px);
                background: color(secondary);
                color: color(dark);
                @include rem(font-size, 18px);
                text-align: center;
                font-weight: fw(bold);
                font-family:font(title);
                span{
                    @include rem(font-size, 32px);
                }
            }
        }
        &.actu{
            background-color: color(light);
            .dt-c-ActuEvents-item-date{
                @include rem(font-size, 14px);
            }
        }

        &-img {
            position: relative;
            flex-shrink: 0;
            width: 100%;
            height: auto;
            @include rem(margin-bottom, 15px);
            @include mq-from(medium) {
                @include rem(max-width, 302px);
                @include rem(height, 176px);
                @include rem(margin, 0 15px 0 0);
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.rich{
                width: 100%;
                @include rem(height, 278px);
                @include rem(margin-bottom, 15px);
                @include mq-from(medium){
                    max-width: 50%;
                    flex-shrink: 0;
                    @include rem(margin, 0 15px 0 0);
                    @include rem(margin-right, 30px);
                }
                @include mq-from(large) {
                    @include rem(max-width, 445px);
                }
            }
        }
        &-category{
            color: color(primary);
            @include rem(font-size, 14px);
            @include rem(margin-bottom, 10px);
            &-tag{
                text-transform: uppercase;
                @include rem(font-size, 14px);
                font-weight: fw(bold);
            }
            &-sep{
                @include rem(margin,0 13px);
            }
        }
        &-title{
            @include rem(margin-bottom, 5px);
            font-family: font(title);
            @include rem(font-size, 24px);
            color:color(dark);
            font-weight: 400;
            b{
                font-weight: fw(bold);
            }
            &.rich{
                @include rem(font-size, 28px);
                @include rem(margin-bottom, 15px);
                b{
                    font-weight: fw(extrabold);
                }
                text: {
                    transform: uppercase;
                };
            }
        }
        &-description{
            @include rem(margin-bottom, 5px);
            font-family: font(text);
            color:color(dark);
            @include mq-from(medium){
                @include rem(margin-bottom, 30px);
            }
            &.rich{
                span{
                    display: block;
                    font-weight: fw(bold);
                    @include rem(font-size,24px);
                    @include rem(margin-bottom,10px);
                    text: {
                        transform: uppercase;
                    };
                    font-family: font(title);
                }
            }
        }
        &-bottom-link{
            text-decoration: underline;
            font-weight: fw(bold);
        }
    }
}

