.dt-c-NavigationProducts {
  width: 100%;

  &-list {}

  &-item {}

  @include mq-from(large) {
    position: relative;
    height: 100%;

    &-list {
      width: 210px;
      padding: 20px 0;
      background-color: color(background--light);
      border-right: 1px solid color(tertiary);
    }

    &-item {
      display: block;

      > a {
        position: relative;
        display: block;
        padding: 16px 14px 16px 37px;
        @include fs(13px, 16px);
        font-weight: fw(bold);
        font-family: font(title);
        text-transform: uppercase;
        color: color(primary);
        background: {
          image: url('../img/icons/arrowline-right.svg');
          position: 14px center;
          repeat: no-repeat;
        }

        &::before {
          content: '';
          z-index: 0;
          @include cover-abs;
          background-color: rgba(color(dark), 0.04);
          opacity: 0;
          transition: all .2s ease-in-out;
        }

        span {
          z-index: 1;
          position: relative;
        }

        &[style*='background-color'] {
          margin-left: -1px;
          padding-left: 38px;
          background-position-x: 15px;
        }
      }

      &:hover, &:focus-within {
        > a {
          color: color(tertiary);

          &[style*='background-color'] {
            color: color(light);
          }

          &::before {
            opacity: 1;
          }
        }
      }

      &-submenu {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        width: calc(100% - 210px);
        height: 100%;
        padding: 30px 13px 30px 17px;
        background-color: color(light);
        overflow: auto;
        opacity: 0;
        pointer-events: none;

        &-links {
          flex: 1 1 auto;

          // Lvl 1
          > ul {
            display: flex;
            flex-wrap: wrap;
            margin: -15px;

            > li {
              flex: 0 1 50%;
              padding: 15px;

              > a {
                display: block;
                margin-bottom: 10px;
                @include fs(16px, 19px);
                font-weight: fw(bold);
                font-family: font(title);
              }

              // Lvl 2
              > ul {
                > li {
                  &:first-child {
                    margin-top: -5px;
                  }

                  &:last-child {
                    margin-bottom: -5px;
                  }

                  > a {
                    display: block;
                    padding: 5px 0;
                    @include fs(14px, 17px);
                    font-family: font(text);
                    transition: all .1s ease;

                    &:hover, &:focus {
                      color: color(tertiary);
                    }
                  }
                }
              }
            }
          }
        }

        &-push {
          flex: 0 0 190px;
          margin-left: 30px;

          > *:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        &-textRight {}
      }

      &:first-child > &-submenu, &:hover > &-submenu, &:focus-within > &-submenu {
        z-index: 0;
        opacity: 1;

        // Scope to prevent overriding pointer-events: none; on .dt-c-Navigation-item-submenu
        .dt-c-NavigationProducts:hover & {
          pointer-events: all;
        }
      }
    }
  }

  @include mq-from(xlarge) {
    &-item {
      &-submenu {
        &-links {
          // Lvl 1
          > ul {
            > li {
              flex-basis: calc(100% / 3);
            }
          }
        }
      }
    }
  }

  @include mq-to(large) {
    &-list {
      padding-bottom: 45px;
    }

    &-item {
      $item: &;

      > a[data-navigation] {
        position: relative;
        padding-top: 18.6px;
        padding-bottom: 18.6px;
        @include fs(18px, 22px);
      }


      &-submenu {
        &-links {
          > ul > li {
            a[data-navigation] {
              padding-top: 16.1px;
              padding-bottom: 16.1px;
              @include fs(16px, 20px);
            }

            > ul {
              display: none;
            }
          }
        }

        &-push {
          margin: 30px 40px;
        }
      }
    }
  }
}