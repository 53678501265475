.qbox{
	position: relative;
}
.qbox-wrapper{
	display: none;
	position:absolute;
	min-width:100%;
	max-width:500px;
	min-height:100px;
	top:100%;
	right:0px;
}
.isLoading .qbox-wrapper{
	background:#ffffff url("../../images/loader/loader-red-80px.gif") center no-repeat;
}
.isActive .qbox-wrapper{
	//visibility:visible;
	display: inherit;
	@include opacity(1);
}