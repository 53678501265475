.cpt_rassurance{
  position: relative;
  display: inline-block;
  padding:80px 10px 10px 10px;
  & .img{
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%);
    @include x-rem(font-size,50px);
    color:$footer-rassurance-icon;
  }
  & .title{
    display: block;
    margin-bottom: 5px;
    @include x-rem(font-size,18px);
    color:$footer-rassurance-text;
    font-weight: 700;
    text-align: center;
  }
  & .desc{
    display: block;
    @include x-rem(font-size,16px);
    color:$footer-rassurance-text;
    text-align: center;
  }
}