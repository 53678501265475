article.product-tile{
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	background: white;
	padding:20px;
	.tiny & {
		padding: 15px 14px;
	}

	& .product-gallery{
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	& .product-infos{
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: flex-start;
		margin:10px 0;

		.tiny & {
			> [class*='mb'], > [class*='marg-b'] {
				margin-bottom: 5px;
			}
		}
	}
	& .product-title{
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		color:black;
		font-family: "Raleway", Arial, sans-serif;
		font-weight: 700;
		text-transform: uppercase;
		
		.tiny & {
			font-size: 11px;
		}
	}
	& .product-actions{
		& a{
			width: 100%;
			min-width: 0;
		}

		.tiny & a {
			padding: 9.8px 15px;
			font-size: 10px;
		}
	}
	& .product-label {
		position: absolute;
		top: 0;
		right: 0;
		text-align:right;
	}

	& .current-price{
		font-weight: 700;
		font-size:18px;
		text-align: left;
	}

	& .product-shipping{
		height: 16px;

		&, .cpt_product-availability span {
			font-size:12px;
		}

		.tiny & {
			height: 12px;

			&, .cpt_product-availability span {
				font-size: 11px;
				line-height: 12px;
				font-weight: 400;
			}
		}
	}
}

.product-tile-list {

	.product-variation-container {
		width: 100%;

		.product-variation {
			display: inline-block;
			text-align: right;
			width: 73%;

			label {
				text-align: left;
				padding-top: 0px;
				width: 100%;
			}

			.form-group {
				margin-bottom: $space-default /2;
				margin-left: 0px;
				margin-right: 0px;
			}
		}
	}

	.product-list-actions-container {

		* ~ * {
			margin-top: $space-default/2;
		}

	}

}

.product-list-item {
	padding-left: $space-default;
	padding-right: $space-default;

	padding-bottom: ($space-default * 2);
	list-style-type: none;

	outline: none;

	&.grid-view {
		&:nth-child(2n+1) {
			clear: both;
		}

		@media (min-width:$screen-md-min) {
			&:nth-child(2n+1) {
				clear: none;
			}
			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}

	&.list-view {
		width: 100%;
		a {
			&.product-title {
				text-align: left;
				padding-top: 0;
			}
		}
		.add-to-wishlist {
			padding-right: 0;
		}
		.add-to-compare {
			padding-right: 0;
			@media (min-width:$screen-sm-min) {
				text-align: right;
				display: block;
			}
		}
		.add-to-quote {
			padding-right: 0;
		}
	}
}




.filtered-products {
	.product-list {
		.product-list-item {
			&.grid-view {
				&:nth-child(2n+1) {
					clear: both;
				}

				@media (min-width:$screen-md-min) {
					&:nth-child(2n+1),
					&:nth-child(3n+1) {
						clear: none;
					}
					&:nth-child(4n+1) {
						clear: both;
					}
				}
			}
		}
	}
}

.product-list-container {
	@include clearfix();
	padding-bottom: ($space-default * 2);

	a.view-all {
		display: block;
		clear: both;
	}
	[class*='icon-arrow-']{
		&:not(.icon){
			&:before{
				filter: none;
			}
		}
	}
}

.express-shop {
	.product-img-thumbs {
		width: auto;
	}
	.product-thumb-set {
		margin-right: $space-default;
	}
}

.product-image-container {
	position: relative;
	&:hover {
		.express-shop-trigger {
			display: block;
		}
	}
}

.express-shop-trigger {
	display: none;
	margin: auto;
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
	cursor: pointer;
	background-color: $CORPORATE-PRIMARY;
	opacity: 0.93;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	color: $text-color-inverse;
	text-align: center;

	&:hover{
		background-color: $CORPORATE-SECONDARY;
	}
	.glyphicon {
		font-size: 30px;
		padding: 25px 0px;
	}
}

.list-view {
	.product-label {
		top: 15px;
	}
}

.full-page-grid-view-product-list{
	.product-list:not(.slick-carousel) {
		.product-list-item {
			&:nth-child(2n+1) {
				clear: both;
			}
			@media (min-width:$screen-sm-min) {
				&:nth-child(2n+1) {
					clear: none;
				}
				&:nth-child(3n+1) {
					clear: both;
				}
			}
			@media (min-width:$screen-md-min) {
				&:nth-child(2n+1) {
					clear: none;
				}
				&:nth-child(3n+1) {
					clear: none;
				}
				&:nth-child(4n+1) {
					clear: both;
				}
			}
		}
	}
}


article.product-tile{
	display: flex;
	flex-direction: column;
	height: 100%;
	border: 1px solid white;
	background: color(light);
	padding:20px;
	& .product-tile-gallery{
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	& .product-tile-infos{
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		align-items: flex-start;
	}

}