/************************  Global Header ******************************/
.top-header {
	position: relative;
	background: $header-bkg-top;
	padding:10px 0;
	&:before{
		content:"";
		position: absolute;
		bottom:0;
		left:0;
		width: 100%;
		height:2px;
		background-image: linear-gradient(to right, white , #000000);
		z-index: 10;
	}
	& ul li{
		display: inline-block;
		margin-left:10px;
		& a{
			color:#000000;
			text-decoration: underline;
		}
	}
}
.mid-header {
	background: $header-bkg-mid;
	& .inner{
		/*display:flex;
        flex-wrap: wrap;
        align-items: center;*/
		display:table;
		width:100%;
		& > *{
			display: table-cell;
			vertical-align: middle;
			padding:25px 20px;
			@media (max-width:$screen-xs-max) {
				padding:10px 5px;
			}
			&:first-child{
				padding-left:0;
			}
			&:last-child{
				padding-right:0;
			}
		}
		& > div.logo-cell{
			width: 180px;
			& img{
				max-width:100%;
				height: auto;
			}
		}
		& > div.help-cell{
			text-align: center;
			@include x-rem(font-size,12px);
			color:$header-help;
			& strong{
				display: block;
				@include x-rem(font-size,20px);
				&:before{
					margin-right: 10px;
					@include x-rem(font-size,15px);
				}
			}
		}
		& > div.search-cell{
			position: relative;
			width: auto;
			min-width: 450px;
		}
		& > div.quickaccess-cell{
			width:auto;
			& > ul{
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 0;
				padding: 0;
				& > li {
					display: inline-block;
					list-style: none;
					padding:0 15px;
					&:last-child{
						padding-right:0;
					}
					@media (max-width:$screen-xs-max) {
						padding:0 10px !important;
					}
					& > .item{
						position:relative;
						& > a{
							color:$header-quickaccess-icon;
							& .labeltip{
								display: none;
								position:absolute;
								top:100%;
								left:50%;
								white-space: nowrap;
								@include x-rem(font-size,14px);
								transform: translateX(-50%);
								&.show{
									display: block;
								}
								&.top{
									text-transform: capitalize;
									top:-65%;
								}
							}
							&:hover{
								color: $header-quickaccess-icon-hover;
								& .labeltip{
									display: block;
								}
							}
						}

						.logout{
							white-space: nowrap;
							@include x-rem(font-size,14px);
							@include x-rem(padding-left,6px);
						}

					}
					& .icon{
						@include x-rem(font-size,25px);
					}
				}
			}
			.bullet-count-text{
				position:absolute;
				top: -5px;
				left: 15px;
				width:20px;
				height:20px;
				text-align: center;
				line-height: 20px;
				background: $header-quickaccess-bulle-bkg;
				color:$header-quickaccess-bulle-text;
				border-radius: 100%;
			}
		}
	}
}

.qbox-wrapper-quickaccessaccount{
	top: calc(100% + 20px);
	right: -25px;
	width: 250px;
	box-shadow: 0 0 5px 0 #cccccc;
	z-index: 11000;
	background: #ffffff;
	border: 1px solid #F1F1F1;
	& .line{
		padding:10px 20px;
		border-bottom: 1px solid  #F1F1F1;
	}
	& ul{
		display:block;
		li{
			display:block;
			padding:0 0 5px;
			text-align: left;
		}
	}

	&:after, &:before {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #ffffff;
		border-width: 10px;
		margin-left: calc(50% - 44px);
	}
	&:before {
		border-color: rgba(191, 191, 191, 0);
		border-bottom-color: #F1F1F1;
		border-width: 11px;
		margin-left: calc(50% - 45px);
	}
}

.mid-search{
	display:table;
	width:100%;
	background: $header-bkg-mid;
	& > *{
		display: table-cell;
		vertical-align: middle;
		padding:5px 10px;
	}
	& > div.hamburger{
		@include x-rem(font-size,35px);
	}
	& form{
		border-radius: 4px;
	}
}
.bottom-header {
	background: $header-bkg-bottom;
}


@media (max-width:$screen-xs-max) {
}