.dt-c-AccountNavigation {
    flex: 0 0 100%;
    @include rem(padding, 0 0 30px 0);

    & ul > li {
        @include rem(margin-bottom, 10px);
    }

    &-item {
        display: block;

        & > span {
            display: flex;
            align-items: center;
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            background: linear-gradient(to right, color(secondary) 50%, transparent 50%);
            background-size: 200% 110%;
            background-position: right bottom;
            transition: all .2s ease-in-out;

            & span:nth-child(1) {
                @include rem(padding, 5px);
                color: color(primary);
            }

            & span:nth-child(2) {
                @include rem(font-size, 13px);
                font-weight: 700;
                color: color(dark);
            }

            .icon {
                @include rem(width, 30px);
                @include rem(margin, 0 10px 0 5px);
                display: block;

                img {
                    width: 100%;
                    height: auto;
                    filter: invert(38%) sepia(24%) saturate(675%) hue-rotate(91deg) brightness(89%) contrast(85%);
                }
            }
        }
    }

    &-subitem {
        @include rem(padding, 0 30px 0 34px);

        a {
            @include rem(font-size, 13px);

            &.active {
                font-weight: fw(bold);
            }
        }
    }

    &-logout {
        @include rem(font-size, 13px);
        text-decoration: underline;

        &:hover {
            color: color(dark);
        }
    }

    &-burgerToggler {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 100%;
        background-color: color(tertiary);
        border-color: color(tertiary);
        color: color(light);

        &:not(:first-child) {
            margin-top: 15px;
        }

        &::before {
            content: '';
            @include center-abs-y;
            left: 20px;
            width: 20px;
            height: 20px;
            background: {
                image: url('../img/icons/burger.svg');
                color: transparent;
                size: contain;
                repeat: no-repeat;
                position: center;
            };
            filter: brightness(0) invert(1);
        }
    }


    @include mq-from(large) {
        flex: 0 1 auto;
        @include rem(max-width, 250px);
        width: 100%;
        height: 100%;
        border-width: 0 1px 0 0;
        border-style: solid;
        border-image: linear-gradient(
                        to bottom,
                        map-get($colors, border),
                        rgba(0, 0, 0, 0)
        ) 1 100%;

        [data-dtdisplay='large'] {
            display: block;
        }

        &-burgerToggler, &-titleBis, &-top {
            display: none;
        }

        &-list {
            padding-top: 50px;
        }

        &-item {
            @include rem(padding-right, 30px);
            width: calc(100% + 3px);

            &:not(.active) {
                @include rem(padding-right, 36px);
            }

            a {
                &:hover {
                    color: color(dark);
                    transition: all .2s ease-in-out;
                }
            }

            &.active,
            &:hover {
                & > span {
                    background-position: left bottom;

                    & span:nth-child(1) {
                        color: map-get($colors, black);
                    }

                    .icon {
                        img {
                            filter: brightness(0);
                        }
                    }
                }
            }

            &.active {
                border-right: 6px solid map-get($colors, secondary);
            }
        }

        &-subitem {
            @include rem(padding, 0 10px 0 55px);
            @include rem(margin, 10px 0);

            a {
                @include rem(font-size, 13px);

                &.active {
                    font-weight: fw(bold);
                }
            }
        }

        &-logout {
            @include rem(margin-top, 30px);
            @include rem(padding-left, 55px);
        }
    }

    @include mq-to(large) {
        padding: 0;

        [data-dtdisplay='small'] {
            display: block;
        }

        &-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 24px 0;
            overflow: auto;
            overscroll-behavior: contain;

            // Hide if not in layout
            .dt-c-AccountNavigation & {
                position: absolute;
                opacity: 0;
                pointer-events: none;
            }
        }

        &-list {
            margin: 24px 0;

            > li {
                padding: 0 15px;
            }
        }

        &-item {
            &[data-toggle='collapse'] {
                position: relative;

                &::after {
                    content: '';
                    @include center-abs-y;
                    right: 0;
                    display: block;
                    width: 9px;
                    height: 15px;
                    background: {
                        image: url("../img/icons/arrow-right-small.svg");
                        position: center;
                        size: contain;
                        repeat: no-repeat;
                    };
                    transition: all .2s ease;
                }

                &[aria-expanded='true'] {
                    &::after {
                        transform: translate(-8px, -50%) rotate(90deg);
                    }
                }
            }
        }

        &-logout {
            padding: 0 15px;
        }
    }
}