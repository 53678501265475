.dt-c-Choice {
  position: relative;

  &-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid color(border);
    @include rem(border-radius, 5px);
    @include rem(width, 43px);
    @include rem(height, 41px);
    cursor: pointer;

    img {
      @include rem(width, 21px);
      @include rem(margin-right, 3px);
    }
  }

  &-content {
    background: color(light);
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 10;
    @include rem(width, 143px);
    @include rem(border-radius, 5px);
    @include rem(margin-top, 7px);

    ul {
      li {
        @include rem(padding, 13px);

        &:not(:last-of-type) {
          border-bottom: 1px solid color(border--2);
        }

        a {
          font-weight: fw(semiBold);
          @include rem(font-size, 14px);
          text-decoration: underline;
        }
      }
    }
  }

  .bullet-status {
    position: absolute;
    right: 0;
    @include rem(top, 6px);
  }

  [data-show-hide="content"] {
    display: none;

    &[data-shown="true"] {
      display: block;
    }
  }


  @include mq-to(large) {
    &-content {
      right: auto;
      left: 0;
    }

    .dt-c-Layer & {
      display: none;
    }
  }
}