.dt-c-RestrictedMessage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    @include rem(margin-bottom,40px);
    @include rem(padding,0 15px);
    text-align: center;
    &:before{
        content: "";
        position: absolute;
        @include rem(top,-100px);
        left: 0;
        z-index: 10;
        @include rem(height,100px);
        width: 100%;
        display: block;
        background: color(background--light);
        background: linear-gradient(0deg, rgba(color(background--light),1) 50%, rgba(color(background--light),0) 100%);
        @include mq-from(medium){
            @include rem(top,-130px);
            @include rem(height,130px);
        }
        @include mq-from(large){
            @include rem(top,-200px);
            @include rem(height,200px);
        }
    }
    &-title{
        font-family: font(title);
        font-weight: fw(bold);
        @include rem(font-size,20px);
        @include rem(margin-bottom,10px);
        color: color(black);
    }
    &-desc{
        @include rem(margin-bottom,17px);
        /*a{
            text: {
                decoration: underline;
            }
        }*/
    }
    &-action{
        text-align: center;
    }
}
