/**************** QUICK VIEW ******************/

#quickView {
	
	h1 {
		font-size: 24px;
		color: $text-color-primary;
		font-weight: bold;
	}
	
	.product-sale-price {
		color: $text-color-secondary;
		font-size: 24px;
		font-family: $font-family-condensedregular;
	}
	
	.control-label {
		margin-top: 7px;
	}
	
	.btn-add-to-cart {
		background: $color-corporate;
		color: $color-inverse;
		font-size: 16px;
		text-align: center;
		font-weight: bold;
		text-transform: uppercase;
		font-family: $font-family-condensedregular;
		border: $border-width-default solid darken($CORPORATE-PRIMARY, 5%);
		height: 60px;
		line-height: 60px;
		width: 100%;
	}

} 