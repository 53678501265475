.dt-c-Paragraph {
    &[data-color="blackyellow"],
    &[data-color="whitebrown"],
    &[data-color="whitegreen"] {
        @include rem(padding, 20px);

        p:first-child {
            margin-top: 0;
        }
    }

    &[data-color="blackyellow"] {
        color: color(black);
        background-color: rgba(249, 199, 68, 0.5);

    }

    &[data-color="whitebrown"] {
        color: color(light);
        background-color: #3A2C13;
    }

    &[data-color="whitegreen"] {
        color: color(light);
        background-color: #3F6C51;
    }
}
