.cpt_bannerseo{
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  & img{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    object-fit: cover;
    min-width: 100%;
    width: auto;
    height:auto;
  }
  & .inner{
    position: relative;
    top: 0;
    left:0;
    background: rgba(0,0,0,0.5);
    width: 45%;
    padding:20px;
    & .title{
      display: block;
      margin-bottom: 20px;
      color:white;
      @include x-rem(font-size,40px);
    }
    & .desc{
      display: block;
      color:white;
      @include x-rem(font-size,16px);
    }
  }

}

@media (max-width:$screen-xs-max) {
  .cpt_bannerseo{
    & .inner{
      width: 100%;
    }
  }
}