.dt-c-ArticleMostRead{
    @include rem(margin-bottom,32px);
    &-item{
        display: block;
        @include rem(padding,4px 0 12px 20px);
        @include rem(margin-bottom,15px);
        @include rem(border-width,0 0 0 4px);
        border: {
            style: solid;
            color: color(secondary);
        }
        &--link{
            @include a11yhover {
                color: color(dark);
            }
        }
    }
}