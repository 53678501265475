[class*='icon-']{
  display: inline-block;
  line-height: 1;
  &:before{
    display:inline-block;
    line-height: 1;
    font-style: normal;
  }
}
@mixin createfontFace($name, $slug, $weights:('regular'), $style:'normal') {
  @each $weight in $weights {
    @font-face {
      font-family: $name;
      src: url('../fonts/#{to-lower-case($slug)}/#{capitalize($slug)}-#{capitalize($weight)}.woff2') format('woff2'),
      url('../fonts/#{to-lower-case($slug)}/#{capitalize($slug)}-#{capitalize($weight)}.woff') format('woff');
      font-weight: fw(uncapitalize($weight));
      font-style: unquote(uncapitalize($style));
      font-display: swap;
    }
  }
}

// Barlow
/*@include createfontFace('Barlow', 'barlow', ('light', 'regular', 'bold'));*/

// OpenSans
/*@include createfontFace('Open Sans', 'openSans', ('regular', 'semiBold', 'bold', 'extraBold'));*/

// Raleway
/*@include createfontFace('Raleway', 'raleway', ('bold', 'extraBold'));*/
