.storelocator-header{
  box-shadow: 0 10px 5px -10px #dcdcdc;
  padding:20px;
  position: relative;
  z-index: 10;
}

.storelocator-search{
  dispay:block;
  position: relative;

    & .toolbar-search{
      display: flex;
      background: #ffffff;
      box-shadow: 0 0 10px 0px #dcdcdc;
      & .form{
        flex-grow: 1;
        position: relative;
        & input{
          width: 100%;
          padding:10px 60px 10px 10px;
          font-size: 20px;
          border:0;
          background: none;
          &::placeholder{
            font-size: 12px;
          }
          &:focus{
            border:0;
            outline: none;
          }
        }
        & button{
          position: absolute;
          top:50%;
          right:20px;
          transform : translateY(-50%);
          font-size: 20px;
          color:#000000;
          background: none;
          border:0;
        }
      }
      & .conditional{
        position: relative;
        width:10px;
        & span{
          position: absolute;
          top:50%;
          left:50%;
          transform: translate(-50%,-50%);
          background: white;
          width:30px;
          height:30px;
          background: #f3f3f3f3;;
          border-radius: 100%;
          line-height: 30px;
          font-size: 12px;
          text-align: center;
        }
      }
      & .localisation{
        display:flex;
        align-items: center;
        background: $color-01;
        color:white;
        font-size: 15px;
        padding:0 10px 0 20px;
        &:before{
          font-size: 20px;
        }
        & span{
          margin-left:5px;
          @media (max-width: $screen-sm-max) {
            display:none;
          }
        }

      }
    }

}

.storelocator-map{
  position:relative;
  height:100vh;
  & .gmap-map{
    height: 100%;
  }
  & .gm-bundled-control-on-bottom{
    top: 20px !important;
    right: 50px !important;
  }
}
@media (max-width: $screen-sm-max) {
  .storelocator-map{
    height:65vh;
  }
}

.storelocator-results{
  & .title {
    padding: 20px 20px 0;
    background: #f3f3f3;
    font-size: 20px;
    font-weight: 700;
  }
  & .search {
    padding: 20px;
    background: #f3f3f3;
    &.scroll-to-fixed-fixed{
      box-shadow: 0 0 10px 0px #dcdcdc;
    }
  }
  .nbresults{
    dispay:block;
    padding: 20px;
    background: $color-01;
    color:white;
    & span{
      display: inline-block;
      background: white;
      border-radius: 10px;
      padding: 1px 10px;
      color: $color-01;
      margin-left: 10px;
      font-weight: 700;
    }
  }

}

.storelocator-markerlist{
  position: relative;
}

.storelocator-markerlist-list{
  position:relative;
  & > li{
    background: white;
    border-left:5px solid white;
    border-bottom: 1px solid #f3f3f3;
    &:hover{
      background: #f3f3f3;
      border-left:5px solid $color-01;
    }
  }

}

@media (max-width: $screen-sm-max) {
  .storelocator-markerlist{
    padding:10px;
  }
  .storelocator-markerlist-list{
    margin-top:-150px;
    box-shadow: 0 0 10px 0px #dcdcdc;
  }
}

.storelocator-markerlist-item{
  display:flex;
  cursor: pointer;
  & *{
    color:black;
  }
  & > div{
    padding:15px;
  }
  & .img{
    width:100px;
    & img{
      width:100%;
    }
  }
  & .infos{
    width:calc(60% - 100px);
    flex-grow: 1;
  }
  & .address{
    width:calc(40%);
    & address{
      margin-bottom: 0;
    }
  }
}
@media (max-width: $screen-sm-max) {
  .storelocator-markerlist-item{
    font-size: 10px;
    & > div{
      padding:10px;
    }
    & .img{
      display:none;
    }
    & .infos{
      width:60%;
    }
    & .address{
      width:40%;
    }
  }
}