/**************** CATEGORY FILTER PANEL ******************/

/* FILTER PANEL */

.filter-clear {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 28px 4px 12px;
	@include fs(13px, 16px);
	color: color(light);
	background-color: color(dark);
	border-radius: 12px;

	&::before, &::after {
		content: '';
		position: absolute;
		top: 50%;
		right: 8px;
		width: 13px;
		height: 1px;
		background-color: currentColor;
	}
	&::before {
		transform: translate(0, -50%) rotate(45deg);
	}
	&::after {
		transform: translate(0, -50%) rotate(-45deg);
	}
}

.filter-group {
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-color: darken(color(border), 30%);

	&:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
		border-bottom: none;
	}

	h3 {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 17px;
		@include fs(16px, 19px);
		color: color(primary);
		font-family: font(title);
		font-weight: fw(bold);
		text-transform: none;
		cursor: pointer!important;
	
		// Override glyphicon
		[class*='glyphicon-chevron-']::before {
			content: '';
			display: block;
			background: {
				position: center;
				size: contain;
				repeat: no-repeat;
			};
			filter: brightness(0);
		}
	
		.glyphicon-chevron-down::before {
			width: 15px;
			height: 9px;
			background-image: url('../img/icons/arrow-down-small.svg');
		}
	
		.glyphicon-chevron-right::before {
			width: 9px;
			height: 15px;
			background-image: url('../img/icons/arrow-right-small.svg');
		}
	}

	ul.filter-list {
		&:not(:first-child) {
			margin-top: 13px;
		}
	}

	li.filter-item {
		position: relative;
		padding: 0;
		user-select: none;

		&:not(:first-child) {
			margin-top: 13px;
		}
		&:not(:last-child) {
			margin-bottom: 13px;
		}

		&.filter-layer1 {
			margin-left: 18px;
		}

		&, a.filter-item-name {
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			color: color(black);
			text-transform: capitalize;
			@include fs(13px, 14px);
		}
		
		a.filter-item-name:hover, a.filter-item-name:focus {
			span[class^='icon-'] {
				&.icon-notselected::after {
					opacity: 0.4;
					transform: scale(0.5);
				}
			}
		}

		a.filter-clear {
			z-index: 1;
			@include cover-abs;
			opacity: 0;
		}

		$item: &;

		// Fake checkbox
		span[class^='icon-'] {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 18px;
			height: 18px;
			margin-right: 9px;
			border: 1px solid color(black);
			background-color: color(light);
			transition: all .2s ease;

			// Tick
			&::after {
				content: '';
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				background-color: color(black);
				transform: scale(0);
				opacity: 0;
				transition: all .2s ease;
			}

			&.icon-selected::after {
				transform: scale(1);
				opacity: 1;
			}
		}
	}
}