/**************** CATEGORY FILTER ROW ******************/

.filters-row {
	margin-bottom:20px;
	z-index: 99999;

	.search-count {
		line-height: $input-height-base;
		
		> span {
			font-size: 14px;
			font-weight: bold;
		}
	}
	
	.sort-by {

		label {
			float: right;
			font-weight: normal;
			line-height: $input-height-base;
			margin-right: ($space-default *2/3);
			margin-bottom: 0px;
		}
		
		select {
			float: right;			
			
			@media (max-width:$screen-sm-max) {
				width: 100%;
			}
			
		}

	}
	
	.switch-layout {
		padding-left: 0px;
	}
}


.pagination-total {
	line-height: $input-height-base;
}

.pagination-sites {
	line-height: $input-height-base;
	padding-left: 0px;
	padding-right: 0px;

	@media (max-width: $screen-sm-max) {
		margin-bottom: $space-default;
	}
	

	.pagination-label {
		float: left;
		margin-bottom: 0px;
		font-weight: normal;
	}	
	.pagination-site-list {
		display: inline-block;
		float: left;
		list-style: none;
		margin: 0px;
		padding: 0px;
		
		li {
			float: left;
			margin-right: ($space-default);
			
			.pagination-hide {
				display: none;
			}
		}
	}	
}

.search-contentlist  {
	list-style-type: none;
	list-style-position: outside;
	list-style-image: none;
}