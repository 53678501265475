
//
// CATEGORY

.current-price {
	color: $price-current-color;
	font-family: $price-current-font;
	font-size: $price-current-size;
	font-weight: $price-current-weight;
}

.sale-price {
	color: $price-sale-color;
	font-family: $price-sale-font;
	font-size: $price-sale-size;
	font-weight: $price-sale-weight;
}

.old-price {
	color: $price-old-color;
	font-family: $price-old-font;
	font-size: $price-old-size;
	font-weight: $price-old-weight;
	text-decoration: line-through;
	margin: -5px 0;
}

.discount-per {
	text-decoration: none;
}

.price-savings{
	color: $text-color-tertiary;
	font-family: $font-family-condensedregular;
}

.secondary-actions {
	button {
		&:first-child {
			margin-right: ($space-default * 3);
		}
	}
}

.add-new-wishlist {
	display: inline-block;
}

.choose-variation-link-margin{
	margin-top: ($space-default / 2);
}

.rpd-price {
	font-weight: 400;
	font-size: 18px;
}