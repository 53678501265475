.dt-c-AccountDashboard, .dt-u-AccountDashboard {
    @include rem(padding-top, 20px);

    .dt-c-SelectionPage {
        &-list {
            flex-direction: column;
        }
    }

    &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            align-self: stretch;
        }

        &-actions {
            margin-left: auto;
        }
    }

    &-cpt-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        @include rem(margin-bottom, 20px);

        .swiper-inner {
            @include rem(margin-bottom, 20px);
            width: 100%;
            overflow: hidden;

            &.half {
                .swiper-container {
                    height: 100%;
                }
            }

            .swiper-container {
                margin-bottom: 0;
            }

            .swiper-button-round {
                @include resetSwiperIcons();

                &[class^="swiper-button-"] {
                    border-radius: 100%;
                    background: color(light);
                    @include rem(height, 34px);
                    @include rem(width, 34px);
                    transform: translateY(-50%);
                    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
                }

                [class*='icon-arrow-'] {
                    &:not(.icon) {
                        &:before {
                            @include rem(height, 16px);
                        }
                    }
                }
            }

            .swiper-button {
                &-next {
                    @include rem(right, 17px);
                    left: auto;
                }

                &-prev {
                    @include rem(left, 17px);
                    right: auto;
                }
            }

            [class^="swiper-button-"] {
                transform: translateY(-50%);
            }
        }
    }

    &-block, &--block {
        height: 100%;
        background: color(light);
        @include rem(padding, 20px);
        border-radius: 5px;

        &-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            @include rem(margin-bottom, 25px);

            &[data-note] {
                @include rem(margin-bottom, 10px);
            }

            img {
                flex: 0 0 auto;
                @include rem(margin-right, 10px);
                @include rem(width, 30px);
                filter: invert(38%) sepia(24%) saturate(675%) hue-rotate(91deg) brightness(89%) contrast(85%);
            }

            &-title, &--title {
                font-family: font(title);
                @include rem(font-size, 16px);
                font-weight: fw(bold);
                text-transform: uppercase;
                margin: 0 auto 0 0;

                span {
                    display: block;
                    @include rem(font-size, 14px);
                    text-transform: none;
                }
            }

            &-actions, &--actions {
                flex-shrink: 1;

                .btn {
                    @include rem(padding-left, 15px);
                    @include rem(padding-right, 15px);
                }
            }

            &-note {
                display: block;
                width: 100%;
                margin-top: 5px;
                padding-top: 8px;
                font-size: 12px;
                border-top: 2px solid color(secondary);
            }
        }
        &-content{
            &.chart-within{
                position: relative;
                @include rem(max-height,256px);
                @include rem(margin,0 -10px);
                .ss-content{
                    @include mq-to(medium){
                        width: 100%;
                    }
                }
            }
        }
        &.auto{
            height: auto;
        }
    }


    /**
     * Responsive
     */

    @include mq-from(medium) {
        @include rem(padding-top, 50px);
        @include rem(padding-left, 25px);

        &-block, &--block {
            &-head:not([data-note]) {
                flex-direction: row;
            }
        }
    }

    @include mq-from(large) {
        &-cpt-wrapper {
            flex-direction: row;

            .swiper-inner {
                &.half {
                    width: calc(50% - 11px);
                }
            }
        }

        &-block, &--block {
            &-head {
                &-title, &--title {
                    margin: 0 auto 0 0;
                }

                &-actions, &--actions {
                    flex-shrink: 0;
                }
            }
        }
    }

    @include mq-to(large) {
        &-top {
            flex-direction: column;

            &-actions {
                width: 100%;
                margin-top: 20px;
            }
        }

        &-block, &--block {
            &-head {
                &-title + &-actions,
                &--title + &--actions {
                    @include rem(margin-top, 10px);
                }

                &-actions, &--actions {
                    &:not(:last-child) {
                        @include rem(margin-bottom, 10px);
                    }
                }
            }
        }
    }
}


.bullet-status{
    display: block;
    @include rem(margin,0 10px);
    @include rem(width,10px);
    @include rem(height,10px);
    border-radius: 100%;
    background: #ED6E4C;
}
