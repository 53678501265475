.cpt_product-availability{
  display:inline-block;
  position: relative;
  padding-left:30px;
  & .icon{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  & .instock{
    color:$brand-success;
    font-weight: 700;
  }
  & .outofstock{
    color:$brand-danger;
    font-weight: 700;
  }
  & .ressuplying{
    color:$brand-warning;
    font-weight: 700;
  }
}