.topbutton{
  right : 15px;
  bottom: 15px;
  width:50px ;
  height:50px ;
  background: white ;
  border:none ;
  border-radius:5px;
  font-size:20px ;
  color:$color-01 ;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
  z-index:20;
  @include transition(.3s);
  &:hover,
  &:focus{
    background: $color-01 ;
    color:white;
    .icon{
      &:before{
        opacity: 1;
      }
    }
  }
  .icon{
    &:before{
      font-size: 20px;
      color: white;
      opacity: .5;
    }
  }
}