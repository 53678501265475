.footer-links{
  padding: 50px 0;
  background: $footer-links-bkg;
  color:white;
  & .title{
    display: block;
    margin-bottom: 20px;
    @include x-rem(font-size,24px);
    color:$footer-links-title;
    text-transform: uppercase;
  }

  & .subtitle{
    display: block;
    margin-bottom:10px;
    @include x-rem(font-size,16px);
    color:$footer-links-title;
    font-weight: 700;
  }

  & ul.list li a{
    display: block;
    margin-bottom:5px;
    @include x-rem(font-size,16px);
    color:$footer-links-link;
    &:hover{
      color:$footer-links-link-hover;
    }
  }
  & ul.socialnetwork li{
    display: inline-block;
    & a {
      display: inline-block;
      margin-right: 30px;
      @include x-rem(font-size, 30px);
      color: $footer-links-link;

      &:hover {
        color: $footer-links-link-hover;
      }
    }
  }
  & .btn{
    background: white;
    color: $color-07;
  }
  & .line{
    display:block;
    background: white;
    width:100%;
    height:2px;
  }
}