//
// Page Navigation

.page-navigation {
	.page-navigation-contents {
		background:white;

		& .title{
			padding: 15px;
			background: $color-01;
			color: white;
		}

		ul {
			list-style: none outside none;
			padding-left: 0;

			& a{
				display:block;
				padding:5px 15px;
			}

			&.page-navigation-0 {
				
				ul {
					padding-left: $space-default;

					&.page-navigation-1 {

					}
				}
			}

			li {
				line-height: 24px;

				&.page-navigation-active {
					> a {
						font-weight: bold;
					}
				}
			}
		}

		
	}
}