.menu-panel{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  pointer-events: none;
  & > .shadow{
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    background: white;
    opacity: 0;
    transition: all 0.3s ease;
  }
  & > .inner{
    position: absolute;
    top:0;
    left: -100%;
    width:90%;
    height:100%;
    background: white;
    box-shadow: 0 0 5px 0 #333333;
    transition: all 0.3s ease;
    overflow: auto;
  }

  &.isActive{
    pointer-events: auto;
    & > .shadow{
      opacity: 0.5;
    }
    & > .inner{
      left: 0;
    }
  }
}

@media (max-width: $screen-xs-max) {

}