.cpt_univers{
  border:0;
  &.grey{
    background: $color-03;
  }
  &-list{
    .display-grid{
      padding: 0 15px;
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
      @include rem(gap,15px);
      @include mq-from(medium) {
        grid-template-columns: repeat(2, minmax(calc(50% - 15px), 1fr));
        @include rem(gap,15px);
      }
      @include mq-from(xlarge) {
        padding: 0;
        grid-template-columns: repeat(3, minmax(350px, 1fr));
        @include rem(gap,29px);
      }
      .dt-c-NavigationServices-item{
        @include rem(height,335px);
        display: flex;
        justify-content: center;
        align-items: center;
        //background-size: cover;
        background-repeat: no-repeat;
        transition: all .2s ease-in-out;

        a {
          width: 100%;
          max-width: 100%;
          height: 100%;
        }

        @include mq-from(large) {
          @include rem(height,350px);
        }
        &:hover, &:focus-within {
          background-size: 102%;
        }
      }
    }
    & .user-loggedin{
      .dt-c-NavigationServices-item-col{
        a{
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;
        }
        &:before{
          content:none;
        }
        img{
          height: 100px;
          object-fit: cover;
          object-position: center center;
          transition: all .2s ease-in-out;
        }
        .univers-name{
          font-family: font(title);
          color: color(dark);
          @include rem(font-size,20px);
          @include rem(padding,12px);
          text-align: center;
          font-weight: 900;
          min-height: 0;
          text-transform: uppercase;
        }

        &:hover, &:focus-within {
          img {
            transform: scale(1.02);
          }
        }
      }
    }
  }
}

