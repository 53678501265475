.breadcrumbs {
  padding: 0!important;
  margin: 19px 0!important;

  li {
    text-transform: none;
    font-size: 14px;
    line-height: 16px;

    a {
      font-size: inherit;
      color: inherit;

      &:hover, &:focus {
        color: color(tertiary);
      }
    }
  }
}