.video-container {
	position: relative;

	video {
		display: block;
	}
}

.video-text {
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translate(0, -50%);
	width: 100%;

	h1 {
	color: #fff;
	font-size: 60px;
}
}

.video-link {
	display: inline-block;
	font-size: 66px;
	font-weight: bold;
	opacity: 0.7;

	&:hover {
	opacity: 1;
	cursor: pointer;
}

	@media (min-width: $screen-md) {
		font-size: 85px;
	}
}

.video-wrapper {
	position: relative;
	padding-bottom: 55%; /* 16:9 - original: 56.25%; */
	padding-top: 25px;
	height: 0;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		max-width: 100%;
		max-height: 100%;
		&:not([width]){
			width: 100%;
		}
		&:not([height]){
			height: 100%;
		}
	}
}

.cpt_video{
	.video-wrapper {
		iframe{
			width: 100%;
			height: 100%;
		}
	}
}
