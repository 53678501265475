.dt-c-Wysiwyg {
    &[data-typecomponent="infos"] {
        h2 {
            color: color(black);
            font: {
                family: font(title);
                weight: fw(semiBold);
            };
            text-transform: uppercase;
            @include rem(font-size, 32px);
            @include rem(line-height, 35px);
            @include rem(margin-bottom, 8px);
        }

        h3 {
            color: color(primary);
            font: {
                family: font(title);
                weight: fw(bold);
            };
            text-transform: uppercase;
            @include rem(font-size, 24px);
            @include rem(line-height, 26px);
            @include rem(margin-bottom, 8px);
        }

        h4 {
            font: {
                family: font(title);
            };
            text-transform: uppercase;
            @include rem(font-size, 24px);
            @include rem(line-height, 29px);
            strong {
                font: {
                    weight: fw(bold);
                };
            }
        }

        p {
            @include rem(margin-top, 8px);
        }

        ul {
            @include rem(margin-bottom, 16px);

            > li {
                @include rem(line-height, 20px);
                @include rem(margin-top, 8px);
                @include rem(padding-left, 32px);
                position: relative;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 4px;
                    z-index: 1;
                    content: "";
                    background: {
                        image: url("../img/icons/arrow-right-yellow.svg");
                        size: 19px 14px;
                        repeat: no-repeat;
                        position: center center;
                    };
                    display: inline-block;
                    width: 19px;
                    height: 14px;
                    @include rem(margin-right, 8px);
                }
            }
        }
        ol{
            counter-reset: olList;
            > li{
                @include rem(line-height, 20px);
                @include rem(margin-top, 8px);
                @include rem(padding-left, 32px);
                position: relative;
                &:before{
                    position: absolute;
                    left: 3px;
                    top: 0;
                    z-index: 1;
                    counter-increment: olList;
                    content: counter(olList);
                    display: inline-block;
                    @include rem(font-size,19px);
                    @include rem(margin-right, 8px);
                    color:color(secondary);
                    font-weight: fw(bold);
                }
            }
        }
        [data-theme="dark"]{
            h2{
                color: color(light);
            }
        }
        a{
            &:not(.btn){
                color: color(dark);
                text-decoration: underline;
                &:hover{
                    text-decoration: none;
                    color: color(tertiary);
                }
            }
        }
    }
    
    &[data-typecomponent="dashboard"]{
        @include rem(font-size,13px);
        background: color(light);
        strong{
            font-weight: fw(bold);
            color: color(primary);
        }
        .date{
            @include rem(margin-bottom,15px);
        }
        ul{
            li{
                list-style: initial;
                @include rem(margin-left,15px);
                margin-bottom: 10px
            }
        }
    }
}
