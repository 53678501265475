
//
// PANEL

.panel {
	margin-bottom: ($space-default * 2);
	background-color: $color-inverse;
	border: $border-width-default solid transparent;
	box-shadow: none;
	
	> .list-group {
		margin-bottom: 0;
	  
		.list-group-item {
			border-width: 1px 0;
		}
			 
		&:first-child {
			.list-group-item {
				&:first-child {
					border-top: 0;
				}
				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}
}

.panel-body {
	padding: ($space-default + ($space-default / 2)) 0;
}

.panel-heading {
	padding: ($space-default / 2) 0;
	border-bottom: $border-width-default solid transparent;
 	
	a:hover{
		text-decoration: none;
	}
	
	> .dropdown {
		 .dropdown-toggle {
			color: inherit;
		}
	}
	
	> a {
		display: block;
		
		&:active {
			outline: none;
		}
		&:focus {
			outline: none;
		}

		.glyphicon {
			color: $text-color-corporate;
		}
	}
	
	+ .list-group {
		 .list-group-item {
			&:first-child {
				border-top-width: 0;
			}
		}
	}
}

.panel-title {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 16px;
	color: inherit;
	
	> a {
		color: inherit;
	}
}

.panel-footer {
	padding: $space-default ($space-default + ($space-default / 2));
	background-color: $color-tertiary;
	border-top: $border-width-default solid $border-color-light;
}

.panel-group {
	margin-bottom: ($space-default * 2);
	
	.panel {
		margin-bottom: 0;
		overflow: hidden;
		
		+ .panel {
			margin-top: ($space-default / 2);
		}
	}
	
	.panel-heading {
		border-bottom: 0;
		
		 + .panel-collapse {
			.panel-body {
				border-top: $border-width-default solid $border-color-light;
			}
		}
	}
	
	.panel-footer {
		border-top: 0;
		  
		+ .panel-collapse {
			.panel-body {
				border-bottom: $border-width-default solid $border-color-light;
			}
		}
	}
}

.panel-default {
	border-color: $border-color-light;
  
	> .panel-heading {
		color: $text-color-primary;
		background-color: $color-tertiary;
		border-color: $border-color-light;
		
		+ .panel-collapse {
			.panel-body {
			  border-top-color: $border-color-light;
			}
		}
	}
	
	> .panel-footer {
		+ .panel-collapse {
			.panel-body {
				border-bottom-color: $border-color-light;
			}
		}
	}
}

.panel-primary {
	border-color: $border-color-corporate;
	
	> .panel-heading {
		color: lighten($text-color, 100%);
		background-color: $color-corporate;
		border-color:$border-color-corporate;
		
		+ .panel-collapse {
			.panel-body {
				border-top-color: $border-color-corporate;
			}
		}
	}
	
	> .panel-footer {
		+ .panel-collapse {
			.panel-body {
				border-bottom-color: $border-color-corporate;
			}
		}
	}
}

.collection {
	&.panel-group {
		.panel-heading {
			padding: 0;
			
			h4 {
				padding: $panel-heading-padding;
			}
			
			a {
				display: block;
				color: $gray;
				&:hover {
					color: $gray-dark;
				}
			}
			+ .panel-collapse {
				.panel-body {
					padding: $panel-body-padding;
					border-top: 1px solid $border-color-light;
				}
			}
		}
	}
}
