.btn-default {
  border: 1px solid $color-07;
  &:hover{
    border: 1px solid $color-01;
    color:$color-01;
  }
}
.btn-primary {
  border:0;
  &:after{
    content: "";
    position: absolute;
    bottom: 0;
    left:0;
    width: 100%;
    height: 5px;
    background:$color-01;
    z-index: -1;
    transition: all 0.1s ease-in 0s;
  }
  &:hover{
    color:white;
    &:after{
      height: 100%;
    }
  }
}