@font-face {
	font-family: "icons";
	src: url('../fonts/icons/fonts/icons.eot');
	src: url('../fonts/icons/fonts/icons.eot?#iefix') format('eot'),
		url('../fonts/icons/fonts/icons.woff2') format('woff2'),
		url('../fonts/icons/fonts/icons.woff') format('woff'),
		url('../fonts/icons/fonts/icons.ttf') format('truetype'),
		url('../fonts/icons/fonts/icons.svg#icons') format('svg');
}

.icon:before {
	font-family: "icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-accueil:before {
	content: "\E001";
}

.icon-aide:before {
	content: "\E002";
}

.icon-arrow-back:before {
	content: "\E003";
}

.icon-arrow-down-small:before {
	content: "\E004";
}

.icon-arrow-left-small-bright_sun:before {
	content: "\E005";
}

.icon-arrow-left-small:before {
	content: "\E006";
}

.icon-arrow-left:before {
	content: "\E007";
}

.icon-arrow-right-small-bright_sun:before {
	content: "\E008";
}

.icon-arrow-right-small:before {
	content: "\E009";
}

.icon-arrow-right-yellow:before {
	content: "\E00A";
}

.icon-arrow-right:before {
	content: "\E00B";
}

.icon-arrow-up-small:before {
	content: "\E00C";
}

.icon-arrowline-left:before {
	content: "\E00D";
}

.icon-arrowline-right:before {
	content: "\E00E";
}

.icon-assistance:before {
	content: "\E00F";
}

.icon-back:before {
	content: "\E010";
}

.icon-basket:before {
	content: "\E011";
}

.icon-bell:before {
	content: "\E012";
}

.icon-book:before {
	content: "\E013";
}

.icon-burger:before {
	content: "\E014";
}

.icon-calendar-fill:before {
	content: "\E015";
}

.icon-calendar:before {
	content: "\E016";
}

.icon-cb:before {
	content: "\E017";
}

.icon-cob:before {
	content: "\E018";
}

.icon-collecte:before {
	content: "\E019";
}

.icon-compte:before {
	content: "\E01A";
}

.icon-conseils:before {
	content: "\E01B";
}

.icon-contrat:before {
	content: "\E01C";
}

.icon-exploitation:before {
	content: "\E01D";
}

.icon-facebook:before {
	content: "\E01E";
}

.icon-facture:before {
	content: "\E01F";
}

.icon-farmer:before {
	content: "\E020";
}

.icon-garantie:before {
	content: "\E021";
}

.icon-gps:before {
	content: "\E022";
}

.icon-headsets:before {
	content: "\E023";
}

.icon-house:before {
	content: "\E024";
}

.icon-info:before {
	content: "\E025";
}

.icon-liens:before {
	content: "\E026";
}

.icon-like-off:before {
	content: "\E027";
}

.icon-like-on:before {
	content: "\E028";
}

.icon-linkedin:before {
	content: "\E029";
}

.icon-livraison:before {
	content: "\E02A";
}

.icon-mail:before {
	content: "\E02B";
}

.icon-marche:before {
	content: "\E02C";
}

.icon-paiement:before {
	content: "\E02D";
}

.icon-play:before {
	content: "\E02E";
}

.icon-publications:before {
	content: "\E02F";
}

.icon-question:before {
	content: "\E030";
}

.icon-search:before {
	content: "\E031";
}

.icon-shipping:before {
	content: "\E032";
}

.icon-shop:before {
	content: "\E033";
}

.icon-speech:before {
	content: "\E034";
}

.icon-stats:before {
	content: "\E035";
}

.icon-twitter:before {
	content: "\E036";
}

.icon-user:before {
	content: "\E037";
}

.icon-arrow-bottom:before {
	content: "\E038";
}

.icon-arrow-top:before {
	content: "\E039";
}

.icon-calling:before {
	content: "\E03A";
}

.icon-close:before {
	content: "\E03B";
}

.icon-compare-off:before {
	content: "\E03C";
}

.icon-compare-on:before {
	content: "\E03D";
}

.icon-expedition:before {
	content: "\E03E";
}

.icon-fast:before {
	content: "\E03F";
}

.icon-hamburguer:before {
	content: "\E040";
}

.icon-information:before {
	content: "\E041";
}

.icon-left-arrow:before {
	content: "\E042";
}

.icon-localisation:before {
	content: "\E043";
}

.icon-loupe:before {
	content: "\E044";
}

.icon-magasin:before {
	content: "\E045";
}

.icon-print:before {
	content: "\E046";
}

.icon-star-off:before {
	content: "\E047";
}

.icon-star-on:before {
	content: "\E048";
}

.icon-telecharge:before {
	content: "\E049";
}

.icon-view:before {
	content: "\E04A";
}

.icon-view_unvisibility:before {
	content: "\E04B";
}

.icon-view_visibility:before {
	content: "\E04C";
}

.icon-youtube:before {
	content: "\E04D";
}
