.minicart-panel{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 12000;
  pointer-events: none;
  & > .shadow{
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    background: white;
    opacity: 0;
    transition: all 0.3s ease;
  }
  & > .inner{
    position: absolute;
    top:0;
    right: -360px;
    width:340px;
    height:100%;
    background: white;
    box-shadow: 0 0 5px 0 #333333;
    transition: all 0.3s ease;
    & .top{
      position: absolute;
      width: 100%;
      height:120px;
      top:0;
      left:0;
      background: $color-01;
      color:white;
      @include x-rem(font-size,20px);
      & > *{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width:calc(100% - 50px);
        padding-left:70px;
      }
      & .totalprice{
        @include x-rem(font-size,30px);
      }
      & .icon{
        position: absolute;
        top:50%;
        left: 0;
        transform: translateY(-50%);
        @include x-rem(font-size,40px);
      }
    }
    & .middle{
      position: absolute;
      top:120px;
      left: 0;
      width:100%;
      height:calc(100% - 210px);
      overflow-x: hidden;
    }
    & .actions{
      position: absolute;
      bottom:0;
      left: 0;
      width:100%;
      height:90px;
      & > *{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width:calc(100% - 50px);
      }
    }
  }

  &.isActive{
    pointer-events: auto;
    & > .shadow{
      opacity: 0.5;
    }
    & > .inner{
      right: 0;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .minicart-panel{
    & > .inner{
      right: -110%;
      width:80%;
    }
  }
}

.minicart-item{
  display: flex;
  padding:20px;
  border-bottom: 1px solid $color-03;
  & > .img{
    width:100px;
    & img{
      width: 100%;
      height: auto;
    }
  }
  & > .infos{
    width:100%;
    padding-left: 20px;
    @include x-rem(font-size,14px);
    & .name{
      color:$color-07;
    }
    & .price{
      margin-top: 20px;
      color:$color-01;
    }
    & .qty{
      @include x-rem(font-size,12px);
    }
  }
}