
//
// QUICK CART

ul {
	
	&.quick-links {
		float: left;
		list-style: none outside none;
		margin: 0;
		padding: $space-default ($space-default * 2) 0 0;
		
		li {
			float: left;
			margin-left: ($space-default * 2);
		}
		
		a {
			color: $text-color-secondary;
			font-size: $font-size-base;
			text-decoration: none !important;
			text-transform: uppercase;
			font-family: $font-family-condensedregular;
		}
	}
}

.quick-cart-link {
	float: right;
	background: $color-tertiary;
	padding: 9px $space-default 8px;
	position: relative;
	
	a {
		font-family: $font-family-bold;
		color: $text-color-quarternary;
		font-size: $font-size-base;
		text-transform: uppercase;
		text-decoration: none !important;
		cursor: pointer;
		
		span {
			&.mini-cart-price {
				color: $text-color-corporate;
				font-size: $font-size-base;
				white-space: nowrap;
			}
		}
		.glyphicon:before {
			font-size: $font-size-large;
			margin-right: ($space-default / 2);
		}
	}
	
	> a {
		padding: 0 ($space-default / 2);
		white-space: nowrap;
	}
	
	&.mini-cart-active {
		background: $color-corporate;
		
		a {
			color: $text-color-inverse;
			
			 span {
				 &.mini-cart-price {
					color: $text-color-inverse;
				}
			}
		}
	}
	
	.mini-cart {
		position: absolute;
		top: 40px;
		background: $color-inverse;
		right: -1px;
		z-index: 9999;
		width: 310px;
		padding: $space-default;
		box-shadow: 0 4px 7px rgba(0, 0, 0, 0.23);
		 
		.product-rows-block {
			max-height: 228px;
			position: relative;
			overflow: hidden;
			
			.slider {
				max-height: 208px;
				position: relative;
			}
		}
		
		.btn-scroll-up {
			cursor: pointer;
			height: 22px;
			color: $text-color-corporate;
			width: 100%;
			margin: $space-default 0 0;
			padding: 2px 0 0 0;
			text-align: center;
			
			&.disabled {
				color: $text-color-quinary;
			}
		}
		
		.btn-scroll-down {
			cursor: pointer;
			height: 22px;
			color: $text-color-corporate;
			width: 100%;
			margin: 0 0 $space-default;
			padding: 2px 0 0 0;
			text-align: center;
			
			&.disabled {
				color: $text-color-quinary;
			}
		}
		
		.view-cart {
			font-family: $font-family-condensedbold;
			font-size: $font-size-large;
			margin-top: ($space-default *2);
		}
	}
}

.product-row {
	display: block;
	@include clearfix();
	padding: $space-default 0;

	&~.product-row {
		border-top: $border-width-default solid $border-color-light;					
	}
	
	.mini-product-img {
		width: 30%;
		text-align: center;
		float: left;
	}
	
	.mini-product-info {
		float: right;
		text-align: left;
		width: 70%;
		padding-left: $space-default;
		
		a {
			font-family: $font-family-condensedregular;
			color: $text-color-quarternary;
			font-size: $font-size-base;
			font-weight: normal;
			text-transform: none;
			text-overflow: ellipsis;
			margin: 0;
			display: block;
			overflow: hidden;
			white-space: nowrap;
			padding-bottom: ($space-default / 4);
			padding-top: ($space-default / 2);
		}
		
		.product-price {
			font-family: $font-family-condensedbold;
			color: $text-color-primary;
			font-size: $font-size-large;
			padding-bottom: ($space-default / 4);
		}
		
		.product-size {
			font-family: $font-family-condensedregular;
			color: $text-color-secondary;
			font-size: $font-size-base;
		}

		.cart-pli-data {
			font-size: 85%;
			color: $text-color-quarternary;
			
			span {
				width: 80px;
				display: inline-block;
			}
		}
		
		.qty-label {
			font-size: 85%;
			color: $text-color-quarternary;
			
			span {
				width: 75px;
				display: inline-block;
			}
		}
	}
}
