.dt-c-SelectDropdown{
  position: relative;
  &-toggle{
    cursor: pointer;
    display: block;
    height: 41px;
    width: 100%;
    padding: 10px;
    background-color: color(light);
    border: 1px solid color(border);
    border-radius: 5px;
    &:after{
      content: '';
      display: block;
      width: 8px;
      height: 14px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: {
        image: url('../img/icons/arrow-down-small.svg');
        position: center;
        size: contain;
        repeat: no-repeat;
      };
      filter: brightness(0);
    }
    &[data-shown="true"]{
      border-radius: 5px 5px 0 0;
      border-bottom: none;
      &:after{
        background: {
          image: url('../img/icons/arrow-up-small.svg');
        };
      }
    }
  }
  &-content{
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 10;
    &[data-shown="false"]{
      display: none;
    }
    &[data-shown="true"]{
      display: block;
      padding: 10px;
      background-color: color(light);
      border: 1px solid color(border);
      border-top: none;
      border-radius: 0 0 5px 5px;
    }
  }
}