@mixin center-x() {
  margin-right: auto;
  margin-left: auto;
}

@mixin center-abs-x() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-abs-y() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-abs-xy() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin cover-abs() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; // If media
  object-position: center;
}
