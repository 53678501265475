.dt-c-Header {
  z-index: 99;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background-color: color(light);
  transition: all .2s ease-in-out;

  &-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }

  &-logo {
    padding: 20px 0;

    img {
      display: block;
      width: 109px;
      height: auto;
    }
  }

  &-navigation {
    align-self: stretch;
    margin-left: 20px;
    padding: 30px 0 20px;
    .dt-c-Navigation{
      @include mq-to(large){
        display: none;
      }
    }
  }

  &-shortcuts {
    margin-left: auto;
  }


  @include mq-from(large) {
    [data-dtdisplay='large'] {
      display: block;
    }

    body.homepage:not(.logged) & {
      position: fixed;

      &:not([data-sticky='true']) {
        background-color: transparent;
      }
    }
  }


  @include mq-to(large) {
    [data-dtdisplay='small'] {
      display: block;
    }

    &-logo {
      padding: 7px 0;

      img {
        width: 55px;
      }
    }

    &-shortcuts {
      align-self: stretch;
    }
  }
}