.dt-c-Datepicker{
    position:relative;  
    display: block;  
    input{
        @include rem(padding-right,45px);
        & +.icon{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include rem(right,10px);
        }
    }
}