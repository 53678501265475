
//
// CHECKOUT - PROGRESS BAR

.progress-bar-panel {
	background: $color-tertiary;
	overflow: auto;
}

.progress-steps {
	list-style: none;
	margin: 0;
	padding: 0;	
	
	li {
		color: $color-01;
		border-bottom: 4px solid #cccccc;
		line-height: 50px;
		float: left;
		width: 20%;
		padding-left: $space-default;
		
		@media (max-width:$screen-xs-max) {	
			line-height: 25px;
			text-align: center;
			padding-top: $space-default;
			padding-left: $space-default/4;	

			.progress-label {
				font-size: 85%;
				display: block;
			}			
		}
	}
	
	.progress-active {
		color: $color-01;
		border-bottom: 4px solid $color-01;
				
		.progress-number {
			background: $color-01;
			color: white;
		}
	}
	
	.progress-complete {
		color: white;
		border-bottom:solid 4px $color-01;
	}
}

.progress-number {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: (($space-default * 3) / 2);
	text-align: center;
	line-height: 30px;
	font-weight: $font-family-bold;
	background: white;
	color: $color-01;
}

.progress-complete {

	.progress-number {		
		background: $color-01;
		color: white;
		border: 2px solid $color-01;
		line-height: 26px;	
	}

}

