//
// MY ACCOUNT - OVERVIEW

.account-wrapper {

	.grey-panel {
		h2 {
			font-weight: bold;
			margin: 0;
		}
	}
	
	.account-main {
		padding: $space-default 0;
	
		h1, h2, h3 {
			margin-top: 0;
		}
		
		h1 {
			.btn {
				 @media (max-width: $screen-xs-max) {
					display: block;
					width: 100%;
					margin-top: $space-default;
					margin-bottom: $space-default;
					clear: both;
				 }
				 
				  @media (min-width: $screen-sm-min) {
					float:right;
				  }
			}
		}
	}
	
	.cost-summary {
		border: none;
	}
	
}

.user-profile {
	.h3 {
		margin-top: 0;
	}

}

//
// MY ACCOUNT - SINGLE DEFINITIONS

.birthday-input {
	.form-control {
		width: 25%;
		margin-right: 2%;
		float: left;
		
		&:last-child {
			margin-right: 0;
		}
	}
}

.dl-profile dd {
	text-align: left;
}

/* Wishlist */

.product-img-box {
    float: right;
    width: 70%;
}

.table-checkbox {
    display: inline-block;
    margin: -4px 4% 0 0;
} 


//
// MY ACCOUNT - SINGLE DEFINITIONS

.order-detail-box {
	&:first-child, &:nth-child(2) {
		@media (max-width: $screen-xs-max) {
			min-height: 180px;
		}

		@media (max-width: $screen-xs-max) {
			min-height: auto;
			margin-bottom: $space-default;
		}

		@media (min-width: $screen-sm-min) {			
			margin-bottom: $space-default;
		}
	}

	@media (max-width: $screen-xs-max) {
		min-height: auto;
		margin-bottom: $space-default;
	}

	address {
		margin-bottom: 0;
	}

	.infobox {
		width: 100%;
	}
}

//
// ADDRESSES

.myAccount-addressBook  {
	.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
		@media (min-width: $screen-sm-min) {
			&.dropdown {
				width: auto;
			}	
		}	
		@media (max-width: $screen-xs-max) {
			width: 100%;
			button	{			
				font-family:$font-family-condensedbold;				
			}
		}
	}	
}

