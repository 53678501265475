.footer-credit{
  padding: 20px 0;
  background: $footer-credit-bkg;
  text-align: center;
  & ul{
    display: inline-block;
    & li{
      display: inline-block;
      @include x-rem(font-size,16px);
      color:$footer-credit-link;
      & a{
        margin:0 10px;
        @include x-rem(font-size,16px);
        color:$footer-credit-link;
        &:hover{
          color:$footer-credit-link-hover;
        }
      }
    }
  }
}