@import "mixins/grid-flex-framework";
@import "mixins/grid-flex";
//
// Grid system
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  @include container-fixed;
  width: 100%;
}
.container-no-gutters {
  width: 100% !important;
  padding: 0 !important;
  & > .row {
    margin: 0;
    & > [class*='col-'] {
      padding: 0;
    }
  }
}

// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row;
}

.row-no-gutters {
  margin-right: 0;
  margin-left: 0;

  [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}



// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.col-xs-auto{flex: 1 1 auto;width:auto;position: relative;min-height: 1px;padding-right: floor(($grid-gutter-width / 2));padding-left: ceil(($grid-gutter-width / 2));}
.col-xs-initial{flex: 0 1 auto;width:auto;position: relative;min-height: 1px;padding-right: floor(($grid-gutter-width / 2));padding-left: ceil(($grid-gutter-width / 2));}
@include make-grid(xs);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
  .col-sm-auto{flex: 1 1 auto;width:auto;position: relative;min-height: 1px;padding-right: floor(($grid-gutter-width / 2));padding-left: ceil(($grid-gutter-width / 2));}
  .col-sm-initial{flex: 0 1 auto;width:auto;position: relative;min-height: 1px;padding-right: floor(($grid-gutter-width / 2));padding-left: ceil(($grid-gutter-width / 2));}
  @include make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
  .col-md-auto{flex: 1 1 auto;width:auto;position: relative;min-height: 1px;padding-right: floor(($grid-gutter-width / 2));padding-left: ceil(($grid-gutter-width / 2));}
  .col-md-initial{flex: 0 1 auto;width:auto;position: relative;min-height: 1px;padding-right: floor(($grid-gutter-width / 2));padding-left: ceil(($grid-gutter-width / 2));}
  @include make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
  .col-lg-auto{flex: 1 1 auto;width:auto;position: relative;min-height: 1px;padding-right: floor(($grid-gutter-width / 2));padding-left: ceil(($grid-gutter-width / 2));}
  .col-lg-initial{flex: 0 1 auto;width:auto;position: relative;min-height: 1px;padding-right: floor(($grid-gutter-width / 2));padding-left: ceil(($grid-gutter-width / 2));}
  @include make-grid(lg);
}

.dflex {
  display: flex;
  flex-wrap: wrap;
}

$flex-wrap:nowrap,wrap,wrap-reverse;
@each $item in $flex-wrap {
  .flex-#{$item} {flex-wrap : $item;}
  @media (min-width: $screen-sm-min) {
    .sm\:flex-#{$item} {flex-wrap : $item !important;}
  }
}

$align-items: flex-start,flex-end,center,baseline,stretch,inherit,initial,unset;
@each $item in $align-items {
  .align-items-#{$item} {
    align-items : $item;
  }
}

$justify-content: flex-start,flex-end,center,space-between,space-evenly,space-around,inherit,initial,unset;
@each $item in $justify-content {
  .justify-content-#{$item} {
    justify-content : $item;
  }
}