// .nq-.. given for retro-compatibility

$pOld: unquote('.nq-c-Footer');

.dt-c-Footer {
  z-index: 0;
  position: relative;
  margin-top: 45px;

  // background free to grow upside
  &-bg {
    z-index: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    img {
      min-height: 304px;
      object: {
        fit: cover;
        position: center bottom;
      };
      pointer-events: none;
      user-select: none;
    }
  }

  #{$pOld}-links, &-links,
  #{$pOld}-credit, &-credit {
    z-index: 1;
    position: relative;
  }

  #{$pOld}-links, &-links {
    &-wrapper {
      display: flex;
      margin: -25px;

      > * {
        margin: 25px;
      }
    }

    &-group {
      strong {
        display: block;
        margin-bottom: 13px;
        @include fs(16px, 17px);
        font-weight: fw(semiBold);
      }

      ul {
        display: inline-block;

        li {
          @include fs(14px, 16px);

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      &[data-type='organisation'] {
        flex: 0 0 160px;

        img {
          display: block;
          margin: 0 auto;

          &.uneal {
            max-width: 128px;
          }
        }

        .btn {
          margin: 30px 0;
        }
      }

      &[data-type='links'] {}

      &[data-type='network'] {
        strong {
          margin-bottom: 25px;
          text-align: center;
        }

        ul {
          display: inline-flex;
          align-items: center;

          li {
            font-size: 30px;

            &:not(:last-child) {
              margin-right: 30px;
            }
          }
        }
      }
    }
  }

  #{$pOld}-credit, &-credit {
    margin-top: 45px;
    padding-bottom: 21px;
    text-align: center;

    li {
      display: inline-block;
      @include fs(14px, 16px);

      &:not(:last-child) {
        margin-right: 5px;
        padding-right: 5px;
        border-right: 1px solid currentColor;
      }
    }
  }


  @include mq-from(large) {
    padding-top: clamp(100px, 24vw, 334px);
    color: color(light);

    #{$pOld}-links, &-links {
      &-group {
        &[data-type='organisation'] {
          img {
            filter: brightness(0) invert(1);
          }

          // Force white mode
          .btn {
            display: block;
            min-width: 100%;

            &:not(:hover) {
              color: color(light);
            }
          }
        }
      }
    }
  }

  @include mq-to(large) {
    padding-top: 30px;
    background-color: color(light);

    &-bg {
      // Fade in top of image
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 26%;
        z-index: 1;
        background: linear-gradient(to bottom, white 0%, transparent 100%);
      }
    }

    #{$pOld}-links, &-links {
      &-wrapper {
        flex-direction: column;
        text-align: center;
        margin: -15px;

        > * {
          margin: 15px;
        }
      }

      &-group {
        strong {
          margin-bottom: 5px;
          @include fs(14px, 16px);
        }

        ul {
          li {
            @include fs(12px, 14px);

            &:not(:last-child) {
              margin-bottom: 4px;
            }
          }
        }

        &[data-type='organisation'] {
          img {
            &.uneal {
              max-width: 96px;
            }

            &.advitam {
              max-width: 104px;
              filter: brightness(0);
            }
          }

          .btn {
            margin: 20px auto;
          }
        }

        &[data-type='network'] {
          color: color(light);

          strong {
            margin-bottom: 18px;
          }

          ul {
            li {
              font-size: 21px;

              &:not(:last-child) {
                margin-right: 24px;
              }
            }
          }
        }
      }
    }

    &-credit {
      margin-top: 30px;
      padding-bottom: 16px;

      li {
        @include fs(11px, 13px);
      }
    }
  }


  // Handle black/white text depending on background
  @include mq-to(large) {
    #{$pOld}-credit, &-credit {
      color: color(light);
    }
  }
  @include mq-from-to(small, medium) {
    #{$pOld}-links, &-links {
      &-group:nth-last-child(-n+2) {
        color: color(light);
      }
    }
  }
  @include mq-from-to(medium, large) {
    #{$pOld}-links, &-links {
      &-group:nth-last-child(-n+3) {
        color: color(light);
      }
    }
  }
}