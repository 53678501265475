.dt-c-Category-tag{
    text-transform: uppercase;
    @include rem(font-size, 14px);
    font-weight: fw(bold);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include rem(border-radius, 18px);
    background: color(secondary);
    color:color(dark);
    @include rem(padding, 4px 16px);
    .icon{
        display: inline-block;
        @include rem(margin-right, 5px);
        @include rem(font-size, 15px);
    }
}