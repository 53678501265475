// ================
// Fonts
// ================

$fonts: (
  title: '"Barlow", Arial, sans-serif',
  text: '"Open Sans", Arial, sans-serif',
  product: '"Raleway", Arial, sans-serif',
);

$base-fs: 16px;
$base-lh: 1.38;

$font-weights: (
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
);


// ================
// Colors
// ================

$colors: (
  light: #FFFFFF,
  black: #000000,
  dark: $color-06,
  primary: $color-01,
  secondary: #F9C744,
  secondary--faden: #f5db95,
  tertiary: $color-04,
  background--light: $color-03,
  background--dark: #3D1C14,
  background--darker: #E4DFD3,
  border: #969696,
  border--light: #E5E5E5,
  border--2: #F2EFE7,
  green: #3F6C51,
  green--light: #66CE7E,
  danger: $state-danger-text,
);


// ================
// Breakpoints
// ================

$breakpoints: (
    xsmall: 360px,
    small: 480px,
    medium: 768px,
    large: 992px,
    xlarge: 1160px,
    xxlarge: 1280px,
    xxxlarge: 1440px,
);

/**** Popover ***/
$popover-bg : map-get($colors,background--darker);
$popover-arrow-color : map-get($colors,background--darker);
$popover-fallback-border-color : map-get($colors,background--darker);
$popover-border-color : map-get($colors,background--darker);
$popover-arrow-outer-fallback-color : map-get($colors,background--darker);
$popover-arrow-outer-color : map-get($colors,background--darker);