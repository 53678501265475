.dt-c-QuickAccessLinks {
  display: flex;
  justify-content: space-between;
  flex-direction: column;


  @include mq-from(medium) {
    flex-direction: row;

    ul {
      width: 50%;
    }
  }
}