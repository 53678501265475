/*********** SEARCH CONTAINER **********/
form.search.suggest {
	position:relative;
	border: 1px solid $header-searchsuggest-bdr;

	& > button {
		position: absolute;
		top:50%;
		transform: translateY(-50%);
		right: 0;
		border: 0;
		background: 0;
		width: 40px;
		height: calc(100% + 1px);
		font-weight: bold;
		color:$header-searchsuggest-btn-text;
		background: $header-searchsuggest-btn-bkg;
	}

	& > input {
		width:100%;
		padding:8px 45px 8px 20px;
		background: none;
		border:0;
		color:$header-searchsuggest-text;
		&:focus{
			outline: none;
		}
	}
}

ul.search-suggest-results {
	width:100%;
	border: $border-width-default solid $border-color-light;
	display: none;
	list-style: none;
	padding: 0;
	position: absolute;
	top: calc(100% - 1px);
	z-index: 13000;
	background: $color-inverse;
	margin: 0;

	li {
		width: 100%;
		padding: 0 $space-default;

		&:hover {
			background: $color-01;
			border-bottom: $border-width-default solid $border-color-default;
			color: white;

			button {
				color: $text-color-inverse;
				border-top: $border-width-default solid transparent;
			}

			+ li {
				button {
					border: none;
				}
			}
		}

		&.active-suggestion {
			background: $color-corporate;
			border-bottom: $border-width-default solid $border-color-corporate;
			color: $text-color-inverse;

			button {
				color: $text-color-inverse;
				border-top: $border-width-default solid transparent;
			}

			+ li {
				button {
					border-top: $border-width-default solid transparent;
				}
			}
		}

		&:first-child {

			button {
				border: 0;
			}

		}

		button {
			width: 100%;
			padding: $space-default 0 $space-default 4px;
			border: 0;
			border-top: $border-width-default solid $border-color-light;
			background: none;
			font-size: 13px;
			color: $text-color-quarternary;
			text-align: left;
			text-transform: capitalize;
			font-family: $font-family-regular;

			span {
				font-weight: bold;
			}
		}
	}
}

.header-search-container {

	@media (max-width:$screen-xs-max) {
		border-top: $border-width-default solid lighten($border-color-light, 100%);
		padding: 0;
	}

	input {
		&.form-control {
			border: $border-width-default solid $border-color-default;
			background: $color-primary;
			height: 38px;
			padding-left: (38 / 2);
			float: right;
			font-size: 14px;
			color: lighten($text-color, 100%);

			&::-ms-clear {
				display:none;
			}


			@media (max-width:$screen-xs-max) {
				border: $border-width-default solid $border-color-default;

				&:focus {
					background: $color-tertiary;
				}
			}
		}

		&:focus {
			border: $border-width-default solid $border-color-light;
			background: $color-inverse;
			font-size: 14px;
			color: $text-color-primary;
			box-shadow: none;

			+ button {
				&.btn-search {
					span:before {
						color: $text-color-primary;
					}
				}
			}
		}

		// Placeholder Styles

		&::-webkit-input-placeholder { /* WebKit browsers */
			color: $text-color-quarternary !important;
		}
		&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: $text-color-quarternary !important;
		}
		&::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: $text-color-quarternary !important;
		}
		&:-ms-input-placeholder { /* Internet Explorer 10+ */
			color: $text-color-quarternary !important;
		}
	}

	ul.search-suggest-results {
		width: 92.5%;
	}
}

.main-search-container {

	ul.search-suggest-results {
		width: 100%;
	}
}

.error-search-container {

	ul.search-suggest-results {
		top: 33px;
		width: 100%;
	}
}

form.search.suggest {


	ul.search-suggest-results {

		li.classic {
			padding: 0 $space-default;

			&:hover {
				background: $color-01;
				border-bottom: $border-width-default solid $border-color-default;
				color: $text-color-inverse;
			}
		}
	}
}


form.search.suggest ul.search-suggest-results li {
	white-space: nowrap;
	border: none;
	padding: 0;
}

form.search.suggest ul.search-suggest-results li:hover {
	background-color: $color-01;
}

form.search.suggest ul.search-suggest-results li:hover div {
	background-color: $color-01;
}

form.search.suggest ul.search-suggest-results li div {
	padding: 0 15px;
}

form.search.suggest ul.search-suggest-results li div.border {
	padding: 0;
	margin: 0 15px;
	border-bottom: $border-width-default solid $border-color-light;
}

form.search.suggest ul.search-suggest-results li span.ish-searchTerm {
	font-weight: bold;
}

form.search.suggest ul.search-suggest-results li a span {
	color: $color-primary;
}

form.search.suggest ul.search-suggest-results li:hover a span {
	color: white;
}

form.search.suggest ul.search-suggest-results li:hover span.left img {
	opacity: 0.8;
}

form.search.suggest ul.search-suggest-results li.sectionHeader {
	background-color: $border-color-light;
	font-weight: bold;
	padding: 10px;
	white-space: nowrap;
	border-bottom: none;
}

form.search.suggest ul.search-suggest-results li span.left {
	float: left;
	overflow: hidden;
	text-overflow: ellipsis;
	//width: 250px;
	max-width: 100%;
	line-height: 50px;
}

form.search.suggest ul.search-suggest-results li span.left img {
	height: 50px;
	width: auto;
	padding-right: 5px;
}

form.search.suggest ul.search-suggest-results li span.right {
	float: right;
	line-height: 50px;
}


form.search.suggest ul.search-suggest-results li.textOnly span.left {
	line-height: 30px;
	width: 300px;
	max-width: 100%;
}

form.search.suggest ul.search-suggest-results li.textOnly span.right {
	line-height: 30px;
}

form.search.suggest ul.search-suggest-results.twocolumns {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
	-moz-column-gap: 0;
	-webkit-column-gap: 0;
	column-gap: 0;
	width: 800px;
	left: -115px;
}
