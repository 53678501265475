.cpt_buybox{
  position: relative;
  display: block;
  width: 100%;
  min-height: 350px;
  background: $color-03;
  padding:20px 20px 60px 20px;
  & .qty .ui-spinner{
    max-width : 100%;
  }
  & .actions{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    & > *{
      width: 100%;
    }
  }
}