.checkbox-custom{
  position: absolute !important;
  margin:0px;
  left: -9999px;
}
.checkbox-custom ~ label{
  position: relative;
  padding-left:30px;
  margin:0;
  cursor: pointer;
  display: inline-block;
}
.checkbox-custom ~ label{
  &:before,
  &:after{
    content: '';
    position: absolute;

  }
  &:before{
    left: 0;
    top: 3px;
    width:21px;
    height:21px;
    border: 2px solid map-get($colors,border--light);
    background: map-get($colors,light);
  }
  &:after{
    left: 5px;
    top: 8px;
    width: 11px;
    height: 11px;
    background: map-get($colors,green--light);
    opacity: 0;
  }
}
.checkbox-custom:checked ~ label{
  &:before{
    border: 1px solid map-get($colors,green--light);
  }
  &:after{
    opacity: 1;
  }
}
.checkbox-custom:disabled ~ label{
  cursor: not-allowed;
  &:before{
    border: 1px solid map-get($colors,border--light);
  }
  &:after{
    color:map-get($colors,border--light);
  }
}

.checkbox-switch{
  opacity: 0;
  width: 0;
  height: 0;
  & + label ,
  &:disabled + label{
    position: relative;
    display: inline-block;
    height: 34px;
    line-height: 34px;
    padding-left: 60px;
    margin:0;
    cursor:pointer;
    &:before{
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 60px;
      height: 34px;
      border-radius: 34px;
      background-color: map-get($colors,border--light);
      margin:0;
      transition: all .2s ease-in-out;
    }
    &:after{
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      border-radius: 100%;
      background-color: map-get($colors,light);
      transition: all .2s ease-in-out;
    }
  }
  &:checked + label {
    &:before{
      background-color: map-get($colors,green--light);
    }
    &:after{
      left: 30px;
      background-color: map-get($colors,light);
    }
  }
  &:disabled + label{
    opacity: 0.5;
    cursor: not-allowed;
  }
}