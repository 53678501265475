.cpt_univers{
  position: relative;
  display: inline-block;
  width: 100%;
  background: $univers-bkg;
  border:1px solid  $univers-border;
  color: $univers-color;
  padding-bottom: 60px;
  &:hover{
    color: $univers-color-hover;
  }
  & .title{
    display:block;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 700;
    @include x-rem(font-size,20px);
  }
  & .img{
    display:block;
    margin-bottom: 20px;
    text-align: center;
    & img{
      width: auto;
      margin: auto;
      height: 150px;
    }
  }
  & .desc{
    display:block;
    @include x-rem(font-size,12px);
    text-align: center;
  }
  & .actions{
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    & > *{
      width: 100%;
      margin: 0;
    }
  }

}