.dt-c-FaqList{
    &-item{
        &[data-shown="true"]{
            background: color(light);
        }
        &:last-child{
            border: {
                width: 0 0 1px 0;
                color: color(border--light);
                style: solid;
            }
        }
        &--title{
            @include rem(padding,35px 0px);
            @include rem(font-size,24px);
            position: relative;
            cursor: pointer;
            font: {
                family: font(title);
                weight: fw(bold);
            }
            border: {
                width: 1px 0 0 0;
                color: color(border--light);
                style: solid;
            }
            &:after{
                content: "";
                background: url('../img/icons/arrow-down-small.svg') no-repeat center center/contain;
                @include rem(width,14px);
                @include rem(height,8px);
                position: absolute;
                @include rem(right,0px);
                top: 50%;
                transform: translateY(-50%);
                filter: brightness(0);
            }
            &[data-shown="true"]{
                &:after{
                    background: url('../img/icons/arrow-up-small.svg');
                }
            }
        }
        &--content{
            @include rem(padding,0 0px 35px 0px);
            display: none;
            &[data-shown="true"]{
                display: block;
            }
        }
    }
}