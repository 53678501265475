/* POURQUOI DEVENIR ADHERENT UNEAL ? */
.hero-img {
  @include rem(margin,0 auto 75px auto);
  @include mq-from(medium) {
    @include rem(padding,0 80px);
  }
}
.hero-list-item{
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
  width: 100%;
  @include mq-from(large) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .img-wrapper{
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    @include rem(margin-bottom,60px);
    @include mq-from(medium) {
      @include rem(margin-bottom,80px);
    }
    @include mq-from(large) {
      width: 626px;
      margin-bottom: 0;
    }
    img{
      &:first-child{
        width: 75%;
        @include mq-from(medium) {
          @include rem(width,541px);
        }/*
        @include mq-from(large) {
          @include rem(width,541px);
        }*/
      }
      &:last-child{
        width: 55%;
        @include mq-from(medium) {
          @include rem(margin,-163px auto 0 0);
        }
        @include mq-from(large) {
          @include rem(width,450px);
        }
      }
    }
  }
  .content{
    position: relative;
    @include mq-from(large) {
      @include rem(max-width,386px);
    }
    .h2,
    .desc,
    .actions{
      position: relative;
      z-index: 2;
    }

    .actions { //#85482
      @include mq-to(large) {
        text-align: center;
      }
    }

    .counter{
      position: absolute;
      left: 45px;
      top: -140px;
      z-index: 1;
      @include rem(font-size,320px);
      color: #F9C744;
      font-weight: 800;
    }
    .desc{
      @include rem(margin-bottom,57px);
    }
  }
  &.rtl{
    flex-flow: column wrap;
    @include mq-from(large) {
      flex-flow: row nowrap;
      .content{
        @include rem(margin,136px 0 0 30px);
      }
    }
    .img-wrapper{
      align-items: flex-end;
      img{
        &:last-child{
          @include rem(margin,-80px auto 0 0);
          @include mq-from(large) {
            @include rem(margin,-163px auto 0 0);
          }
        }
      }
    }
  }
  &.ltr {
    flex-flow: column-reverse nowrap;
    @include mq-from(large) {
      flex-flow: row-reverse nowrap;
      .content{
        @include rem(margin,136px 30px 0 0);
      }
    }
    .img-wrapper {
      align-items: flex-start;
      margin-bottom: 0;
      img{
        &:last-child{
          @include rem(margin,-80px 0 0 auto);
          @include mq-from(large) {
            @include rem(margin,-163px 0 0 auto);
          }
        }
      }
    }
    .content{
      @include rem(margin-bottom,60px);
    }
  }
}
