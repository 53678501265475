.dt-c-SearchPanel {
  &-top, &-results {
    z-index: 1;
    position: relative;
  }

  &-top {
    background-color: color(secondary);

    &-bigWrapper {
      position: relative;
    }

    &-wrapper {
      position: relative;
      padding: 0 146px;
    }

    &-logo {
      @include center-abs-y;
      left: calc(((555px / 2) - 108px) - 50px);
      width: 108px;

      svg {
        display: block;
        width: 100%;
      }
    }

    &-form {
      width: 555px;
      max-width: calc(100% - 133px * 2);
      margin: auto;
      padding: 20px 0;
    }

    &-close {
      @include center-abs-y;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      padding: 10px;
      border-radius: 50%;
      background-color: color(background--light);

      span {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        font-size: 0;
        @include rotate(45deg);

        &::before, &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: calc(50% - 1px);
          width: 2px;
          background-color: color(primary);
        }

        &::after {
          @include rotate(90deg);
        }
      }
    }
  }

  &-results {
    padding: 20px 40px;
    max-height: calc(100vh - 59px);
    color: color(primary);
    background-color: color(light);
    overflow: auto;
    overscroll-behavior: contain;

    &-autosuggest {
      display: flex;
      flex-wrap: wrap;
      margin: -20px;

      &:empty {
        display: none;
      }

      > * {
        flex: 1 1 auto;
        padding: 20px;

        &[data-type='infos'] {
          flex-grow: 5;
          flex-basis: 300px;
        }

        &[data-type='categories'], &[data-type='products'] {
          flex-grow: 4;
          flex-basis: 200px;
        }
      }
    }

    &-autosuggest:not(:empty) + &-popular {
      display: none;
    }

    &-popular {
      display: flex;
      justify-content: center;

      > * {
        width: 555px;
        max-width: 100%;
      }
    }

    &-part {
      > strong {
        display: block;
        margin-bottom: 12px;
        @include fs(20px, 24px);
        font-family: font(title);
      }

      li:not(:last-child) {
        margin-bottom: 12px;
      }

      li a {
        display: flex;
        align-items: center;

        .img {
          position: relative;
          flex: 0 0 62px;
          margin-right: 15px;
          background-color: color(background--light);
          overflow: hidden;

          &::before {
            content: '';
            display: block;
            padding-bottom: 83%;
          }

          img {
            @include cover-abs;
          }
        }

        .text {
          flex: 1 1 auto;

          > strong {
            display: block;
            margin-bottom: 6px;
            @include fs(16px, 22px);
            font-family: font(title);
            text-transform: uppercase;
          }

          > span {
            @include fs(16px, 22px);
            font-family: font(text);
          }
        }
      }


      // Variations specific to infos
      &[data-type='infos'] {
        li:not(:last-child) {
          margin-bottom: 27px;
        }

        li a {
          .img {
            flex-basis: 120px;
          }

          .text > span {
            @include fs(14px, 19px);
          }
        }
      }
    }
  }

  &-fade {
    z-index: 0;
    @include cover-abs;
    position: fixed;
    background-color: rgba(color(dark), .2);
  }


  @include mq-to(medium) {
    &-top {
      &-wrapper {
        padding: 0 80px 0 0;
      }
      &-logo {
        display: none;
      }

      &-form {
        max-width: 100%;
        padding: 6px 0 10px;
      }
    }

    &-results {
      padding: 20px;

      &-part {
        &[data-type='infos'] {
          li a {
            .img {
              flex-basis: 80px;
            }
          }
        }
      }
    }
  }
}