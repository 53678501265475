.dt-c-NavigationServices {
  &-item {
    position: relative;
    height: 100%;
    background: {
      color: color(background--dark);
      position: center;
      size: cover;
    };

    // Fade
    &::before {
      content: '';
      z-index: 0;
      @include cover-abs;
      display: block;
      background-color: color(dark);
      opacity: 0.15;
      pointer-events: none;
    }

    a {
      z-index: 1;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 180px;
      max-width: 350px;
      margin: 0 auto;
      padding: 35px 20px;
      text-align: center;
      @include fs(36px, 34px);
      font-family: font(title);
      font-weight: fw(extraBold);
      color: color(light);
      text-transform: uppercase;
    }
  }

  &-cta {
    margin-top: 26px;
    text-align: center;
  }

  @include mq-from(small) {
    padding: 22px 12px 26px;

    &-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 26px;
    }

    &-item {
      background-size: 100%; // To allow animation
      transition: all .2s ease-in-out;

      &:hover, &:focus-within {
        background-size: 102%;
      }
    }
  }

  @include mq-to(small) {
    padding: 0 15px 45px;

    &-list {
      > * {
        margin-bottom: 10px;
      }
    }

    &-item {
      a {
        @include fs(26px, 28px);
      }
    }
  }
}