.dt-c-AccountDashboardTopActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .dt-c-Choice-toggler {
    flex: 0 0 43px;
  }

  .custom-select {
    flex: 1 1 auto;

    &:before {
      content: "";
      background: url('../img/icons/exploitation.svg') center center/contain no-repeat;
      display: block;
      position: absolute;
      @include rem(left, 14px);
      top: 50%;
      transform: translateY(-50%);
      @include rem(width, 17px);
      @include rem(height, 16px);
    }

    select {
      @include rem(padding-left, 41px);
    }
  }
}