.spacing-actions{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > :not([hidden])~:not([hidden]) {
    margin-top: calc(10px * calc(1 - 0));
    margin-bottom: calc(10px * 0);
  }

  @media (min-width:$screen-sm-min) {
    flex-direction: row;
    & > :not([hidden])~:not([hidden]) {
      margin-right: calc(10px * 0);
      margin-left: calc(10px * calc(1 - 0));
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}