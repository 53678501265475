.landingpagefreestyle{


  & h1{
    @include x-rem(font-size,$landingpagefreestyle-h1-size);
    color: $landingpagefreestyle-h1-color;
    text-transform: uppercase;
    font-weight: 700;
  }
  & h2{
    @include x-rem(font-size,$landingpagefreestyle-h2-size);
    color: $landingpagefreestyle-h2-color;
    text-transform: uppercase;
    font-weight: 700;
  }
  & h3{
    @include x-rem(font-size,$landingpagefreestyle-h3-size);
    color: $landingpagefreestyle-h3-color;
    text-transform: uppercase;
    font-weight: 700;
  }
  & h4{
    @include x-rem(font-size,$landingpagefreestyle-h4-size);
    color: $landingpagefreestyle-h4-color;
    text-transform: uppercase;
    font-weight: 700;
  }
  & h5{
    @include x-rem(font-size,$landingpagefreestyle-h5-size);
    color: $landingpagefreestyle-h5-color;
    text-transform: uppercase;
    font-weight: 700;
  }
  & h6{
    @include x-rem(font-size,$landingpagefreestyle-h6-size);
    color: $landingpagefreestyle-h6-color;
    text-transform: uppercase;
    font-weight: 700;
  }
  & ul{
    & li{
      position: relative;
      display:block;
      padding:0 0 5px 20px;
      &:before{
        content:"";
        position: absolute;
        top:3px;
        left:0;
        width:10px;
        height:10px;
        background:$landingpagefreestyle-liste-puce-bkg;
        border-radius: 100%;
      }
    }
  }

  & table{
    width:100% !important;
    & tr{
      & td{
        padding:10px;
      }
      &:nth-child(even) td{
        background:$landingpagefreestyle-table-even-bkg;
      }
      &:nth-child(odd) td{
        background:$landingpagefreestyle-table-odd-bkg;
      }
      &:first-child{
        & td{
          background:$landingpagefreestyle-table-firstchild-bkg;
          color:$landingpagefreestyle-table-firstchild-color;
        }
      }
    }

  }
}