.dt-c-ContactBox {
    padding: 30px;
    background-color: color(secondary--faden);
    border-radius: 5px;

    &-title {
        display: block;
        margin-bottom: 20px;
        text-align: center;
        font-size: 25px;
        color: color(green);
    }

    &-content {
        display: flex;
        align-items: center;

        > * {
            position: relative;
            flex: 0 0 50%;
            height: 100%;
            font-size: 16px;
            text-align: center;
            
            // separator
            &:not(:first-child) {
                border-left: 1px solid rgba(#000000, 0.3);

                &::before {
                    content: 'OU';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    @include translate(-50%, -50%);
                    display: block;
                    padding: 4px 0;
                    font-size: 16px;
                    line-height: #{23/16};
                    color: color(dark);
                    background-color: color(secondary--faden);
                }
            }

            strong {
                display: block;
                font-size: 21px;
                line-height: #{26/21};
                color: color(green);

                &:not(:first-child) {
                    margin-top: 6px;
                }
            }

            .btn {
                &:not(:first-child) {
                    margin-top: 15px;
                }
            }
        }
    }

    
    @include mq-to(medium) {
        &-content {
            display: block;

            // separator
            > *:not(:first-child) {
                margin-top: 30px;
                padding-top: 30px;
                border-top: 1px solid rgba(#000000, 0.3);
                border-left: none;

                &::before {
                    top: 0;
                    left: 50%;
                    padding: 0 4px;
                }
            }
        }
    }
}