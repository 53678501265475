// .nq-.. given for retro-compatibility

.nq-c-HomeTextImage,
.dt-c-HomeTextImage {
  position: relative;
  margin: 80px 0;
  overflow: hidden;

  $pOld: unquote('.nq-c-HomeTextImage');
  $p: unquote('.dt-c-HomeTextImage');

  &-image {
    position: relative;

    img {
      @include cover-abs;
    }
  }

  &-content {
    padding: 34px 0 30px;

    #{$pOld}:not([data-theme='dark']) & .h1,
    #{$p}:not([data-theme='dark']) & .h1 {
      color: color(primary);
    }

    > *:not(:last-child) {
      margin-bottom: 15px;
    }

    p:not(:last-child) {
      margin-bottom: 1em;
    }
  }


  // Dark mode
  &[data-theme='dark'] {
    color: color(light);
    background-color: color(background--dark);
  }


  @include mq-from(large) {
    &-wrapper {
      display: flex;
      align-items: center;
      min-height: 385px;

      > * {
        flex: 1 1 50%;
      }
    }

    &-image {
      align-self: stretch;
    }

    // Centered display
    &:not([data-mode='full']) {
      #{$pOld}-image,
      #{$p}-image {
        margin-left: -82px;

        &::after {
          content: '';
          display: block;
          padding-bottom: 60%;
        }
      }

      #{$pOld}-content,
      #{$p}-content {
        margin-left: 30px;
      }
    }

    // Full width display
    &[data-mode='full'] {
      #{$pOld}-image,
      #{$p}-image {
        flex-basis: 54%;

        img {
          right: 0;
          left: auto;
          width: 51vw;
        }
      }

      #{$pOld}-content,
      #{$p}-content {
        padding-left: 58px;
      }
    }
  }


  @include mq-to(large) {
    &-image {
      height: 200px;
      min-height: 44vw;
    }


    // Full width display
    &[data-mode='full'] {
      img {
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}