
//
// PRODUCT IMAGES

.product-detail-img {
	.carousel {
		float: left;
		max-width: 500px;
		width: 100%;
		
		@media (max-width:$screen-xs-max) {
			width: 100%;
		}
	}
}

.product-img-main {
	width: 95%;
	float: left;
	
	@media (max-width:$screen-xs-max) {
		margin-bottom: ($space-default * 2);
		width: 100%;
	}
	
	@media (min-width:$screen-sm-min) and (max-width:$screen-sm-max) {
		margin-bottom: ($space-default * 2);
	}
	
	img {
		display: inline;
		float: left;
		max-width: 100%;
		
		&:first-child {
			margin-right: 2%;
		}
	}
}

.product-img-inner {
	.item {
		> img {
			display: inline;
			float: left;
			
			@media (max-width:$screen-xs-max) {
				display: inline;
			}
			
			@media (max-width: $screen-xs) {
				display: inline;
				max-width: 100%;
			}
			
			&:first-child {
				margin-right: 2%;
				
				@media (max-width:$screen-xs-max) {
					margin-right: 0px;
				}
			}
		}
	}
}

.product-thumb-set {
	opacity: 0.3;
	cursor: pointer;
	border: 1px solid $border-color-lighter;
	margin-bottom: $space-default;
	padding: $space-default /3;
	float: left;
	
	img {
		width: 100%;
	}
	
	&.active {
		opacity: 1.0;
	}
}

.product-img-thumbs {
	width: 90px;
}