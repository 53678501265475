// =====================================================================
// REFONTE 2021
// =====================================================================

// ================
// Abstract
// ================

@import 'abstracts/variables';
@import 'abstracts/mixins/_media';
@import 'abstracts/mixins/_center';
@import 'abstracts/mixins/_a11y';
@import 'abstracts/mixins/_rem';
@import 'abstracts/mixins/_resetSwiperIcons';
@import 'abstracts/_functions';

// ================
// base styles
// ================

@import 'base/_fonts';
@import 'base/_generic';
@import 'base/_breadcrumbs';
@import 'base/form';

// ================
// elements styles
// ================
@import 'elements/_table';


// ================
// Utils
// ================

@import 'utils/_hspace';
@import 'utils/_wrapper';
@import 'utils/_a11yhidden';
@import 'utils/_fit';
@import 'utils/_colors';
@import 'utils/_spacing';
@import 'utils/_typography';
@import 'utils/_grid';
@import 'utils/_responsive';
@import 'utils/_sizing';
@import 'utils/_containerCancel';

// ================
// Components
// ================

/** old styles **/
@import "components/productdetail/buybox";
@import "components/productdetail/gallery";
@import "components/category/category-page";
@import "components/category/filter-panel";
@import "components/offer";
@import "components/univers";
@import "components/rassurance";
@import "components/product/productgrid";
@import "components/product-availability";

@import 'components/common/_Btn';
@import 'components/common/_Layer';
@import 'components/common/_BannerFull';
@import 'components/common/_Wysiwyg';
@import 'components/common/_TemplatePages';
@import 'components/common/_Dropdown';
@import 'components/common/_TextImage';
@import 'components/common/_Pagination';
@import 'components/common/_Tags';
@import 'components/common/_ImageFullWidth';
@import 'components/common/_Video';
@import 'components/common/_Promotion';
@import 'components/common/_Breadcrumbs';
@import 'components/common/_Choice';
@import "components/common/_Collection";
@import 'components/common/_Paragraph';
@import 'components/common/_SocialShare';
@import 'components/common/_SimpleHeader';
@import 'components/common/_BtnList';
@import 'components/common/_QuickAccessLinks';
@import 'components/common/_Tooltip';
@import 'components/common/forms/_Checkbox';
@import 'components/common/forms/_Radio';
@import 'components/common/_Tabs';
@import 'components/common/_Datepicker';
@import 'components/common/_ProfileCard';
@import 'components/common/_ContactBox';
@import 'components/common/_Push';

@import 'components/header/_Header';
@import 'components/header/_HeaderShortcuts';
@import 'components/header/_HeaderContactInfos';
@import 'components/header/_Navigation';
@import 'components/header/_NavigationProducts';
@import 'components/header/_NavigationPush';
@import 'components/header/_NavigationServices';
@import 'components/header/_SearchPanel';

@import 'components/footer/_Footer';

@import 'components/home/_HomeLogin';
@import 'components/home/_HomeTextImage';
@import 'components/home/_HomeStats';
@import 'components/home/_HomeAdherent';
@import 'components/home/_GroupIntroduction';
@import 'components/home/_HomeSliderProduct';

@import 'components/page/_SelectionPage';
@import 'components/page/_ActuEvents';
@import 'components/page/_HeaderActuTech';
@import 'components/page/_RestrictedMessage';
@import 'components/page/_OADServices';
@import 'components/page/_AuthorCard';
@import 'components/page/_ArticleMostRead';
@import 'components/page/_DatesPlaces';

@import 'components/account/_AccountDashboard';
@import 'components/account/_AccountNavigation';
@import 'components/account/_AccountCollecte';
@import 'components/account/_AccountDashboardTopActions';
@import 'components/account/_AccountTransfer';
@import 'components/account/_AccountUpfront';

@import "components/category/_CategoryHeading";

@import 'components/faq/_Faq';

@import "components/search/_SearchBox";
@import "components/search/_SearchResultsToolbar";

// ================
// vendors
// ================
@import 'vendor/jquery-ui/spinner';
@import 'vendor/swiper/swiper';
@import 'vendor/daterangepicker/daterangepicker';
@import 'vendor/simple-scrollbar/simple-scrollbar';

//@import "layout/footer/links";
