// .nq-.. given for retro-compatibility

.nq-c-GroupIntroduction,
.dt-c-GroupIntroduction {
  margin: 80px 0;

  &-wrapper {
    width: 1046px;
  }

  &-image {
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-bottom: 67%;
    }

    img {
      @include cover-abs;
    }
  }

  &-content {
    &-logo {
      margin-bottom: 32px;

      img {
        display: inline-block;
        width: auto!important;
        max-width: 100%;
      }
    }

    &-ctas {
      margin: 50px 0 -10px;
      line-height: 1.2;
      word-spacing: 24px;

      a {
        margin-bottom: 10px;
        word-spacing: normal;
      }
    }
  }


  @include mq-from(large) {
    &-wrapper {
      display: flex;
    }

    &-image {
      flex: 1 1 auto;
    }

    &-content {
      flex: 0 0 49.4%;
      padding-left: 30px;
    }
  }


  @include mq-to(large) {
    text-align: center;

    $v-gap: 25px;

    &-image {
      margin-bottom: $v-gap;
      height: 200px;
      min-height: 44vw;
    }

    &-content {
      &-logo {
        margin-bottom: $v-gap;
      }

      &-ctas {
        margin-top: $v-gap;
      }
    }
  }
}