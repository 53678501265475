/* COMPOSANT "Découvrez Unéal en vidéo" */

.cpt_video {
    .video-container {
        max-width: calc(100% - 30px);
        @include rem(margin-top, 60px);
        margin-bottom: 0;
        @include rem(margin-right, 30px); // preserve space of yellow rectangle
        video {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .video-text {
            z-index: 3;
        }
    }

    .video-wrapper {
        &.no-gutters{
            padding: 0;
            height: auto;
        }
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            @include rem(left, 30px);
            @include rem(top, -30px);
            z-index: 0;
            border: 14px solid color(secondary);
        }
    }

    .content {
        width: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: flex-start;
    }


    @include mq-from(medium) {
        display: flex;

        .video-container {
            flex: 0 1 636px;
        }

        .content {
            flex: 1 1 calc(100% - 646px); // 646px = flex-basis of video container
        }

        /** Position variation */
        &[data-position="Left"] {
            .content {
                @include rem(margin-left, 60px);
            }
        }
        &[data-position="Right"] {
            flex-direction: row-reverse;

            .content {
                @include rem(margin-right, 60px);
            }
        }
    }

    @include mq-from-to(medium, large) {
        .video-container {
            max-width: 60%;
        }
    }

    @include mq-to(medium) {
        .content {
            @include rem(padding-top, 20px);
        }
    }
}

.video-link{
    .glyphicon.glyphicon-play-circle{
        &::before{
            content: "";
            display: block;
            background: {
                image: url('../img/icons/play.svg');
                size: contain;
                repeat: no-repeat;
                position: center center;
            };
            filter: invert(84%) sepia(68%) saturate(1133%) hue-rotate(321deg) brightness(101%) contrast(96%);
            @include rem(width,70px);
            @include rem(height,70px);
        }
    }
}

.video-full-container {
    background:#F2EFE7;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
}