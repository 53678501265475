/**
 * Arrows
 */
$prev: unquote('.swiper-button-prev, .swiper-container-rtl .swiper-button-next');
$next: unquote('.swiper-button-next, .swiper-container-rtl .swiper-button-prev');

#{$prev}, #{$next} {
    transform: translate(0, -50%);
}
#{$prev} {
    left: 0;
}
#{$next} {
    right: 0;

}

/*@media screen and (min-width: calc(1400px + 35px * 2)) {
    #{$prev}, #{$next} {
        left: 50%;
    }
    #{$prev} {
        transform: translate(calc(100% - 1400px / 2 + 12px), -50%);
    }

    #{$next} {
        transform: translate(calc(-100% + 1400px / 2 + 12px), -50%);
    }
}*/

/*
@include mq-to(large) {
    #{$prev}, #{$next} {
        display: none;
    }
}
*/


/*
.swiper-inner.swiper-arrow-sides .swiper-button-prev {
    @media screen and (min-width: calc(1400px + 35px * 2)) {
        left: auto;
        right: 50%;
    }
}
*/




/**
 * Pagination
 */
.swiper-pagination {
    display: flex;
    width: calc(100% - 100px);
    justify-content: center;
    align-items: center;
    @include fs(16px, 18px);
    font-family: font(title);
    font-weight: fw(semiBold);
    color: color(secondary);

    &.fixed {
        @include center-abs-x;
        bottom: 37px;
        color: color(light);
    }

    &-bullets {
        font-size: 0;
        line-height: 0;
    }

    &-bullet {
        @include rem(height, 12px);
        @include rem(width, 12px);
        background: transparent;
        border: 2px solid color(secondary);
        @include rem(margin-right, 9px);
        @include rem(border-radius, 9px);
        transition: width .2s ease-in-out;
        opacity: 1;

        &:last-child {
            margin-right: 0;
        }

        &-active {
            @include rem(width, 27px);
            background: color(secondary);
            color: color(secondary);
        }
    }


    @include mq-to(small) {
        &.fixed {
            bottom: 15px;
        }

        &-bullet {
            @include rem(height, 9px);
            @include rem(width, 9px);

            &-active {
                @include rem(width, 22px);
            }
        }
    }
}
