.cpt_firstvisit{
  position: relative;
  padding:60px 0;
  &:before{
    content:"";
    position:absolute;
    top:0;
    left:0;
    width:50%;
    height:100%;
    background-color:$color-01 !important;
  }
  & .inner{
    display: flex;
    margin:0 -15px;
    & .cell-40{
      flex: 0 0 40%;
      padding:0 15px;
    }
    & .cell-20{
      flex: 0 0 20%;
      padding:0 15px;
    }
  }
  & .desc{
    color:white;
    @include x-rem(font-size,16px);
    & > .title{
      display:block;
      @include x-rem(font-size,36px);
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    & > .subtitle{
      display:block;
      font-weight: 700;
    }
    & .para{
      & a{
        color:white;
        text-decoration: underline;
      }
    }
    & > .actions{
      display:block;
      margin-top: 30px;
    }
  }

  & .blc{
    border: 1px solid $color-01;
    @include x-rem(font-size,16px);
    background: white;
    height:100%;
    & > .img{
      position: relative;
      width: 100%;
      height:200px;
      overflow: hidden;
      & img{
        position: absolute;
        top: 0;
        left:50%;
        transform: translateX(-50%);
        object-fit: cover;
        min-width: 100%;
        width: 100%;
        height: auto;
      }
    }
    & > .title{
      position: relative;
      color:$color-01;
      @include x-rem(font-size,16px);
      padding:20px;
    }
    & .number{
      position: absolute;
      top:-20px;
      left:20px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: white;
      color:$color-01;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
    }
  }

}

@media (max-width:$screen-xs-max) {
  .cpt_firstvisit {
    &:before{
      width:100%;
    }
    & .inner {
      display: block;
    }
    & .blc{
      margin-bottom: 20px;
    }
  }
}