// .nq-.. given for retro-compatibility

.nq-u-wrapper,
.dt-u-wrapper {
  margin-right: auto;
  margin-left: auto;
  width: 1110px;
  max-width: 100%;

  &[data-size='tiny'] {
    width: 676px;
  }

  &[data-size='large'] {
    width: 1275px;
  }

  &[data-size='xlarge'] {
    width: 1400px;
  }

  &[data-size='fullWidth'] {
    width: 100%;
  }
}