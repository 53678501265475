.dt-u-Table{
    width:100%;
    th,
    td{
        @include rem(font-size,13px);
    }
    & > thead{
        tr{
            &.text-left th{text-align: left;}
        }
    }
    & > tbody{
        tr{
            td{
                @include rem(padding,15px 10px);
                @include mq-to(medium){
                    padding: 0;
                }
                text-align: left;
                a{
                    text-decoration: underline;
                }
            }
            &:last-child{
                td{
                    border-bottom: none;
                }
            }
            &.vertical-top{vertical-align: top}
            &.no-border td{border-bottom:0;}
            & > td{
                @include mq-from(medium){
                    border-bottom: 1px solid color(border--2);
                }
            }
        }
    }
    &.primary{
        & > thead{
            tr{
                th{
                    @include rem(padding,9px);
                    font-weight: fw(semiBold);
                    text-align: center;
                    border-bottom: 1px solid color(secondary);
                }
                &.text-left th{text-align: left;}
            }
        }
        & > tbody{
	        tr{
	            td{
	            	text-align: left;
	            }
	        }
        }
        &.text-left {
            & > thead > tr > th,
            & > tbody > tr > td{
                text-align: left;
            }
        }
    }
    &.secondary{
        & > thead{
            tr{
                th{
                    @include rem(padding,9px);
                    text-align: center;
                    background-color:color(background--dark);
                    color:color(light);
                    font-weight: fw(regular);
                }
                &.text-left th{text-align: left;}
                td{
                    background-color: color(light) !important;
                }
            }
        }
    }
    &.tertiary{
        @include mq-from(medium){
            & > tbody{
                &:nth-child(even){
                    & > tr:not(.hidden){
                        & > td{
                            background: color(border--2);
                        }
                    }
                }
                &:nth-child(odd){
                    & > tr:not(.hidden){
                        & > td{
                            background: color(light);
                        }
                    }
                }
            }
        }
    }
    
    @include mq-from(medium){
        &.wide {
            th, td {
                &:first-child {
                    padding-left: 60px;
                    padding-left: clamp(20px, 4vw, 60px);
                }
                
                &:last-child {
                    padding-right: 60px;
                    padding-right: clamp(20px, 4vw, 60px);
                }
            }
        }
    }

    @include mq-to(medium){
        thead{
            display: none;
        }
        &,
        tbody{
            &,
            tr{
                &,
                td{
                    display: block;
                }
            }
            td{
                padding: 0;
                &[data-th]{
                    &:before{
                        content: attr(data-th) " : ";
                        font-weight: fw(bold);
                        color:color(dark);
                        @include rem(font-size,13px);
                    }
                }
            }
        }
    }
}

.table{
    display: table;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
    
    tbody{
        display: table-row-group;
        vertical-align: middle;
        border-color: inherit;
    }
    tr {
        display: table-row;
        vertical-align: inherit;
        border-color: inherit;
    }
    td {
        display: table-cell;
        vertical-align: inherit;
    }
}