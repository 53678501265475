
//
// LABELS

 .label {
	border-radius: 22px;
	font-size: 100%;
	
	&.label-info {		
		background-color: lighten($brand-info, 10%);
	}	
}


