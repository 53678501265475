.cpt_offer{
  width: 100%;
  & .img{
    position: relative;
    display:block;
    width:100%;
    height: 300px;
    overflow: hidden;
    z-index: -1;
    & img{
      position: absolute;
      top: 50%;
      left:50%;
      transform: translate(-50%,-50%);
      object-fit: cover;
      min-width: 100%;
      width: auto;
      height: auto;
    }
  }
  & .inner{
    width:80%;
    margin-left: 10%;
    background: $offer-bkg;
    color: $offer-color;
    text-align: center;
    padding:20px;
    & .title{
      display:block;
      margin-bottom: 20px;
      text-align: center;
      @include x-rem(font-size,16px);
      font-weight: 700;
      text-transform: uppercase;
    }
    & .desc{
      display:block;
      margin-bottom: 20px;
      @include x-rem(font-size,24px);
    }
    & .actions{
      display:block;
    }
  }


}