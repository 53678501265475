.dt-c-HeaderActuTech{
  padding: {
    left: 20px;
    right: 20px;
  };
  @include mq-from(medium){
    padding: {
      left: 0;
      right: 0;
    };
  }
  &-item{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    @include mq-from(medium){
      flex-direction: row;
      @include rem(margin-bottom, 30px);
    }
  }
  &-img {
    @include rem(height,278px);
    width: 100%;
    display: block;
    @include rem(margin-bottom, 15px);
    @include mq-from(medium){
      max-width: 50%;
      flex-shrink: 0;
      margin-bottom: 0;
      @include rem(margin-right, 30px);
    }
    @include mq-from(large){
      @include rem(max-width,445px);
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-content{
    @include mq-to(medium){
      @include rem(margin-bottom, 30px);
    }
  }
  &-category{
    @include rem(font-size, 12px);
    @include rem(margin-bottom, 5px);
    text: {
      transform: uppercase;
    }
  }
  &-title{
    @include rem(margin-bottom, 15px);
  }
  &-desc{
    @include rem(font-size, 16px);
    @include rem(margin-bottom, 10px);
    color: color(black);
  }
  &-infos{
    &--author{
      color: #888888;
      @include rem(font-size, 12px);
      @include rem(margin-bottom, 5px);
      span{
        font-weight: fw(bold);
        @include rem(font-size, 14px);
        color: color(black);
      }
    }
    &--stats{
      display: flex;
    }
    &--date,
    &--reading-time{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include rem(margin-right, 13px);
      .icon{
        display: inline-block;
        @include rem(margin-right, 5px);
        @include rem(font-size, 15px);
      }
      .value{
        @include rem(font-size, 12px);
      }
    }
  }
}
