.footer-newsletter {
  position: relative;
  padding: 50px 0;
  background: $footer-newsletter-bkg;
  color:white;
  & .img{
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    background: url(../img/background/newsletter_bkg.jpg) center no-repeat;
    background-size: cover;
    opacity: 0.5;
  }
  & .title{
    display: block;
    margin-bottom: 20px;
    @include x-rem(font-size,24px);
    color:white;
    text-transform: uppercase;
  }
  & .form-newsletter{
    & input{
      width: 100%;
      padding:10px;
      color:$color-07;
    }
    & .infos{
      display: block;
      margin-top: 5px;
      @include x-rem(font-size,12px);
      & a{
        color:white;
        text-decoration: underline;
      }
    }
    & button{
      margin-top: 20px;
    }
  }

}