.dt-c-Transfer{
    &-amount{
        font-weight: fw(bold);
        @include rem(font-size,20px);
        @include rem(line-height,16px);
        &.green{
            color:color(green--light);
        }
    }
    .form-group{
        position: relative;
        input{
            @include rem(padding-right,45px);
            & + .currency{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                @include rem(right,10px);
            }
        }
    }
    .dt-c-Datepicker{ 
        input{
            width: auto;
        }
    }
}