.dt-c-BannerFull {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color(background--dark);
  overflow: hidden;

  $p: &;

  &-bg {
    z-index: 0;
    @include cover-abs;
  }

  &-wrapper {
    z-index: 1;
    position: relative;
  }

  &-title {
    display: block;
    margin-bottom: 6px;
  }

  &-description {
    @include fs(16px, 20px);
  }


  /**
   * States
   */

  // Short
  &[data-hasimage='false'] {
    min-height: 153px;

    #{$p}-wrapper {
      padding: 30px 300px 30px 0;
    }

    #{$p}-title {
      @include fs(24px, 26px);
    }

    #{$p}-description {
      @include fs(14px, 18px);
      color: color(light);
    }

    #{$p}-cta {
      @include center-abs-y;
      right: 0;
    }
  }

  // Immersive
  &[data-hasimage='true'] {
    height: calc(100vh - 400px);
    min-height: 488px;
    max-height: 600px;

    #{$p}-wrapper {
      padding: 73px 0;
      margin-top: 110px; // To don't center including floating header
    }

    #{$p}-title {
      max-width: 720px;
    }

    #{$p}-description {
      max-width: 537px;
      font-weight: fw(semiBold);
      color: color(dark);
    }

    #{$p}-cta {
      margin-top: 18px;
    }
  }


  @include mq-from(large) {
    padding: 0 100px;

    // Immersive
    &[data-hasimage='true'] {
      padding: 0 177px;
    }
  }

  @include mq-to(large) {
    // Immersive
    &[data-hasimage='true'] {
      height: auto;
      min-height: 429px;

      #{$p}-wrapper {
        margin-top: 0;
        margin-bottom: 24px; // Don't include dots in v centering
      }
    }
  }

  @include mq-to(medium) {
    // Short
    &[data-hasimage='false'] {
      #{$p}-wrapper {
        padding: 30px 0;
      }

      #{$p}-cta {
        position: static;
        margin-top: 18px;
        transform: none;
      }
    }
  }
}
