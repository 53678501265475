.dt-c-Upfront{
    background: color(light);
    @include rem(border-radius,16.5px);
    @include rem(padding,5px 20px);
    display: flex;
    justify-content: center;
    align-items: center;

    &-value{
        @include rem(line-height,16px);
    }
}