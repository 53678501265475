.cursor{cursor:pointer;}
.img-responsive {width: 100%;height:auto;}

/* Utilities : position */
.p-abs{position:absolute;}
.p-rlv{position:relative;}

/*Text*/
.txt-undeline{
  text-decoration: underline !important;
}

/* Utilities : z-index */
@for $i from 1 through 20 {
  .zindex-#{(100 *$i)}{
    z-index:(10px *$i) !important;
  }
}
/* Utilities : font */
.font-normal{font-weight: normal !important;}
@for $i from 1 through 40 {
  .fs-#{(1 *$i)}{
    font-size:(1px *$i) !important;
  }
}
@media (max-width: $screen-xs-max) {
  @for $i from 1 through 40 {
    .fs-xs-#{(1 *$i)}{
      font-size:(1px *$i) !important;
    }
  }
}

/* Utilities : margin / padding */
.marg-auto{margin:auto;}
@for $i from 0 through 40 {
  .marg-#{(5 *$i)}{
    margin:(5px *$i) !important;
  }
  .marg-t-#{(5 *$i)}{
    margin-top:(5px *$i) !important;
  }
  .marg-l-#{(5 *$i)}{
    margin-left:(5px *$i) !important;
  }
  .marg-b-#{(5 *$i)}{
    margin-bottom:(5px *$i) !important;
  }
  .marg-r-#{(5 *$i)}{
    margin-right:(5px *$i) !important;
  }
  .marg-n-#{(5 *$i)}{
    margin:-(5px *$i) !important;
  }
  .marg-t-n-#{(5 *$i)}{
    margin-top:-(5px *$i) !important;
  }
  .marg-l-n-#{(5 *$i)}{
    margin-left:-(5px *$i) !important;
  }
  .marg-b-n-#{(5 *$i)}{
    margin-bottom:-(5px *$i) !important;
  }
  .marg-r-n-#{(5 *$i)}{
    margin-right:-(5px *$i) !important;
  }
  .pad-#{(5 *$i)}{
    padding:(5px *$i) !important;
  }
  .pad-t-#{(5 *$i)}{
    padding-top:(5px *$i) !important;
  }
  .pad-l-#{(5 *$i)}{
    padding-left:(5px *$i) !important;
  }
  .pad-b-#{(5 *$i)}{
    padding-bottom:(5px *$i) !important;
  }
  .pad-r-#{(5 *$i)}{
    padding-right:(5px *$i) !important;
  }
}
@media (max-width: $screen-sm-max) {
  @for $i from 0 through 40 {
    .marg-sm-#{(5 *$i)}{
      margin:(5px *$i) !important;
    }
    .marg-sm-t-#{(5 *$i)}{
      margin-top:(5px *$i) !important;
    }
    .marg-sm-l-#{(5 *$i)}{
      margin-left:(5px *$i) !important;
    }
    .marg-sm-b-#{(5 *$i)}{
      margin-bottom:(5px *$i) !important;
    }
    .marg-sm-r-#{(5 *$i)}{
      margin-right:(5px *$i) !important;
    }
    .marg-sm-n-#{(5 *$i)}{
      margin:-(5px *$i) !important;
    }
    .marg-sm-t-n-#{(5 *$i)}{
      margin-top:-(5px *$i) !important;
    }
    .marg-sm-l-n-#{(5 *$i)}{
      margin-left:-(5px *$i) !important;
    }
    .marg-sm-b-n-#{(5 *$i)}{
      margin-bottom:-(5px *$i) !important;
    }
    .marg-sm-r-n-#{(5 *$i)}{
      margin-right:-(5px *$i) !important;
    }
    .pad-sm-#{(5 *$i)}{
      padding:(5px *$i) !important;
    }
    .pad-sm-t-#{(5 *$i)}{
      padding-top:(5px *$i) !important;
    }
    .pad-sm-l-#{(5 *$i)}{
      padding-left:(5px *$i) !important;
    }
    .pad-sm-b-#{(5 *$i)}{
      padding-bottom:(5px *$i) !important;
    }
    .pad-sm-r-#{(5 *$i)}{
      padding-right:(5px *$i) !important;
    }
  }
}
@media (max-width: $screen-xs-max) {
  @for $i from 0 through 40 {
    .marg-xs-#{(5 *$i)}{
      margin:(5px *$i) !important;
    }
    .marg-xs-t-#{(5 *$i)}{
      margin-top:(5px *$i) !important;
    }
    .marg-xs-l-#{(5 *$i)}{
      margin-left:(5px *$i) !important;
    }
    .marg-xs-b-#{(5 *$i)}{
      margin-bottom:(5px *$i) !important;
    }
    .marg-xs-r-#{(5 *$i)}{
      margin-right:(5px *$i) !important;
    }
    .marg-xs-n-#{(5 *$i)}{
      margin:-(5px *$i) !important;
    }
    .marg-xs-t-n-#{(5 *$i)}{
      margin-top:-(5px *$i) !important;
    }
    .marg-xs-l-n-#{(5 *$i)}{
      margin-left:-(5px *$i) !important;
    }
    .marg-xs-b-n-#{(5 *$i)}{
      margin-bottom:-(5px *$i) !important;
    }
    .marg-xs-r-n-#{(5 *$i)}{
      margin-right:-(5px *$i) !important;
    }
    .pad-xs-#{(5 *$i)}{
      padding:(5px *$i) !important;
    }
    .pad-xs-t-#{(5 *$i)}{
      padding-top:(5px *$i) !important;
    }
    .pad-xs-l-#{(5 *$i)}{
      padding-left:(5px *$i) !important;
    }
    .pad-xs-b-#{(5 *$i)}{
      padding-bottom:(5px *$i) !important;
    }
    .pad-xs-r-#{(5 *$i)}{
      padding-right:(5px *$i) !important;
    }
  }
}

/* Utilities : width / height */
.w-full{width:100%;}
@media (max-width: $screen-xs-max) {
  .w-xs-full{width:100%;}
}
.h-full{height:100%;}
@media (max-width: $screen-xs-max) {
  .h-xs-full{width:100%;}
}

.list-puce{
  & > li{
    position: relative;
    padding:5px 0 5px 15px;
    &:before{
      content:"";
      position: absolute;
      top: 50%;
      left:0;
      transform: translateY(-50%);
      width:10px;
      height:10px;
      background: $color-01;
      border-radius: 100%;
    }
  }
}

.bkg-full-01{background: $color-01;}
.bkg-full-02{background: $color-02;}
.bkg-full-03{background: $color-03;}
.cl-01{color: $color-01 !important;}
.cl-02{color: $color-02 !important;}
.cl-03{color: $color-03 !important;}
.cl-04{color: $color-04 !important;}
