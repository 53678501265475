.footer-rassurance{
  .row{
    @extend .dt-u-wrapper;
  }
}

.cpt_rassurance{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & .img{
    color: color(black);
    min-width: 70px; // #85484
  }
  & .title{
    color: color(dark);
  }
  & .desc{
    color:color(dark);
  }
}
