.filter-toolbar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include rem(font-size,14px);
  @include rem(padding-bottom,31px);
  @include rem(margin-bottom,30px);
  border-bottom: 1px solid color(border);
  @include mq-to(medium) {
    align-items: flex-start;
    flex-wrap: wrap;
    @include rem(padding-bottom,10px);
  }
  .input-wrapper{
    width: calc(50% - 15px);
    @include mq-to(medium) {
      @include rem(margin-bottom,15px);
    }
    @include mq-from(medium) {
      width: 100%;
    }
    &.filter-by{
      @include mq-from(medium) {
        @include rem(max-width,350px);
      }
    }
    &.sort-by{
      border-left: 1px solid color(border);
      padding-left: 20px;
      @include mq-from(medium) {
        @include rem(max-width,222px);
      }
    }
  }
  .filter-by{
    @include mq-to(medium){
      width: 50%;
    }
    a{
      @include mq-to(medium){
        display: block;
      }
    }
  }
  .result-count{
    @include rem(margin-bottom,15px);
    @include mq-from(medium) {
      @include rem(margin, 31px auto auto 30px);
    }
  }
  .dt-c-SelectDropdown-toggle{
    @include mq-to(medium) {
      display: none;
    }
  }
}

.dt-c-Layer[data-mode="filter"]{
  .dt-c-Layer-content{
    @include rem(padding,34px 20px);
  }
}