.cpt_canread{
  position: relative;
  display: block;
  width: 100%;
  background:$color-03;
  border-left:10px solid $color-01;
  padding:20px;
  & .title{
    display: block;
    @include x-rem(font-size,20px);
    display: block;
    margin-bottom: 20px;
    text-align: left;
    font-weight: 700;
    text-transform: uppercase;
  }
  & .description{
    display: block;
    margin-bottom: 20px;
  }
  & .actions{
    display: block;

  }
}