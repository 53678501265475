.tablevariation{
  width: 100%;
  color:white;
  & thead{
    background:$color-01;
    color:white;
    & th{
      padding:20px;
      @include x-rem(font-size,17px);
      text-transform: uppercase;
    }
  }
  & tbody{
    background:white;
    color:$color-01;
    & td{
      padding:20px;
      @include x-rem(font-size,14px);
      border:1px solid $color-01;
      & .unitprice{
        display:block;
        @include x-rem(font-size,19px);
        font-weight: 700;
      }
    }
  }
  & tfoot{
    background:white;
    color:$color-01;
    & td{
      vertical-align: top;
      padding:20px 0;
      @include x-rem(font-size,14px);
      & .total{
        display:block;
        @include x-rem(font-size,26px);
        font-weight: 700;
      }
    }
  }
}

@media (max-width:$screen-xs-max) {
  .tablevariation{
    & thead{
      display: none;
    }
    & tbody{
      background:white;
      color:$color-01;
      & tr{
        display: block;
        border:1px solid $color-01;
        padding:10px 10px 0 10px;
        margin-bottom: 20px;
      }
      & td{
        display: block;
        border:0;
        padding:0 0 10px 0;
        text-align: center;
      }
    }
    & tfoot{
      & tr{
        display: block;
        padding:10px 10px 0 10px;
      }
      & td{
        display: block;
        border:0;
        padding:0 0 10px 0;
        text-align: center;
      }
    }
  }

}