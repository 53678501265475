.dt-c-TextImage {
  $p: &;
  @include rem(padding,30px 0);
  &-wrapper {
    display: flex;
    //align-items: flex-start;
    flex-direction: column;
    
    #{$p}[data-image-framed='true'][data-theme='dark'] & {
      @include rem(padding,30px);
    }
  }

  &-content {
    p:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  &-image {
    img{
      object-fit: cover;
      height: 100%;
    }
    #{$p}[data-image-framed='true'] & {
      position: relative;
      padding-top: 30px;
      padding-right: 30px;

      &::before {
        content: '';
        z-index: 0;
        @include cover-abs;
        top: 0;
        left: 30px;
        width: calc(100% - 30px);
        height: calc(100% - 30px);
        border: 14px solid color(secondary);
      }

      img {
        z-index: 1;
        position: relative;
      }
    }
  }


  /**
   * Dark background
   */
  &[data-theme='dark'] {
    padding: 0;
    color: color(light);
    background-color: color(background--dark);
    #{$p}-content{
      @include rem(padding,30px);
      @include mq-from(large){
        @include rem(padding,30px 30px 30px 0);
      }
    }
    &[data-image-framed='true']{
      #{$p}-content{
        padding: 0;
      }
    }
    &[data-position='text-image']{
      #{$p}-content{
        @include mq-from(large){
          @include rem(padding,30px 30px 30px 30px);
        }
      }
    }
  }

  /**
   * Positionning variations
   */
  &[data-position='text-image'] {
    #{$p}-image {
      margin-top: 30px;
    }
  }

  &[data-position='image-text'] {
    #{$p}-wrapper {
      flex-direction: column-reverse;
    }

    #{$p}-content {
      
      @include mq-from (large){
        margin-top: 30px;
      }
    }
  }


  @mixin largeMode {
    &-wrapper {
      flex-direction: row;

      > * {
        flex: 0 0 calc(50% - 15px);
      }
    }

    &[data-image-framed='true'] #{$p}-content {
      padding-top: 30px;
    }


    /**
     * Positionning variations
     */
    &[data-position='text-image'] {
      #{$p}-content {
          margin-right: 30px;
      }

      #{$p}-image {
        margin-top: 0;
      }
    }

    &[data-position='image-text'] {
      #{$p}-wrapper {
        flex-direction: row-reverse;
      }

      #{$p}-content {
        margin-top: 0;
      }

      #{$p}-image {
        margin-right: 30px;
      }
    }
  }


  @include mq-from(large) {
    body[data-template='doubleColumnStandard'] & {
      @include largeMode;
    }
  }

  @include mq-from(medium) {
    body:not([data-template='doubleColumnStandard']) & {
      @include largeMode;
    }
  }
}
