.btn {
  display: inline-block; // Resets Agroequipement styles
  min-width: 190px;
  width: auto;
  margin: 0;
  padding: 12px 35px;
  @include fs(13px, 16px);
  font-weight: fw(extraBold);
  font-family: font(title);
  text-transform: uppercase;
  color: color(dark);
  background: none;
  border: 2px solid color(secondary);
  border-radius: 25.5px;
  white-space: normal;
  transition: all .2s ease-in-out;

  & ~ & {
    margin-left: 0;
  }

  &::before, &::after {
    content: none;
  }

  // Lvl 1 btn
  &-primary {
    color: color(dark);
    background-color: color(secondary);

    @include a11yhover {
      color: color(dark);
      background-color: transparent;
    }

    @include a11yhover('[data-theme="dark"]') {
      color: color(light);
    }
  }
  &-secondary-green {
    color: color(light);
    background-color: color(primary);
    border-color:color(primary);

    @include a11yhover {
      color: color(dark);
      background-color: transparent;
    }
  }

  &-secondary{
    @include a11yhover {
      color: color(dark);
      background-color: color(secondary);
    }
  }
  &-tertiary{
    color: color(light);
    background-color: color(tertiary);
    border-color: color(tertiary);
    @include a11yhover {
      background-color: color(light);
      color: color(dark);
    }
  }

  &-light {
    color: color(dark);
    background-color: color(light);

    @include a11yhover {
      color: color(dark);
      background-color: transparent !important;
    }
  }
  
  // Lvl 2 btn
  &:not(.btn-primary,.btn-secondary-green,.btn-light) {
    [data-theme='dark'] & {
      color: color(light);
    }

    @include a11yhover {
      color: color(dark);
      background-color: color(secondary);
      border: 2px solid color(secondary);
    }

    @include a11yhover('[data-theme="dark"]') {
      color: color(dark);
    }
  }

  &.min-w-auto{
    min-width: auto;
  }
}

.btn-default, .btn-secondary {
  @extend .btn;
}

.bg-background--dark{
  [class*="btn-"]{
    @include a11yhover{
      color: color(light);
    }
  }
}