//
// Modals
// --------------------------------------------------



.modal-dialog {

	.close {
		position: absolute;
		top:10px;
		right:20px;
		font-size: 40px;
		font-weight: bold;
		line-height: 1;
		color: map-get($colors,black);
		opacity: 1;
	}
	.modal-content{
		background: map-get($colors,border--2);
	}
	.modal-header {
		padding: 15px 50px 15px 15px;
		position: relative;
		background: map-get($colors,background--darker);
		border:0;
	}
	
	.modal-footer {
		.btn {
			width: auto;
			display: inline-block;
			margin-bottom: 0;	
		}
	}
}	