.cpt_push{
  width: 100%;
  padding:20px;
  background: $push-bkg;
  color: $push-color;
  & .title{
    display:block;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;
    @include x-rem(font-size,30px);
    text-transform: uppercase;
  }
  & .img{
    display:block;
    margin-bottom: 20px;
    & img{
      width: 100%;
      height: auto;
    }
  }
  & .desc{
    display:block;
    margin-bottom: 20px;
    @include x-rem(font-size,15px);
  }
  & .actions{
    display:block;
  }

}