.dt-c-Tabs{
    &-head{
        display: flex;
        justify-content: center;
        align-items: center;
        &--item{
            width: 100%;
            @include rem(padding,12px);
            margin: 0;
            @include rem(border-radius,5px 5px 0 0);
            text-align: center;            
            background: color(background--darker);
            font-family: font(title);
            color:color(dark);
            font-weight:fw(bold);
            @include rem(font-size,13px);
            text-transform: uppercase;
            cursor:pointer;
            @include mq-from(medium){
                @include rem(width,300px);
                &:not(:last-child){
                    @include rem(margin,0 30px 0 0);
                }
            }
            &.active{
                background: color(tertiary);
                color:color(light);
                cursor: auto;
            }
        }
    }
    &-content{
        border-top: 2px solid color(tertiary);
    }
}