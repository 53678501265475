$color-red-error: rgb(185, 74, 72);
$color-green-success: #28a745;
$color-grey-arrow: rgba(204, 204, 204, 0.2);

$width-default: 220px; // 3 960px-grid columns

$zindex-select-dropdown: 1060; // must be higher than a modal background (1050)

//** Placeholder text color
$input-color-placeholder: $btn-default-color; // ish customization
$input-alt-color-placeholder: rgba(255, 255, 255, 0.5);

$input-padding-y-sm: .25rem;
$input-padding-x-sm: .5rem;

$input-padding-y-lg: 0.5rem;
$input-padding-x-lg: 1rem;