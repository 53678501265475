
//
// SHARE TOOLS

.share-tools {
	margin: 0;
	padding: 0;
	list-style: none;

	
	> li {
		float: right;
		font-size: 18px;
		/*border-right: $border-width-default solid $border-color-light;*/
		padding: 0 $space-default;
		margin: 0;
		height: 23px;
		overflow: hidden;
		max-width: 50px;
		@include transition(max-width 0.5s ease-in-out);

		button {
			border: 0;
			background-color: transparent;
			padding: 0;
			white-space: normal;
		}

	}
	
	.glyphicon { 
		color: $text-color-corporate;
		float: left;
		font-size: 22px;
	}
	
	li {
		&:hover {
			.glyphicon {
				color: $text-color-corporate;
			}
		}
	}
	
	@media (min-width: $screen-sm-min) {
		li {
			@include transition(max-width 0.5s ease-in-out);
			 
			 &:hover {
				overflow: hidden;
				max-width: 300px;
				color: $text-color-corporate;
				cursor: pointer;
				
				 .share-label {
					color: $text-color-corporate;
				}
			}
			
			.share-label {
				font-size: $font-size-base;
				float: right;
				padding-left: ($space-default / 2);				
				line-height: 26px;
				color: $text-color-quarternary;

				@media (max-width:$screen-xs-max) {	
					display: none;
				}
			}
		}
	}
}

//
// MY ACCOUNT - TOOLBAR
.account-wrapper {
	.share-tools {
		margin-bottom: $space-default;
	}
}