/* UNEAL EN CHIFFRES */
.cpt_cms-stats{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  @include rem(margin-bottom,57px);
  font-family: "Barlow", Arial, sans-serif;
  & .stat{
    &-item{
      @include rem(margin-bottom,20px);
      @include rem(padding,0 25px);
      @include mq-from(medium) {
        width: calc(100% / 2);
      }
      @include mq-from(large) {
        width: calc(100% / 5);
        margin-bottom: 0;
      }
    }
    &-value{
      display: flex;
      align-items: center;
      justify-content: center;
      @include rem(height,78px);
      background: url('../img/bg-stat.svg') no-repeat center center/contain;
      @include rem(margin-bottom,13px);
      @include rem(font-size,40px);
      color: #3F6C51;
      text-align: center;
      font-weight: 700;
    }
    &-label{
      text-transform: uppercase;
      color:#13100E;
      text-align: center;
      @include rem(font-size,14px);
      font-weight: 700;
    }
  }
}