.dt-c-TemplatePages {
    &[data-template="doubleColumnStandard"] {
        .dt-c-TemplatePages {
            &-contentcolumn {
                @include clearfix();
            }
            &-columnLeft {
                width: calc(100% - 380px);
                overflow: hidden;
                @include mq-to(large) {
                    width: 100%;
                }
            }
            &-columnRight {
                width: 350px;
                margin-left: 30px;
                overflow: hidden;
                @include mq-to(large) {
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }

    &[data-template="singleColumnStandard"] {

    }
}

