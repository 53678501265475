@mixin mq-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @media screen and (max-width: $value - 1) {
      @content;
    }
  }
}

@mixin mq-from($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $value: map-get($breakpoints, $breakpoint);

    @media screen and (min-width: $value) {
      @content;
    }
  }
}

@mixin mq-from-to($breakpoint1, $breakpoint2) {
  @if map-has-key($breakpoints, $breakpoint1) and map-has-key($breakpoints, $breakpoint2) {
    $value1: map-get($breakpoints, $breakpoint1);
    $value2: map-get($breakpoints, $breakpoint2);

    @media screen and (min-width: $value1) and (max-width: $value2 - 1) {
      @content;
    }
  }
}