.dt-c-HomeLogin {
  margin: 25px 0;
  color: color(dark);

  body:not(.logged) & {
    margin: 20px 0 45px;

    &-wrapper {
      width: 1150px;
    }
  }

  small {
    @include fs(12px, 17px);

    a {
      text-decoration: underline;
    }
  }

  &-heading {
    h2.h1 {
      @include fs(24px, 28px);
      white-space: nowrap;
    }
  }

  &-form {
    &-field {
      label {
        display: block;
        margin-bottom: 3.5px;
        @include fs(14px, 19px);
      }

      &-wrapper {
        position: relative;

        [data-showpassword-action='password'] {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 40px;
          transition: all .2s ease;
          cursor: pointer;
        }

        input[type='text'] ~ [data-showpassword-action] {
          color: color(tertiary);
        }
      }

      small {
        display: block;
        text-align: right;
      }
    }
  }

  img {
      width:20% !important; 
      margin-top:25px;
  }

  // Logged display
  &-logged {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }


  @include mq-from(medium) {
    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-heading {
      margin-right: 24px;
      position: relative;
      padding: 19px 0;

      small {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &-form {
      display: flex;
      align-items: center;

      > * {
        flex: 1 1 0;

        &:not(:last-child) {
          margin-right: 14px;
        }
      }

      &-field {
        position: relative;
        padding-bottom: 19px;

        small {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &-submit {
        .btn {
          display: block;
          width: 100%;
        }
      }
    }
  }

  @include mq-to(medium) {
    &-heading {
      margin-bottom: 10px;
      text-align: center;
    }

    img {
      width:40% !important;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    &-form {
      > *:not(:last-child) {
        margin-bottom: 10px;
      }

      &-submit {
        text-align: center;
      }
    }

    // Logged display
    &-logged {
      display: block;
      text-align: center;

      h2 {
        margin-bottom: 15px;
      }
    }
  }
}