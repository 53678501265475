
//
// SPECIFIC THEME MIXINS


//
// Rounded corners

@mixin round-corner($r) {
	-webkit-border-radius: $r;
	-moz-border-radius: $r;
	border-radius: $r;
}


//
// CATEGORY FILTER PANEL

@mixin filter-list-item-layer($layer) {
	padding-left: $layer * $space-default;
}

@mixin objectFit() {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
@mixin x-rem($property, $values, $sledgehammer: '') {
	// Create a couple of empty lists as output buffers.
	$base-font-size: 16px; // should be consistent with your html/body font-size
	$px-values: ();
	$rem-values: ();
	// Loop through the $values list
	@each $value in $values {
		// For each property value, if it's in rem or px, derive both rem and
		// px values for it and add those to the end of the appropriate buffer.
		// Ensure all pixel values are rounded to the nearest pixel.
		@if $value == 0 or $value == 0px {
			// 0 -- use it without a unit
			$px-values: join($px-values, 0);
			$rem-values: join($rem-values, 0);
		} @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
			// px value given - calculate rem value from base-font-size
			$px-values: join($px-values, round($value));
			$rem-values: join($rem-values, #{ $value / $base-font-size }rem);
		} @else if $value == auto or $value == inherit {
			// auto or inherit - don't add px or rem
			$px-values: join($px-values, $value);
			$rem-values: join($rem-values, $value);
		} @else {
			// unitless value - use those directly as rem and calculate the px-fallback
			$px-values: join($px-values, round($value));
			$rem-values: join($rem-values, #{$value}rem);
		}
	}
	// output the converted rules
	#{$property}: $px-values #{$sledgehammer};
	#{$property}: $rem-values #{$sledgehammer};
}