
//
// HOMEPAGE


.homepage {
	
	header {
		margin-bottom: 0px;
	}
	
	.wrapper {
		background: none;
		padding: 0;
		
		.main-container {
			width: 100%;
			padding: 0;
		}
	}
	
	.main-container {
		img {
			width: 100%;
		}
		
		.brands-carousel {
			img {
				width: auto;
			}
		}
	}
	
	
	.content-container:not(.container) {
		padding: 0;
	}
}

.homepage-carousel {
	.carousel-indicators {
		bottom: 35%;
	}
}

.brands-carousel {
	background-color: $color-tertiary;
	padding: 40px 0;
}

.welcome-message {
	margin-top: $space-default;
	
	p {
		font-family: $font-family-condensedbold;
		font-size: $font-size-large;
		margin-bottom: $space-default /3;
		text-transform: uppercase;
	}
	
	a {
		padding-right: $space-default /2; 
	}
}
