.dt-c-Collection {
    &-tabs {
        &-list {
            display: inline-block;
            height: 30px;
            //background-color: color(light);
            position: relative;
            z-index: 0;
            border-radius: 15px;
            @include mq-from(medium){
                @include rem(height,30px);
            }

            &:after {
                content: "";
                height: 100%;
                width: 100%;
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                border: {
                    width: 2px;
                    style: solid;
                    color: color(tertiary);
                    radius: 15px;
                }
            }
        }

        &-item {
            display: inline-block;
            border-radius: 15px;
            @include rem(padding,0 10px);
            height: 100%;
            @include rem(font-size, 13px);
            @include rem(line-height, 30px);
            font-weight: fw(semiBold);
            cursor: pointer;
            @include mq-from(medium){
                @include rem(padding,0 20px);
                @include rem(font-size, 14px);
            }
            a{
                padding: 0 !important; /* USE OF !important to overrive inherited navtab styles*/
                border: none !important;
                background-color: transparent !important;
                margin: 0 !important;
                @include rem(line-height, 30px, !important);
                color: color(dark) !important;
                z-index: 2;
            }
            &[data-active="true"],
            &.active {
                cursor: default;
                background-color: color(tertiary);
                a{
                    color: color(light) !important;
                }
            }
        }
    }
}

