.dt-c-Layer {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: color(light);
  transition: transform .4s ease;

  &:not([data-shown='true']) {
    opacity: 0;
    transform: translateX(-100%);
    pointer-events: none;
    transition: transform .4s ease, opacity 0s .4s;
  }

  &-top {
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 34px;
    padding-bottom: 5px;

    &[data-background='true'] {
      background-color: color(light);
    }

    &-label {
      @include fs(16px, 18px)
    }

    &-close {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      margin: -10px;
      padding: 10px;
      font-size: 0;
      box-sizing: content-box;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        height: 2.5px;
        width: 22px;
        background-color: color(dark);
        border-radius: 2px;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &::after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }

  &-content {
    z-index: 0;
    position: relative;
  }

  &[data-mode='account'] {
    background-color: color(background--light);
  }
}