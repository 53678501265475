.dt-u-AccountCollecte{
    &--Table{
        table-layout: fixed;
        @include mq-to(medium){
            tbody{
                tr{
                    position: relative;
                    @include rem(margin-bottom,10px);
                    td{
                        border-bottom: none !important;
                        padding: 0;
                        [data-toggle-sublevel]{
                            position: absolute;
                            right: 0;
                            @include rem(top,5px);
                        }
                        
                    }
                    &.active{
                        td{
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        & > thead{
            th{
                &.toggle-cell{
                    @include mq-from(medium){
                        @include rem(width,50px);
                    } 
                }
                &:not(.toggle-cell){
                    @include mq-from(medium){
                        width: calc((100% / 5) - 50px);
                    }
                }
            }
        }
        & tbody{
            &  tr{
                &.active{
                    .on-active{
                        display: block;
                    }
                    .on-inactive{
                        display: none;
                    }
                }
                & td{
                    &.nested{
                        padding: 0;
                        &.px-10{
                            padding: 0 10px;
                        }
                    }
                }
            }
        }
        .nested{
            tbody{
                &.active{
                    border:1px solid color(border);
                }
            }
            @include mq-to(medium){
                & > table{
                    &:not(.secondary){
                        & > tbody{
                            background: color(border--2);
                            @include rem(padding,15px 15px 15px 30px);
                            @include rem(margin-bottom,15px);
                        }
                    }
                }
            }
        }
        [data-toggle-sublevel]{
            display: block;
            @include rem(font-size,25px);
            color:color(dark);
            text-align: center;
            cursor: pointer;
            line-height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            span{
                @include rem(font-size,12px);
                @include rem(margin-right,5px);
                @include mq-from(medium){
                    display: none;
                }
            }
            img{
                @include rem(width,15px);
                filter: brightness(0);
            }
            .on-active{
                display: none;
            }
            .on-inactive{
                display: block;
            }
        }
        &.tertiary{
            @include mq-to(medium){
                [data-toggle-sublevel]{
                    right: auto;
                    @include rem(left,-20px);
                }
            }
        }
    }
}