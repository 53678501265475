$tooltipArrowSize: 80px;

@mixin topTooltipArrow {
  border-right: ($tooltipArrowSize/2) solid transparent;
  border-bottom: ($tooltipArrowSize/2) solid color(background--darker);
  border-left: ($tooltipArrowSize/2) solid transparent;
}

@mixin bottomTooltipArrow {
  border-top: ($tooltipArrowSize/2) solid color(background--darker);
  border-right: ($tooltipArrowSize/2) solid transparent;
  border-left: ($tooltipArrowSize/2) solid transparent;
}

@mixin leftTooltipArrow {
  border-top: ($tooltipArrowSize/2) solid transparent;
  border-right: ($tooltipArrowSize/2) solid color(background--darker);
  border-left: ($tooltipArrowSize/2) solid transparent;
}

@mixin leftTooltipArrow {
  border-top: ($tooltipArrowSize/2) solid transparent;
  border-bottom: ($tooltipArrowSize/2) solid transparent;
  border-left: ($tooltipArrowSize/2) solid color(background--darker);
}


.dt-c-Tooltip {
  z-index: 2;
  position: relative;
  display: inline-block;
  vertical-align: baseline;

  $p: &;

  &-toggler {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    font-family: font(text);
    font-weight: fw(light);
    font-size: 18px;
    line-height: 0;
    color: color(dark);
    border: 1px solid currentColor;
    border-radius: 50%;
    transition: all .2s ease;

    &:hover {
      color: color(tertiary)
    }
  }

  &-content {
    position: absolute;
    display: block;
    transition: all .2s ease;

    &-wrapper {
      z-index: 1;
      position: relative;
      display: block;
      width: 260px;
      max-height: calc(45px * 4 + 5px * 4);
      padding: 5px 10px;
      font-size: 12px;
      line-height: 15px;
      font-weight: fw(regular);
      background-color: color(background--darker);
      overflow: auto;
      scrollbar-width: thin;
    }

    &-arrow {
      z-index: 0;
      position: absolute;
      display : block;
      height : 0;
      width : 0;
    }

    &:not([data-shown='true']) {
      opacity: 0;
      pointer-events: none;
    }
  }


  @include mq-from(large) {
    &-content[data-display-large*='top'] {
      bottom: calc(100% + 15px);
      #{$p}-content-arrow {
        top: 100%;
        transform: translateY(calc(-100% + 12px));
        @include bottomTooltipArrow;
      }
    }
    &-content[data-display-large*='bottom'] {
      top: calc(100% + 15px);
      #{$p}-content-arrow {
        bottom: 100%;
        transform: translateY(calc(100% - 12px));
        @include topTooltipArrow;
      }
    }
    &-content[data-display-large*='left'] {
      right: -35px;
      #{$p}-content-arrow {
        right: calc(#{$tooltipArrowSize} - 75px);
      }
    }
    &-content[data-display-large*='right'] {
      left: -35px;
      #{$p}-content-arrow {
        left: calc(#{$tooltipArrowSize} - 75px);
      }
    }
  }

  @include mq-to(large) {
    &-content {
      &-wrapper {
        width: 200px;
      }
    }

    &-content[data-display-small*='top'] {
      bottom: calc(100% + 15px);
      #{$p}-content-arrow {
        top: 100%;
        transform: translateY(calc(-100% + 12px));
        @include bottomTooltipArrow;
      }
    }
    &-content[data-display-small*='bottom'] {
      top: calc(100% + 15px);
      #{$p}-content-arrow {
        bottom: 100%;
        transform: translateY(calc(100% - 12px));
        @include topTooltipArrow;
      }
    }
    &-content[data-display-small*='left'] {
      right: -35px;
      #{$p}-content-arrow {
        right: calc(#{$tooltipArrowSize} - 75px);
      }
    }
    &-content[data-display-small*='right'] {
      left: -35px;
      #{$p}-content-arrow {
        left: calc(#{$tooltipArrowSize} - 75px);
      }
    }
  }
}