// .nq-.. given for retro-compatibility

.nq-u-hspace,
.dt-u-hspace {
  padding-right: 20px;
  padding-left: 20px;

  @include mq-from(large) {
    padding-right: 40px;
    padding-left: 40px;
  }
}
