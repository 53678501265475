.cpt_product-availability{
  display:inline-block;
  position: relative;
  @include x-rem(padding-left,20px);
  font-family: font(text);
  span{
    @include x-rem(font-size,14px);
    font-weight: normal;
    &:before{
      content: "";
      @include x-rem(width,8px);
      @include x-rem(height,8px);
      @include x-rem(margin-top,-4px);
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 100%;
    }
  }
  & .instock{
    color:color(primary);
    &:before{
      background: color(primary);
    }
  }
  & .outofstock{
    color:$brand-danger;
    &:before{
      background: $brand-danger;
    }
  }
  & .ressuplying{
    color:color(secondary);
    &:before{
      background: color(secondary);
    }
  }
}