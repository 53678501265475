.form-horizontal {
  .control-label {
    text-align:left;

    &.control-label-right {
      text-align:right;
      padding-right: 0;
      @media (max-width:$screen-xs-max) {
        text-align:left;
      }
    }
  }
}

.form-inline {
  label {
    padding-right: ($space-default / 4);
  }
}

label {
  font-weight: normal;

  &.disabled {
    opacity: 0.5;
  }
}

.input-help {
  color: $text-color-quarternary;
}

.radio {
  label {
    label {
      padding-left: 0;
    }
  }
}


fieldset {
  margin-bottom: ($space-default);
}

.form-control:focus {
  border-color: $border-color-corporate;
}

input.quantity {
  min-width: 80px;
  max-width: 100%;
}

input[type="radio"] ~ input[type="text"] {
  margin-left: $space-default /2;

  @media (max-width: $screen-xs-max) {
    display: inline-block;
    width: 90%;
  }
}
input[type="file"] {
  display: block !important;
  border: 1px solid #ccc !important;
  height: auto !important;
  padding:10px !important;
}
// ERROR
.has-feedback .form-control-feedback {
  height: 50px;
  line-height: 50px;
  position: absolute;
}

.bv-form .has-feedback select + .form-control-feedback {
  right: 28px;
}


// 	REQUIRED FIELDS

.indicates-required {
  color: $text-color-quarternary;

  .required {
    color: $text-color-special;
    margin-right: 4px;
  }
}

label {
  .required {
    color: $text-color-special;
    margin-left: 4px;
  }
}


.server-error {
  @include form-control-validation($state-danger-text, $state-danger-text, $state-danger-bg);
}

.has-feedback {

  .form-control[type="number"] {
    padding: $padding-base-vertical $padding-base-horizontal;
  }

  .form-control[type="number"] + .form-control-feedback {
    display: none !important;
  }

  .form-control {

    + a.form-control-feedback {
      pointer-events: all;
    }

  }
}

.has-success .checkbox {
  color: inherit;
}


.form-horizontal {
  .has-feedback .form-control[type="number"] + .form-control-feedback {
    display: none !important;
  }

  &.form-horizontal-inline {
    @media (max-width:$screen-xs-max) {
      label {
        padding-top: $space-default/2;
      }
    }
  }
}

input[type="file"] {
  border: none;
  box-shadow: none;
  padding: 0;
}
