.dt-c-Radio{
  position: relative;
  label{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    &:before{
      content:"";
      @include rem(width,18px);
      @include rem(height,18px);
      border: 1px solid color(black);
      border-radius: 100%;
      display: block;
      @include rem(margin-right,5px);
      flex-shrink: 0;
      align-self: flex-start;
      background:color(light);
    }
    &:after{
      content:"";
      background: color(dark);
      transform: scale(0);
      transition: all 0.2s ease;
      display: block;
      @include rem(width,12px);
      @include rem(height,12px);
      position: absolute;
      @include rem(left,3px);
      @include rem(top,3px);
      border-radius: 100%;
    }
  }
  &.input-centered{
    label{
      .label-text{
        // display: inline-block;
        @include rem(padding,0 15px 0 23px);
      }
      &:before{
        top:50%;
        transform: translateY(-50%);
      }
      &:after{
        top:50%;
        transform: translateY(-50%) scale(0);
      }
      &:before{
        position: absolute;
      }
    }
    input[type=radio]{
      &:checked + label{
        &:after{
          transform: translateY(-50%) scale(1);
        }
      }
    }
  }
  input[type=radio]{
    display: none;
    &:checked + label{
      &:after{
        transform: scale(1);
      }
    }
  }
}