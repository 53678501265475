.dt-c-Pagination{
    display:flex;
    justify-content: center;
    align-items: center;
    color:color(primary);
    font-weight: fw(bold);
    font-family: font(text);
    @include mq-to(medium){
        flex-wrap: wrap;
    }
    li{
        @include rem(margin-right,20px);
        @include mq-to(medium){
            @include rem(margin-bottom,15px);
            &:first-of-type,
            &:last-of-type{
                width: 100%;
                text-align: center;
                margin-right: 0;
            }
        }
        @include mq-from(medium){
            &:last-of-type{
                margin-right: 0;
            }
        }
    }
    &-item{
        &.active{
            display: block;
            @include rem(width,32px);
            @include rem(height,32px);
            background: color(primary);
            color:color(light);
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}