.gallery-thumbs{
  & .swiper-slide-thumb-active{
    border-bottom: 1px solid #fafafa;
  }
}
.gallery-top{
  & img{
     cursor: zoom-in;
  }
}
.galleryzoom-modal{
  display:none;
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 13000;
  &.isActive{
    display:block;
  }
}
.galleryzoom-shadow{
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: black;
  opacity: 0.8;
}
.galleryzoom-zoom{
  display:inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  & img{
    cursor: zoom-in;
    &.zoomIn{
      cursor: zoom-out;
    }
  }
}
.galleryzoom-close{
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: black;
  z-index: 10;
  cursor: pointer;
  & .icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
    color: white;
  }
}
.galleryzoom-wrapper{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background: white;
  height: 90%;
  padding:20px;
  @media (max-width:$screen-sm-max) {
    width: 95%;
    height: 95%;
    padding:0;
  }
  & .swiper-container{
    height:100%;
    & .swiper-slide{
      text-align: center;
      & img {
        width: auto;
        height: 100%;
        @media (max-width:$screen-sm-max) {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 100%;
          height: auto;
        }
      }

    }
  }
  & .swiper-button-prev .icon,
  & .swiper-button-next .icon {
    color: black;
  }
}