.ui-spinner{
  position:relative;
  display:inline-block;
  max-width:200px;
  width:100%;
  border:1px solid $color-06;
  background-color: white;
  padding:0 45px 0 45px;

  & input{
    position:relative;
    width:100%;
    padding:9px 0 !important;
    border:0;
    font-size:18px;
    text-align: center;
    color:$color-07;
    &:focus{
      outline: none;
    }
  }
  .ui-spinner-up,
  .ui-spinner-down{
    position:absolute;
    top:0;
    background-color: white;
    width:40px;
    height:100%;
    font-size: 10px;
    text-align: center;
    cursor: pointer;
    color:$color-01;

    &:before {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      color: $color-01;
      font-size: 15px;
    }
    &:hover{
      text-decoration: none;
      background-color: $color-01;
      &:before{color:white;}
    }
    & .ui-button-text{
      display:none
    }
  }
  .ui-spinner-up{
    right:0;
    &:before{
      content:"+";
    }
  }
  .ui-spinner-down{
    left:0;
    &:before{
      content:"-";
    }
  }
  &.ui-spinner-disabled{
    background-color: #ebebe4;
  }
}