@mixin a11yhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
  width: 1px;
  height: 1px;
}

@mixin a11yhover($parent_selector: '') {
  $parent: unquote($parent_selector);

  a#{$parent}:hover &, a#{$parent}:focus &, button#{$parent}:hover &, button#{$parent}:focus &, #{$parent} &:hover, #{$parent} &:focus {
    @content;
  }
}