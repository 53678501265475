.cpt_bannerfull{
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(100vh - 400px);
  overflow: hidden;
  & img{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    object-fit: cover;
    min-width: 100%;
    width: auto;
    height: auto;
  }
  & .inner{
    position: absolute;
    top: 50%;
    left:200px;
    transform: translateY(-50%);
    width: calc(100% - 400px);
    & .title{
      display: block;
      margin-bottom: 20px;
      color:white;
      @include x-rem(font-size,40px);
    }
  }
  & .bloc{
    position: absolute;
    bottom:0;
    left:50%;
    transform: translateX(-50%);
    color:#333333;
    @include x-rem(font-size,40px);
    background:white;
    padding:20px 50px;
    text-align: center;
  }
}

@media (max-width:$screen-xs-max) {
  .cpt_bannerfull{
    & .inner{
      left:40px;
      width: calc(100% - 80px);
    }
  }
}