// (un)capitalize
@function capitalize($string) {
  @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}

@function uncapitalize($string) {
  @return to-lower-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}

// Contains (return whether `$value` is contained in `$list`)
@function contains($list, $value) {
  @return not not index($list, $value);
}

// Camelize
@function camelize($string) {
  $progress: $string;
  $result: "";
  $exclude: " ", "-", "–", "—", "_", ",", ";", ":", ".";

  @while str-length($progress) > 0  {
    $char: str-slice($progress, 1, 1);

    @if contains($exclude, $char) {
      $progress: capitalize(str-slice($progress, 2, 2)) + str-slice($progress, 3);
    }
    @else {
      $result: $result + $char;
      $progress: str-slice($progress, 2);
    }
  }

  @return $result;
}

// Colors
@function color($scheme) {
  @return map-get($colors, $scheme);
}

// Fonts
@function font($scheme) {
  @return unquote(map-get($fonts, $scheme));
}

@function fw($scheme) {
  @return map-get($font-weights, $scheme);
}

@function calculateRem($size) {
  $remSize: $size / $base-fs;
  @return $remSize * 1rem;
}

@function calculateLh($fs, $lh) {
  @return $lh / $fs;
}

@mixin fs($size, $lh: false) {
  @if $lh {
    font-size: $size;
    font-size: calculateRem($size);
    line-height: calculateLh($size, $lh);
  } @else {
    font-size: $size;
    font-size: calculateRem($size);
  }
}

@mixin fsr() {
  @include fs($base-fs, $base-lh);
}
