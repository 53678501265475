.dt-c-Navigation {
  height: 100%;

  &-list {
    display: flex;
    align-items: stretch;
  }

  &-item {
    $p: &;

    &-name {
      position: relative;
      padding: 0 10px;
      @include fs(15px, 18px);
      font-weight: fw(bold);
      font-family: font(title);
      text-transform: uppercase;
      color: color(primary);
      transition: all .2s ease-in-out;

      &[data-nowrap='true'] {
        white-space: nowrap;
      }

      &:hover, &:focus {
        color: color(primary);
      }
    }

    &-submenu {

      &-wrapper.arrowtop{
        &:after {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content:" ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #ffffff;
          border-width: 10px;
          margin-left: -10px;
        }
        &:before {
          content: '';
          position: absolute;
          transform: rotate(45deg);
          width: 20px;
          height: 20px;
          margin-left: -10px;
          top:-5px;
          left:50%;
          z-index: -1;
          box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.20);
        }
      }


      &-mobileToggler {
        display: flex;
        align-items: flex-end;
        padding: 20px 15px;
        @include fs(16px, 18px);
        font-family: font(title);
        font-weight: fw(semiBold);
        color: color(dark);
        text-transform: uppercase;

        &::before {
          content: '';
          width: 26px;
          height: 26px;
          margin-right: 10px;
          background: {
            image: url('../img/icons/arrow-back.svg');
            position: center;
            size: contain;
            repeat: no-repeat;
          };
        }
      }
    }
  }

  &-mobileCta {
    margin-top: 50px;
    text-align: center;
  }


  /**
   * Large UI
   */

  @include mq-from-to(large, xxlarge) {
    &-item {
      &[data-type='home'] &-name {
        font-size: 0;
        padding-right: 8px;
      }
    }
  }

  @include mq-from(large) {
    [data-dtdisplay='large'] {
      display: block;
    }

    &-list {
      height: 100%;
    }

    &-item {
      $p: &;

      &-name {
        display: flex;
        align-items: center;
        justify-content: center;
        width: min-content;
        height: 100%;

        img {
          @include center-abs-y;
          width: 14px;
          height: auto;
          transition: all .2s ease-in-out;
        }

        // Burger icon on left
        &[data-icon='left'] {
          padding-left: 10px + 14px + 10px;

          img {
            left: 10px;
          }
        }

        // Arrow down on right
        &[data-icon='right'] {
          padding-right: 10px + 14px + 10px;

          img {
            right: 10px;
          }
        }

        // small spike on top of submenu
        &::after {
          content: '';
          position: absolute;
          bottom: -4px;
          display: inline-block;
          width : 0;
          height : 0;
          border-right : 15px solid transparent;
          border-bottom : 15px solid color(light);
          border-left : 15px solid transparent;
          opacity: 0;
          transition: all .2s ease-in-out;

          #{$p}[data-type='products'] & {
            border-bottom-color: color(background--light);
          }
        }

        body.homepage:not(.logged) .dt-c-Header:not([data-sticky='true']) & {
          color: color(light);
        }
      }

      &-submenu {
        @include center-abs-x;
        top: calc(100% - 20px);
        padding-top: 4px; // To space up with menu without allow mouse to stop hovering
        color: color(primary);
        opacity: 0;
        pointer-events: none;
        transition: all .2s ease-in-out;

        &-mobileToggler {
          display: none;
        }

        &-wrapper {
          background-color: color(light);
          box-shadow: 0 3px 8px 0 rgba(color(dark), 0.1);
        }
      }

      // Show submenu interaction
      &[data-parent='true'] {
        &:hover, &:focus-within {
          #{$p}-name::after, #{$p}-submenu {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }

    &-mobileCta {
      display: none;
    }
  }

  @include mq-from(xxlarge) {
    &-item {
      &-name {
        width: auto;
      }
    }
  }

  @include mq-from(xxxlarge) {
    &-item {
      &-name {
        padding: 0 15px;

        // Burger icon on left
        &[data-icon='left'] {
          padding-left: 15px + 14px + 10px;

          img {
            left: 15px;
          }
        }

        // Arrow down on right
        &[data-icon='right'] {
          padding-right: 15px + 14px + 10px;

          img {
            right: 15px;
          }
        }
      }
    }
  }


  /**
   * Small UI
   */

  @include mq-to(large) {
    background-color: color(light);
    padding-bottom: 50px;
    overflow: auto;

    // Logo on top of all levels
    &::before,
    [data-parent='true'] > [data-show-hide='content']::before {
      content: '';
      display: block;
      width: 96px;
      height: 62px;
      margin: 0 auto;
      background: {
        image: url('../img/logo.svg');
        size: contain;
        position: center;
        repeat: no-repeat;
      };
    }
    &::before {
      margin-bottom: 64px;
    }

    [data-dtdisplay='small'] {
      display: block;
    }

    &:not([data-shown='true']) {
      opacity: 0;
      pointer-events: none;

      .dt-c-Header & {
        display: none; // Prevent view dropping while js layer loading
      }
    }

    &-list {
      flex-direction: column;
    }

    &-item {
      $item: &;

      a[data-navigation] {
        display: block;
        padding: 25.5px 15px;
        @include fs(20px, 24px);
        font-weight: fw(bold);
        font-family: font(title);
        text-transform: uppercase;
        color: color(primary);
        border-top: 1px solid color(border--light);
      }

      &:last-child > a[data-navigation],
      *:last-child > a[data-navigation] {
        border-bottom: 1px solid color(border--light);
      }

      &[data-parent='true'] > a[data-navigation],
      [data-parent='true'] > a[data-navigation] {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '';
          display: block;
          width: 9px;
          height: 15px;
          background: {
            image: url('../img/icons/arrow-right-small.svg');
            size: contain;
            position: center;
            repeat: no-repeat;
          };
        }
      }

      &-name {
        &[data-icon] img {
          display: none;
        }
      }

      [data-show-hide='content'] {
        @include cover-abs;
        z-index: 2;
        background-color: color(light);
        overflow: auto;

        &:not([data-shown='true']) {
          max-height: 0;
          min-height: 0;
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}