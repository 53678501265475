//
// BUTTONS

.btn {
	font-family: $font-family-bold;
	
	@media (max-width: $screen-xs-max) {
		display: block;
		width: 100%;
		margin-bottom: $space-default;		
		clear: both;
	}
	
	& ~ .btn {
		margin-left: 10px;
		@media (max-width: $screen-xs-max) {
			margin-left: 0;
		}
	}
	
	& ~ .btn.btn-block {
		margin-left: 0;
	}
		
	&.btn-link {
		text-transform: unset;
		font-family: $font-family-regular;
		text-decoration: none;
	}
	
	&.btn-link-inline {
		padding: 0;
		line-height: inherit;
		vertical-align: baseline;
		display:inline;
	}
}


.btn-lg {
	font-family: $font-family-condensedbold;
	
	&.btn-block {
		padding-left: 0;
		padding-right:0;
	}
}

//
// RIGHT BUTTONS
.btn.pull-right ~ .btn.pull-right {
	margin-right: 2px;
}

//
// CIRCLE BUTTONS

.btn-circle {
	@include round-corner(50%);
	border: 1px solid $border-color-light;
	color: #ccc;
	display: inline-block;
	font-size: 12px;
	height: 30px;
	line-height: 14px;
	margin-bottom: 5px;
	padding: 7px 8px;
	width: 30px;
}

.btn.processing-state {
	background: $CORPORATE-SECONDARY url(../../../img/icons/processing.gif) 50% no-repeat;
	color: rgba(255,255,255,0);
}

// Button Groups

.btn-group {
	.btn {
		width: auto;
		display: inline-block;
		margin-bottom: 0;
		clear: none;	
	}
}