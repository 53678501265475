.placeholder{
  display:block;
  min-width: 50px;
  & .line,
  & .image{
    position:relative;
    display:block;
    background: linear-gradient(234deg,  #e6ebff, #ffffff, #e6ebff, #ffffff, #e6ebff, #ffffff);
    background-size: 1000% 1000%;
    animation: loading 3s linear infinite alternate;
  }
  & .line{
    height:15px;
    width:75%;
    margin-bottom:5px;
    background: linear-gradient(234deg,  #e6ebff, #ffffff, #e6ebff, #ffffff, #e6ebff, #ffffff);
    background-size: 1000% 1000%;
    animation: loading 3s linear infinite alternate;
    &:last-child{
      margin-bottom:0;
    }
  }
  & .line:nth-child(1){width:100%;}
  & .line:nth-child(2){width:25%;}
  & .line:nth-child(3){width:50%;}
  & .line:nth-child(4){width:65%;}

  & .image{
    width:100%;
    height:200rem;
  }

  & .spin {
    display:inline-block;
    border: 5px solid #e5e5e5;
    border-top: 5px solid #F9C744;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }

}

@keyframes loading {
  0%{background-position:0% 100%}
  100%{background-position:100% 0%}
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}