.dt-c-AuthorCard{
    @include rem(padding,32px 0);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border: {
        width: 2px 0 0 0;
        style: solid;
        color: #D8D8D8;
    }
    @include mq-from(medium){
        flex-direction: row;
    }
    &-image{
        flex-shrink: 0;
        @include rem(width,120px);
        @include rem(height,120px);
        @include rem(margin,0 auto 20px auto);
        @include mq-from(medium){
            @include rem(margin,0 35px 0 0);
        }
        img{
            object-fit: cover;
            object-position: center center;
            height: 100%;
        }
    }
    &-content{
        &--name{
            font-weight: fw(bold);
            @include rem(font-size,16px);
            @include rem(margin-bottom,10px);
            color:color(black);
        }
        &--name,
        &--desc{
            @include mq-to(medium){
                text-align: center;
            }
        }
    }
}