.ratings-box {
	font-size: 0;
	overflow: hidden;
}

.rating-total,
.rating-rated {
	color: $text-color-quarternary;
	line-height: 24px;
}

.rating-total {
	margin-right: $space-default;
}

.rating-overview {
	float: right;
	border-right: $border-width-default solid $border-color-light;
	padding-right: ($space-default * 2);
	
	.rating-display {
		font-size: ($space-default * 2);
	}
}

.rating-input {
	margin: 0;
	padding: 0;
	list-style: none;
	display: block;	
	
	li {
		float: left;
		margin-right: 2px;
		color: $text-color-quarternary;
		
		&:last-child {
			margin-right: 0;
		}
	}
	
	> li {
		font-size: 16px;

		&.stars-before a {
			color: $text-color-primary;
		}
		
		a {
			color: $text-color-quarternary;
			
			&:hover {
				color: $text-color-primary;
			}

		}
	}
}

.rating-display {
	margin: 0px ($space-default /2) 0px 0px;
	padding: 0;
	list-style: none;
	display: block;	
	float: left;
	clear: both; 

	li {
		float: left;
		margin-right: 2px;
		color: $rating-star;
		font-size: 15px;

		&.rating-total {
			font-size: $font-size-base;
			color: $text-color-quarternary;
		}
		
		&:last-child {
			margin-right: 0;
		}
	}
	
	> li {
		&.rating-description {
			padding-left: ($space-default * 2);
			font-size: 85%;
			color: $text-color-secondary;
		}
	}
	
}

.rating-one .star-one,
.rating-two .star-one,
.rating-two .star-two,
.rating-three .star-one,
.rating-three .star-two,
.rating-three .star-three,
.rating-four .star-one,
.rating-four .star-two,
.rating-four .star-three,
.rating-four .star-four,
.rating-five .star-one,
.rating-five .star-two,
.rating-five .star-three,
.rating-five .star-four,
.rating-five .star-five  {
	color: $text-color-primary;
}

div.rating-description {
	overflow: hidden;
	margin-bottom: $space-default;
}

//
// PRODUCT RATING 

.product-tile {
	.product-rating {

		.rating-display {
			position:relative;
			left:50%;
			
			li {
				position:relative;
				right:50%;
			}
		
		}
	}

	.current-price {
		clear: both;
	}
}

.product-rating {
	position: relative;
}

.product-tile .rating-total {
	font-size: 14px;
}
.product-tile-list .rating-total {
	font-size: 14px;
}

//
// PRODUCT DETAIL PAGE / EXPRESSSHOP
.product-details-top-line,
.express-shop {
	.rating-rated {
		float: left;
		white-space: nowrap;
	}
}

//
// REVIEW

.review-item {
	margin-bottom: $space-default*2;


	.review-item-created {
		color: $text-color-quarternary;
	}

	.review-item-header-title {
		font-size: $font-size-large;
	}

	.review-item-text {
		margin-bottom: $space-default;
	}

	+ .pagination {
		margin-top: 0;
	}


}
