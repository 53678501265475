.dt-c-DatesPlaces{
    @include rem(margin-bottom,32px);
    @include rem(padding,15px);
    background: color(light);
    &-title{
        font-weight: fw(regular);
        b{
            font-weight: fw(extraBold);
        }
    }
    &-item{
        display: block;
        @include rem(margin-bottom,30px);
        &--date,
        &--place{
            display: flex;
            align-items: center;
            @include rem(font-size,14px);
        }
    }
    &-comment{
        @include rem(font-size,14px);
    }
    .w-icon{
        &:before{
            content:"";
            display: block;
            @include rem(width,14px);
            @include rem(height,20px);
            @include rem(margin-right,10px);
            background: {
                size: contain;
                repeat: no-repeat;
            };
        }
        &.calendar-fill{
            &:before{
                background-image: url('../img/icons/calendar-fill.svg');
            }
        }
        &.gps{
            &:before{
                background-image: url('../img/icons/gps.svg');
            }
        }
    }
}