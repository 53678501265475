.cpt_tag {
  display: inline-block;
  background:#cccccc;
  padding: 5px;
  color:white;
  .product-tile & {
  	margin-top: 5px;
  }
  
  &.news{
    background:$tag-news-bkg;
    color:$tag-news-text;
  }
  &.bestsellers{
    background:$tag-bestsellers-bkg;
    color:$tag-bestsellers-text;
  }
  &.promos{
    background:$tag-promos-bkg;
    color:$tag-promos-text;
  }
}