.dt-c-CategoryHeading {
    margin-bottom: 25px;

    $gap: 20px;

    &-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: $gap;
    }

    &-main {
        flex: 1 1 calc(100% - 298px - #{$gap});
        min-width: 65%;

        &-title {
            display: flex;
            align-items: flex-end;
            margin-bottom: 15px;

            > *:not(:last-child) {
                margin-right: 10px;
            }

            span:not([class*='h']) {
                font-size: 14px;
                padding-bottom: 4px;
            }
        }
    }

    &-aside {
        flex: 1 0 calc(298px);
        &-content {
            padding: 16px;
            color: color(primary);
            background-color: color(light);
            font-size: 16px;

            > *:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }
}