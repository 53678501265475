.dt-c-ProfileCard {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px 17px;
    background-color: color(light);
    border-radius: 5px;

    // Display as a list, 3 cols on large screen, less on smaller ones
    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: -20px -10px 0;

        &-item {
            flex: 0 0 calc(100% / 3);
            padding: 20px 10px 0;
        }
    }


    // Generic display
    &-icon {
        position: relative;
        flex: 0 0 29.4%;
        max-width: 96px;

        &::before {
            content: '';
            display: block;
            padding-top: 100%;
            border-radius: 50%;
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.15);
        }

        > * {
            @include center-abs-xy;
            max-width: 55%;
            max-height: 62%;
            font-size: 0;
        }
    }

    &-description {
        flex: 1 1 auto;
        margin-left: 26px;

        &-name {
            display: block;
            margin-bottom: 8px;
            font-size: 18px;
            line-height: #{20/18};
            font-family: font(title);
            font-weight: fw(semiBold);
            color: color(green);
        }

        &-details {
            font-size: 14px;
            line-height: #{16/14};

            > * {
                display: block;
                margin-bottom: 5px;
                color: color(dark);

                strong {
                    font-weight: fw(semiBold);
                }
            }
        }
    }



    @include mq-to(xlarge) {
        &-list {
            &-item {
                flex-basis: 50%;
            }
        }
    }


    @include mq-to(medium) {
        &-list {
            &-item {
                flex-basis: 100%;
                padding-top: 10px;
            }
        }
    }
}