
//
// LOADER - Default

.loader {
	height: 20px;
    width: 20px;
    border: 4px solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    -moz-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
    margin: auto;

    &.loader-inline {
    	display: inline-block;
    	border-color: $text-color-primary;
    	border-right-color: transparent;
	    position: relative;
    	top: 4px;
    }

}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform:rotate(360deg);
	}
}


//
// LOADER - Styles for loader within buttons

.btn {

	.loader {
		display: none;
	}

	&.btn-default {

		.loader {
			border-color: $btn-default-color;
		}

		&.disabled {
			background: rgba(red($btn-default-color), green($btn-default-color), blue($btn-default-color), .3);
		}

	}

	&.btn-primary {

		.loader {
			border-color: $btn-default-bg;
		}

		&.btn-lg {
			.loader {
				height: 24px;
				width: 24px;
			}
		}

		&.disabled {
			background: rgba(red($btn-default-bg), green($btn-default-bg), blue($btn-default-bg), .3);
		}

	}

	&.btn-loader {
		display: block;
		@include flex-display();
		@include flex-direction(row);
		@include justify-content(center);

		.loader {
			display: block;
			border-right-color: transparent;
			@include align-items(center);

			& + .btn-content {
				display: none;

				&.btn-content-show {
					display: block;
					margin-left: ($space-default / 2);
				}

			}

		}

	}

}
