.cpt_card{
  position: relative;
  display: block;
  width: 100%;
  height:100%;
  background: $card-bkg;
  border:1px solid $card-bdr;
  color: $card-color;
  &:hover{
    border:1px solid $card-hover;
    color: $card-hover;
  }
  & .img{
    display:block;
    & img{
      width: 100%;
      height: auto;
    }
  }
  & .desc{
    display:block;
    padding:20px;
    text-align: center;
    @include x-rem(font-size,15px);
  }
}