/* ENCART PROMOTION */
.dt-c-Promotion{
  position: relative;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
  @include rem(margin-bottom,52px);

  &--wrapper{
    [class*='icon-arrow-']{
      &:not(.icon){
        &:before{
          filter: none;
        }
      }
    }
    @include mq-to(medium){
      .swiper-arrow-sides{
        padding: 0;
      }
    }
    &[data-mode="tall"]{
      .swiper-button{
        &-next{
          right: 0;
          left: auto;
          @include mq-from(large){
            transform: translateX(50px);
          }          
          @include mq-from(xlarge){
            transform: translateX(100px);
          }
        }
        &-prev{
          left: 0;
          right: auto;
          @include mq-from(large){
            transform: translateX(-50px);
          }
          @include mq-from(xlarge){
            transform: translateX(-100px);
          }
        }
      }
      @include mq-from(large){
        @include rem(padding,0 50px);
      }
      
      @include mq-from(xlarge){
        padding: 0;
      }
    }
  }

  @include mq-from(large) {
    /* CALC TO MANAGE HORIZONTAL SPACING BETWEEN ELEMENTS */
    height: calc(100% - 160px);
    margin: 80px 0;
  }
  &:before{
    content:'';
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: color(primary);
    z-index: 1;
  }
  .tag{
    @include rem(margin,21px 0 21px 0);
    z-index: 2;
    @include mq-from(large) {
      @include rem(margin,21px 0 auto 0);
    }
  }
  .flag{
    text-transform: uppercase;
    font-weight: 900;
    font-family: font(title);
    @include rem(padding,0 7px);
    @include mq-from(large) {
      @include rem(padding,0 13px);
    }
  }
  .content{
    @include rem(padding,0 15px);
    margin: auto 0;
    position: relative;
    z-index: 2;
    width: 100%;
    @include mq-from(large) {
      width: auto;
      @include rem(padding,0 30px);
    }
  }
  .title{
    @include rem(font-size,25px);
    color: color(light);
    font-family: font(title);
    font-weight: 700;
    text-transform: uppercase;
    @include rem(padding,0 7px);
    @include mq-from(large) {
      @include rem(font-size,36px);
      @include rem(padding,0 13px);
    }
    span{
      @include rem(font-size,46px);
      font-weight: 900;
      @include mq-from(large) {
        @include rem(font-size,52px);
      }
    }
    & + .veil{
      @include rem(margin-top,-23px);
    }
  }
  .desc{
    @include rem(margin-top,13px);
    @include rem(font-size,16px);
    color: color(light);
    @include rem(padding,0 7px);
    @include mq-from(large) {
      @include rem(padding,0 13px);
    }
  }
  .veil{
    @include rem(padding,15px 0);
    @include rem(margin-bottom,27px);
    background: rgba(color(primary), .5);
  }
  .actions{
    padding:0;
    @include rem(padding,0 13px);
    @include rem(margin-bottom,57px);
  }
  img{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    object-fit: cover;
    flex: 1;
    overflow: hidden;
    height: 100%;
  }
  &[data-mode="small"]{
    height: 100%;
    margin: 0;
    @include rem(border-radius, 10px);
    .tag{
      @include rem(font-size,14px);
      @include rem(padding,7px 10px 7px 50px);
      @include rem(margin-top,10px);
    }
    .flag{
      @include rem(font-size,14px);
      padding: 0;
    }
    .title{
      padding: 0;
      &,
      span{
        @include rem(font-size,22px);
      }
    }
    .desc{
      @include rem(font-size,14px);
      @include rem(margin-top,8px);
      padding: 0;
    }
    .content{
      @include rem(padding,0 0 0 50px);
    }
    .veil{
      @include rem(padding,5px 50px 15px 0);
      @include rem(margin-bottom,10px);
    }
    .actions{
      padding: 0;
      @include rem(margin-bottom,20px);
    }
    @include mq-to(medium){
      .tag{
        @include rem(padding,7px 10px 7px 33px);
      }
      .content{
        @include rem(padding,0 0 0 33px);
      }
    }
  }
}

.tag{
  @include rem(font-size,20px);
  @include rem(padding,12px 30px);
  font-weight: 900;
  text-transform: uppercase;
  font-family: font(title);
}