.dt-c-SelectionPage {
    &-title {
        font-weight: fw(regular);
        @include rem(margin-bottom, 5px);

        b, strong {
            font-weight: fw(bold);
        }
    }

    &-description {
        max-width: 100%;
        width: 580px;
        margin: {
            left: auto;
            right: auto;
        };
    }

    &-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        color: color(dark);
        @include mq-to(medium){
            flex-direction: column;
        }
    }

    &-item {
        &[data-mode="tall"]{
            @include mq-from(medium) {
                width: calc((100% / 2) - 20px);
            }
            @include mq-from(large) {
                width: calc((100% / 3) - 20px);
            }
        }
        background-color: color(light);
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.04);
        @include rem(margin-bottom, 50px);
        position: relative;
        z-index: 0;
        padding-bottom: 70px;
        margin: {
            left: 0;
            right: 0;
        }

        

        @include mq-to(medium) {
            width: 100%;
        }

        @include mq-from(medium){
            &[data-mode="tall"]{
                &:nth-child(even){
                    margin-left: 20px;
                }
            }
        }

        @include mq-from(large){
            &[data-mode="tall"]{
                &:nth-child(3n+2){
                    margin-right: 0;
                }
            }
            
        }

        @include mq-from(large) {
            &[data-mode="tall"]{
                margin-left: 20px;
                &:nth-child(3n+1) {
                    margin-left: 0;
                }
                &:nth-child(3n+3){
                    margin-right: 0;
                }
            }
        }

        &-link {
            background: red;
            color: inherit;
            text-decoration: none;
            &:hover {
                color: inherit;
            }
        }

        &-img {
            height: 228px;
            width: 100%;
            display: block;
            @include rem(margin-bottom, 15px);
            &.tall{
                @include rem(height, 318px);
            }
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            
            .dt-c-SelectionPage-item-date-encart {
                position: absolute;
                top:7%;
                @include rem(right, 0px);
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-start;
                align-items: center;
                @include rem(width, 59px); // marge droite gauche
                @include rem(height, 74px); // marge en bas
                background: color(secondary);
                color: color(dark);
                @include rem(font-size, 18px);
                text-align: center;
                font-weight: fw(bold);
                font-family:font(title);
                span{
                    @include rem(font-size, 32px);
                }
            }
            
        }

        &-content {
            padding: 0 30px;
        }

        &-category {
            @include rem(font-size, 14px);
            @include rem(line-height, 19px);
            @include rem(margin-bottom, 10px);
            color: color(primary);
            b {
                text-transform: uppercase;
            }
            &-sep {
                padding: 0 10px;
            }
        }

        &-title {
            @include rem(font-size, 18px);
            @include rem(line-height, 22px);
            @include rem(margin-bottom, 10px);
            font-family: font(title);
            font: {
                weight: fw(bold);
            }
            &.big{
                @include rem(font-size, 24px);
            }
        }

        &-description,
        &-date {
            @include rem(font-size, 14px);
            @include rem(line-height, 19px);
            @include rem(margin-bottom, 10px);
        }

        /*&-description {
            //min-height: 105px;
        }*/

        &-date {
            font-weight: fw(extraBold);
            &.variation{
                position: absolute;
                @include rem(top, 20px);
                left: 0;
                padding: 10px;
                background: color(secondary);
                display: flex;
                justify-content: center;
                align-items: center;
                @include rem(font-size, 16px);
                .icon{
                    display: inline-block;
                }
            }
        }

        &-bottom {
            position: absolute;
            left: 0;
            top: auto;
            bottom: 0;
            z-index: 0;
            padding: 0 30px 20px;
            width: 100%;

            &-link {
                @include rem(font-size, 13px);
                @include rem(line-height, 16px);
                color: color(tertiary);
                font-weight: fw(extraBold);
                font-family: font(title);
                text: {
                    decoration: underline;
                    transform: uppercase;
                };
            }
        }
        $context: &;
        &[data-mode="small"]{
            display: flex;
            padding: 0;
            margin-bottom: 0;
            overflow: hidden;
            @include rem(border-radius, 10px);
            height: 100%;
            @include mq-to(medium){
                flex-direction: column;
            }
            #{$context}{
                &-img{
                    height: 100%;
                    @include rem(width, 200px);
                    flex-shrink: 0;
                    @include mq-to(medium){
                        height: auto;
                        width: 100%;
                    }
                }
                &-content{
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    @include rem(padding, 15px 50px 15px 15px);
                }
                &-bottom{
                    position: static;
                    padding: 0;
                }
            }
        }
    }
}

