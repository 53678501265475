*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  color: color(dark);
  font-family: font(text);
  font-size: $base-fs;
  line-height: $base-lh;
  background: color(background--light);
}

body {
  &[data-layer-shown='true'] {
    overflow: hidden;
  }

  > .wrapper {
    z-index: 1;
    position: relative;
  }
}



// Texts
// -------------------------

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

[data-theme='dark'] {
  color: color(light);
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  padding: 0;
  font-family: font(title);
  font-weight: fw(extraBold);
  color: color(dark);

  &:not(.show-inline) {
    display: block;
  }

  [data-theme='dark'] & {
    color: color(light);
  }

  span {
    font-weight: fw(regular);
  }
}

.h1 {
  @include fs(36px, 43px);
  text-transform: uppercase;
}


ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
abbr[title] {
  text-decoration: none;
}
b, strong {
  font-weight: 700;
}
i, em {
  font-style: italic;
}


// links and buttons
a {
  color: inherit;
  text-decoration: none;
  transition: all .1s ease;

  &:hover, &:focus, &:focus-within {
    color: color(tertiary);
  }
}

button {
  border: none;
  background: none;
  padding: 0;
}

// outlines
a, button, input, [tabindex] {
  outline: none;
}

// Forms
input, input.form-control, textarea, select {
  display: block;
  height: 50px;
  width: 100%;
  padding: 10px;
  background-color: color(light);
  border: 1px solid color(border);
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  transition: all .2s ease-in-out;

  &:focus {
    border-color: color(primary);
  }

  &.auto{
    width: auto;
  }
}

textarea {
  height: auto;
  min-height: 41px;
  resize: vertical;
}

.dt-u-inlineError, .server-error {
  color: color(danger);
}

// Icons override
[class*='icon-arrow-']:not(.icon)::before {
  content: '';
  display: block;
  width: 16px;
  height: 28px;
  background: {
    position: center;
    size: contain;
    repeat: no-repeat;
  };
  filter: brightness(0) invert(1);
}



[data-dtdisplay] {
  display: none;
}


.uneal-dnone{
  display:none !important;
}

.dt-c-Title{
  display: block;
  margin: 0;
  padding: 0;
  font-family: font(title);
  font-weight: fw(regular);
  color: color(dark);

  [data-theme='dark'] & {
    color: color(light);
  }

  b {
    font-weight: fw(extraBold);
  }
}

.dt-c-Title-bdr{
  padding-bottom: 5px;
  font-family: font(title);
  border-bottom : 2px solid map-get($colors,secondary);
  font-weight: 700;
  text-transform: uppercase;
}

.custom-select{
  position: relative;
  select {
    height: 41px;
    @include fs(14px, 16px);
    @include rem(border-radius,5px);
    @include rem(padding, 12px 42px 12px 13px);
    color: color(border);

    option {
      color: color(dark);
    }
  }
  &:after{
    content: "";
    @include rem(width,15px);
    @include rem(height,9px);
    background: url('../img/icons/arrow-down-small.svg') center center/contain no-repeat;
    display: block;
    position: absolute;
    @include rem(right,20px);
    top: 50%;
    transform: translateY(-50%);
    filter: brightness(0);
  }
}

.input-search{
  position: relative;
  @include rem(border-radius,5px);
  border: 1px solid #ccc;

  input{
    @include rem(padding,13px 42px 13px 13px);
    border:0;
    height: 47px;
  }
  button{
    position: absolute;
    top:0;
    right:0;
    @include rem(border-radius,5px);
    border:0;
    background: map-get($colors,tertiary);
    color:white;
    padding: 0 10px;
    height: 100%;
  }
}

.filters-row .sort-by select {
  float: none;
}

// Add smal gutters option
.row-gutters-10 {
  margin-right: -10px;
  margin-left: -10px;

  [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

